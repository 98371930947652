import { IStructure } from './structure.model';
import { IAdherent } from './adherent.model';
import { TypeProjetAnnee } from './type-projet-annee.model';
import { DateFieldsDefinition, SubDateFieldDefinition } from '../util/moment.utils';
import { IProjetAnneeModule } from './projet-annee-module.model';
import { IProjetAnneeLieu } from './projet-annee-lieu.model';
import { IProjetAnneeAdherentSoutien } from './projet-annee-adherent-soutien.model';
import { IProjetAnneeAdherentStaff } from './projet-annee-adherent-staff.model';
import { IAdherentInformations } from './adherent-informations.model';
import {
  IProjetAnneeFcTrimestre,
  PROJET_ANNEE_FC_TRIMESTRE_DATE_FIELDS_DEFINITION
} from './projet-annee-fc-trimestre.model';
import { TamRefExercice } from './tam-ref-exercice.model';
import {
  TRIMESTRE_DATES_INTERVAL_DATE_FIELDS_DEFINITION,
  TrimestreDatesInterval
} from './trimestre-dates-interval.model';
import { Moment } from 'moment';
import { IDdcAlertMessge } from './ddc-alert-message.model';
import {IProjetAnneeAction} from "./projet-annee-action.model";
import {IProjetAnneeReve} from "./projet-annee-reve.model";
import {IProjetAnneeAvis} from "./projet-annee-avis.model";
import {ICampDiscussionSujet} from "./camp-discussion-sujet.model";
import {IProjetAnneeDiscussionSujet} from "./projet-annee-discussion-sujet.model";
import {IProjetAnneeModuleMarinSiteContexte} from "./projet-annee-module-marin-sites-contextes.model";

export interface IDroitsUtilisateurCourant {
  commar?: boolean;
  admin?:boolean;
}

export interface IProjetAnnee {
  id?: number;
  _ddcProjetAnneeHistoModuleDerniereModification?: string;
  _projetAnneeFicheInitialeAlertMessages?: IDdcAlertMessge[];
  _projetAnneeFicheComplementaireAlertMessages?: IDdcAlertMessge[];
  projetAnneeDiscussionSujets?: IProjetAnneeDiscussionSujet[];
  _projetAnneeAllAlertsMessages?: IDdcAlertMessge[];
  libelle?: string;
  statut?: number;
  statutProjetDeclaration?: number;
  nbreJeunes613?: number;
  nbreJeunes1417?: number;
  nbreAnimateursQualifies?: number;
  nbreAnimateursNonQualifies?: number;
  nbreAnimateursStagiaires?: number;
  tamRefExercice?: TamRefExercice;
  structureProjetAnnee?: IStructure;
  typeProjetAnnee?: TypeProjetAnnee;
  idTam?: number;
  commentaireDeclarationTam?: string;
  commentaireModificationDeclarationTam?: string;
  statutDeclarationTam?: string;
  errorsDeclarationTam?: string;
  dateHeureDeclarationTam?: Moment;
  adherentDeclarationTam?: IAdherent;
  dateModificationDeclarationTam?: Moment;
  adherentValidationDeclarationTam?: IAdherent;
  projetAnneeModules?: IProjetAnneeModule[];
  projetAnneeLieux?: IProjetAnneeLieu[];
  projetAnneeAdherentSoutiens?: IProjetAnneeAdherentSoutien[];
  projetAnneeAdherentStaffs?: IProjetAnneeAdherentStaff[];
  projetAnneeFcTrimestres?: IProjetAnneeFcTrimestre[];
  projetAnneeAdherentStaffsInformations?: IAdherentInformations[];
  trimestresDatesIntervals?: TrimestreDatesInterval[];
  projetAnneeFcTrimestresDeclarationHistorique?:IProjetAnneeFcTrimestresDeclarationHistorique[];
  projetAnneeActions?: IProjetAnneeAction[];
  degreImplicationJeunes?: number
  projetAnneeReve?: IProjetAnneeReve;
  projetAnneeModuleMarin?: IprojetAnneeModuleMarin;
  projetAnneeAvis?: IProjetAnneeAvis[];
}

export class ProjetAnnee implements IProjetAnnee {
  constructor(
    public id?: number,
    public _ddcProjetAnneeHistoModuleDerniereModification?: string,
    public _projetAnneeFicheInitialeAlertMessages?: IDdcAlertMessge[],
    public _projetAnneeFicheComplementaireAlertMessages?: IDdcAlertMessge[],
    public projetAnneeDiscussionSujets?: IProjetAnneeDiscussionSujet[],
    public _projetAnneeAllFCAlertsMessages?: IDdcAlertMessge[],
    public _projetAnneeAllAlertsMessages?: IDdcAlertMessge[],
    public libelle?: string,
    public statut?: number,
    public statutProjetDeclaration?: number,
    public nbreJeunes613?: number,
    public nbreJeunes1417?: number,
    public nbreAnimateursQualifies?: number,
    public nbreAnimateursNonQualifies?: number,
    public nbreAnimateursStagiaires?: number,
    public tamRefExercice?: TamRefExercice,
    public idTam?: number,
    public structureProjetAnnee?: IStructure,
    public typeProjetAnnee?: TypeProjetAnnee,
    public commentaireDeclarationTam?: string,
    public commentaireModificationDeclarationTam?: string,
    public statutDeclarationTam?: string,
    public errorsDeclarationTam?: string,
    public dateHeureDeclarationTam?: Moment,
    public adherentDeclarationTam?: IAdherent,
    public dateModificationDeclarationTam?: Moment,
    public adherentValidationDeclarationTam?: IAdherent,
    public projetAnneeModules?: IProjetAnneeModule[],
    public projetAnneeLieux?: IProjetAnneeLieu[],
    public projetAnneeAdherentSoutiens?: IProjetAnneeAdherentSoutien[],
    public projetAnneeAdherentStaffs?: IProjetAnneeAdherentStaff[],
    public projetAnneeFcTrimestres?: IProjetAnneeFcTrimestre[],
    public projetAnneeAdherentStaffsInformations?: IAdherentInformations[],
    public trimestresDatesIntervals?: TrimestreDatesInterval[],
    public projetAnneeFcTrimestresDeclarationHistorique?:IProjetAnneeFcTrimestresDeclarationHistorique[],
    public projetAnneeAvis?: IProjetAnneeAvis[],
    public projetAnneeModuleMarin?: IprojetAnneeModuleMarin,
    public projetAnneeActions?: IProjetAnneeAction[],
    public projetAnneeReve?: IProjetAnneeReve,
    public degreImplicationJeunes?: number

) { }
}

export interface IProjetsAnneePagination {
  projetsAnnee: IProjetAnnee[];
  projetsAnneeTotalCount: number;
}

export interface IProjetAnneeCreationDto {
  libelle?: string;
  idTamRefExercice?: number;
  codeStructureProjetAnnee?: string;
  codeTypeProjetAnnee?: string;
  codeModules?: object;
  copyDonneeProjetPeda?: boolean;
}

export class ProjetAnneeCreationDto implements IProjetAnneeCreationDto {
  constructor(
    public libelle?: string,
    public idTamRefExercice?: number,
    public codeStructureProjetAnnee?: string,
    public codeTypeProjetAnnee?: string,
    public codeModules?: object,
    public copyDonneeProjetPeda?: boolean
  ) { }
}

export const PROJET_ANNEE_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureDeclarationTam', 'dateModificationDeclarationTam'], {
  projetAnneeFcTrimestres: new SubDateFieldDefinition(PROJET_ANNEE_FC_TRIMESTRE_DATE_FIELDS_DEFINITION, true),
  trimestresDatesIntervals: new SubDateFieldDefinition(TRIMESTRE_DATES_INTERVAL_DATE_FIELDS_DEFINITION, true)
});

export interface IProjetAnneeFcTrimestresDeclarationHistorique {
  codeTrimestre?:string;
  dateHeureDeclarationTam?:string;
  numeroAdherentDeclarationTam?:string;
  idProjetAnnee?: number
}

export interface IprojetAnneeModuleMarin {
  projetAnneeModuleMarinUnite?: [],
  reglementationConfirme?:boolean,
  telephoneResponsableTechniqueMarin?: string,
  informationsGeneralesCommentaire?: string,
  numeroResponsableTechniqueMarin?: string,
  projetAnneeModuleMarinEncadrants?: [],
  projetAnneeModuleMarinBateaux?: [],
  projetAnneeModuleMarinSitesContextes?: []
}
