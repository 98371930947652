import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface ITrimestreDatesInterval {
  dateDebutIntervalle?: Moment;
  dateFinIntervalle?: Moment;
  codeTrimestre?: string;
}

export class TrimestreDatesInterval implements ITrimestreDatesInterval {
  constructor(
    public dateDebutIntervalle?: Moment,
    public dateFinIntervalle?: Moment,
    public codeTrimestre?: string
  ) { }
}

export const TRIMESTRE_DATES_INTERVAL_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebutIntervalle', 'dateFinIntervalle']);
