import {Component, Input} from '@angular/core';
import { IHdChangesObj } from './camp-info-complementaire-historique-hdtype-obj.component';

@Component({
  selector: 'ddc-camp-info-complementaire-historique-hdtype-scalar',
  templateUrl: './camp-info-complementaire-historique-hdtype-scalar.component.html',
  styleUrls: ['../../../camp/camp-info-complementaire/camp-info-complementaire-historique/camp-info-complementaire-historique.component.scss']
})
export class CampInfoComplementaireHistoriqueHdtypeScalarComponent {

  @Input() set item(item: IHdTypeScalar) {
    this._item = item;
  }

  _item: IHdTypeScalar;
}

export interface IHdTypeScalar extends IHdChangesObj {
  labelKey?: string;
  hdType: 'scalar';
  oldVal?: any;
  newVal?: any;
}
