import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {
  IProjetAnnee,
  IProjetAnneeCreationDto,
  IProjetsAnneePagination,
  PROJET_ANNEE_DATE_FIELDS_DEFINITION
} from '../model/projet-annee.model';
import {
  convertDateFromClient as utils_convertDateFromClient,
  convertDateFromServer as utils_convertDateFromServer
} from '../util/moment.utils';
import { map } from 'rxjs/operators';
import { IDdcAdministrationModuleDTO } from '../model/camp-administration-module-dto.model';
import { ProjetAnneeModificationStaticModuleDTO } from '../model/projet-annee-modification-static-module-dto.model';
import { ITamRefExercice, TamRefExercice } from '../model/tam-ref-exercice.model';
import {
  ProjetAnneeCodeModulesEnum,
  ProjetAnneeModificationActionEnum,
  TypeInfoComplementaireEnum
} from '../constant/enum';
import { IProjetAnneeInfoComplementaire } from '../model/projet-annee-info-complementaire.model';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ProjetAnneeService {

  constructor(
    protected http: HttpClient, 
    private titleService: Title
  ) {
  }

  private static PSEUDO_MODULE_ENTETE = 'ENTETE';

  public resourceUrl = environment.apiServerUrl + 'projets-annee';

  private codeModule: ProjetAnneeCodeModulesEnum;

  static convertProjetAnneeDateFromServer(projetAnnee: IProjetAnnee): IProjetAnnee {
    if (projetAnnee) {
      utils_convertDateFromServer(projetAnnee, PROJET_ANNEE_DATE_FIELDS_DEFINITION);
    }
    return projetAnnee;
  }

  private static convertDateFromClient(projetAnnee: IProjetAnnee): IProjetAnnee {
    return utils_convertDateFromClient(projetAnnee, PROJET_ANNEE_DATE_FIELDS_DEFINITION);
  }

  private static convertDateFromClientForProjetAnneeCreationDTO(projetAnneeCreationDto: IProjetAnneeCreationDto): IProjetAnneeCreationDto {
    return utils_convertDateFromClient(projetAnneeCreationDto, PROJET_ANNEE_DATE_FIELDS_DEFINITION);
  }

  findOneByIdAndModuleCode(id: number, moduleCode?: string): Observable<HttpResponse<IProjetAnnee>> {
    const moduleCodeQuery = `?module=${moduleCode ? moduleCode : ProjetAnneeService.PSEUDO_MODULE_ENTETE}`;
    return this.http
      .get<IProjetAnnee>(`${this.resourceUrl}/${id}${moduleCodeQuery}`, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeDateFromServer(res)));
  }

  find(params): Observable<HttpResponse<IProjetsAnneePagination>> {
    return this.http
      .get<IProjetsAnneePagination>(`${this.resourceUrl}`, {observe: 'response', params: params})
      .pipe(map(res => this.convertProjetsAnneePaginationFromServer(res)));
  }

  findWithCriterias(params: HttpParams): Observable<HttpResponse<IProjetsAnneePagination>> {
    return this.http
      .get<IProjetsAnneePagination>(`${this.resourceUrl}/multi-criteres?`,{ observe: 'response', params: params })
      .pipe(map(res => this.convertProjetsAnneePaginationFromServer(res)));
  }


  create(projetAnneeCreationDto: IProjetAnneeCreationDto): Observable<HttpResponse<IProjetAnnee>> {
    const copy = ProjetAnneeService.convertDateFromClientForProjetAnneeCreationDTO(projetAnneeCreationDto);
    return this.http
      .post<IProjetAnnee>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeDateFromServer(res)));
  }

  updateProjetAnneeModules(projetAnneeModules: IDdcAdministrationModuleDTO): Observable<HttpResponse<IProjetAnnee>> {
    return this.http
      .put<IProjetAnnee>(`${this.resourceUrl}/administration-modules`, projetAnneeModules, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeDateFromServer(res)));
  }

  getExercicesAccordingToCurrentDate(): Observable<HttpResponse<ITamRefExercice[]>> {
    return this.http
      .get<TamRefExercice[]>(`${this.resourceUrl}/options/exercices`, {observe: 'response'});
  }

  updateByProjetAnneeAndModuleCode(projetAnnee: IProjetAnnee, modificationJson: object, moduleCode: string,  action: ProjetAnneeModificationActionEnum = ProjetAnneeModificationActionEnum.UPDATE): Observable<HttpResponse<IProjetAnnee>> {
    const dto = new ProjetAnneeModificationStaticModuleDTO(moduleCode, ProjetAnneeService.convertDateFromClient(projetAnnee), modificationJson ? JSON.stringify(modificationJson) : null, action);
    return this.http
      .put<IProjetAnnee>(`${this.resourceUrl}/module`, dto, {observe: 'response'})
      .pipe(
        map(res => this.convertProjetAnneeDateFromServer(res))
      );
  }

  findProjetAnneeInfoComplementaire(id: number, typeIC: TypeInfoComplementaireEnum, moduleCode?: string, idDynModule?: number): Observable<HttpResponse<IProjetAnneeInfoComplementaire>> {
    const moduleCodeQuery = moduleCode ? `module=${moduleCode}` : '';
    const dynModuleQuery = idDynModule ? `idDynModule=${idDynModule}` : '';
    let query = '';
    if (moduleCodeQuery && dynModuleQuery) {
      query = `?${moduleCodeQuery}&${dynModuleQuery}`;
    } else if (moduleCodeQuery && !dynModuleQuery) {
      query = `?${moduleCodeQuery}`;
    } else if (!moduleCodeQuery && dynModuleQuery) {
      query = `?${dynModuleQuery}`;
    }
    return this.http
      .get<IProjetAnneeInfoComplementaire>(`${this.resourceUrl}/${id}/infos-complementaires/${typeIC}${query}`, {observe: 'response'})
      .pipe(res => res);
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour un projet)
   * @param response
   */
  protected convertProjetAnneeDateFromServer(response: HttpResponse<IProjetAnnee>): HttpResponse<IProjetAnnee> {
    return new HttpResponse({
      ...response,
      body: ProjetAnneeService.convertProjetAnneeDateFromServer(response.body)
    });
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour plusieurs projets)
   * @param res
   */
  protected convertDateArrayFromServer(res: HttpResponse<IProjetAnnee[]>): HttpResponse<IProjetAnnee[]> {
    if (res.body) {
      res.body.forEach((projetAnnee: IProjetAnnee) => {
        ProjetAnneeService.convertProjetAnneeDateFromServer(projetAnnee);
      });
    }
    return res;
  }

  protected convertProjetsAnneePaginationFromServer(res: HttpResponse<IProjetsAnneePagination>): HttpResponse<IProjetsAnneePagination>{
    if(res && res.body) {
      res.body.projetsAnnee.forEach((projetAnnee: IProjetAnnee) => {
        ProjetAnneeService.convertProjetAnneeDateFromServer(projetAnnee);
      });
    }
    return res;
  }

  public setCodeModule(codeModule: ProjetAnneeCodeModulesEnum) {
    this.codeModule = codeModule;
  }

  public getCodeModule() {
    return this.codeModule;
  }

  getProjetAnneeReveEngagementHorizon(id: number): Observable<HttpResponse<any>>  {
    return this.http
      .get<any>(`${this.resourceUrl}/reve/reve-engagement-horizon/${id}`,{ observe: 'response'})
  }

  getProjetAnneeLastYearByStructure(codeStructure: number, idExercice: number) {
    return this.http
      .get<any>(`${this.resourceUrl}/projet-peda-last-year/${codeStructure}/${idExercice}`,{ observe: 'response'})
  }
  
  changePageTitle(projetAnneeId: string){
    if(!projetAnneeId || projetAnneeId === null) {
      this.titleService.setTitle('Mon Projet SGDF');
      return;
    } else {
      this.titleService.setTitle(`Projet année n°${projetAnneeId} - Mon Projet SGDF`);
    }
    // restore favicon
    const favicon = document.querySelector("link[rel='icon'][type='image/x-icon']") as HTMLLinkElement;
    if (favicon) {
      favicon.href = 'favicon.ico';
    }
  }
}
