<div class="container-fluid gestion-notification-email-page">
  <mat-card class="ddc-card-with-title">
    <mat-card-title>
      <span ddcLabel labelKey="appli.accueil.administrationEmail.carte.gestionEmail"></span>
    </mat-card-title>
    <mat-card-content>

      <div class="row ddc-mat-label-gestionMail-form">
        <div class="col-sm-12 col-md-5">
          <mat-label class="ddc-mat-label-gestionMail-form-field" ddcLabel labelKey="appli.accueil.administrationEmail.gestionEmail.notifications"></mat-label>
        </div>
        <div class="col-sm-12 col-md-7 ddc-mat-label-gestionMail-form-choice">
          <mat-button-toggle-group [formControl]="this.mailFormGroup.get('isActivated')">
            <mat-button-toggle [value]="true">Oui</mat-button-toggle>
            <mat-button-toggle [value]="false">Non</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button mat-raised-button class="ddc-button-primary ddc-button-right"
                  (click)="save()"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
