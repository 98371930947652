import {Component, Input} from '@angular/core';

@Component({
  selector: 'ddc-camp-info-complementaire-historique-hdtype-obj',
  templateUrl: './camp-info-complementaire-historique-hdtype-obj.component.html',
  styleUrls: ['../../../camp/camp-info-complementaire/camp-info-complementaire-historique/camp-info-complementaire-historique.component.scss']
})
export class CampInfoComplementaireHistoriqueHdtypeObjComponent {

  @Input() set item(item: IHdTypeObj) {
    this._item = item;
  }

  _item: IHdTypeObj;

  constructor() {
  }

}

export interface IHdTypeObj extends IHdChangesObj {
  labelKey?: string;
  hdType?: string
  changes?: IHdChangesObj[];
}



export interface IHdChangesObj {
  labelKey?: string;
  hdType?: string;
}


