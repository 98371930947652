import { Injectable } from '@angular/core';
import { TypeCampEnum } from '../constant/enum';
import { EventManager } from '../util/event-manager';
import { HttpClient } from '@angular/common/http';
import * as Papa from 'papaparse';
import * as _ from 'lodash';
import { FileReaderService } from './file-reader.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CampConfigurationService {
  public static focusedTheme: string;
  public static campLabels: object = {};

  campLabelsFilesNamesKeys: string[]  = [];

  public static focusedThemeList = [
    'ddc-theme-master',
    'ddc-theme-marins',
    'ddc-theme-audace',
    'ddc-theme-compagnons',
    'ddc-theme-pionniers-et-caravelles',
    'ddc-theme-scouts-et-guides',
    'ddc-theme-louveteaux-et-jeannettes',
    'ddc-theme-farfadets'
  ];

  public static campLabelsFilesNames = {
    ddc_libelle_6_8: "camp-6-8-labels.csv",
    ddc_libelle__8_11: "camp-8-11-labels.csv",
    ddc_libelle_11_14: "camp-11-14-labels.csv",
    ddc_libelle_14_17: "camp-14-17-labels.csv",
    ddc_libelle_compagnons_1: "camp-compagnons-1-labels.csv",
    ddc_libelle_compagnons_2: "camp-compagnons-2-labels.csv",
    ddc_libelle_compagnons_3: "camp-compagnons-3-labels.csv",
    ddc_libelle_jeaunes_adultes: "camp-jeaunes-adultes-labels.csv",
    ddc_libelle_audace: "camp-audace-labels.csv",
    ddc_libelle_accompagne: "camp-accompagne-labels.csv",
    ddc_libelle_master: "libelle-camp-master.csv",
    ddc_libelle_autre: "camp-autre-labels.csv",
    ddc_libelle_farfadet: "camp-farfadet-labels.csv",
  };

  parseConfig = {
    delimiter: "",	// auto-detect
    newline: "",	// auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: false,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
  };

  allCampLabels = {};

  constructor(
    protected http: HttpClient,
    protected fileReaderService: FileReaderService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {
    this.campLabelsFilesNamesKeys = Object.keys(CampConfigurationService.campLabelsFilesNames);
    this.setCampConfigurationsByTypeCamp(null);
  }

  getFocusedTheme() {
    return CampConfigurationService.focusedTheme;
  }

  setFocusedTheme(focusedThemeIndex: number) {
    CampConfigurationService.focusedTheme = CampConfigurationService.focusedThemeList[focusedThemeIndex];
    EventManager.focusedThemeUpdated.next(this.getFocusedTheme());
  }

  setCampLabels(campLabelsFileNameIndex: number) {
    let campLabels = {};
    if(this.allCampLabels[this.campLabelsFilesNamesKeys[campLabelsFileNameIndex]]) {
      campLabels = this.allCampLabels[this.campLabelsFilesNamesKeys[campLabelsFileNameIndex]];
      this.updateCampLabels(campLabelsFileNameIndex, campLabels);
    } else {
      const fileName = `assets/labels/${CampConfigurationService.campLabelsFilesNames[this.campLabelsFilesNamesKeys[campLabelsFileNameIndex]]}`;
      this.fileReaderService.getFileFromAssets(fileName, {responseType: 'text'})
        .subscribe(data => {
          let res = Papa.parse(data, this.parseConfig);
          if (res && res.data && res.data.length > 0) {
            res.data.forEach(item => {
              if (item[0] && item[1]) {
                campLabels[item[0]] = item[1];
              }
            });
          }
          this.allCampLabels[this.campLabelsFilesNamesKeys[campLabelsFileNameIndex]] = campLabels;
          this.updateCampLabels(campLabelsFileNameIndex, campLabels);
        });
    }
  }

  getCampLabels() {
    return CampConfigurationService.campLabels;
  }

  updateCampLabels(campLabelsFileNameIndex: number, campLabels: object) {
    if(campLabelsFileNameIndex !== 10) {
      CampConfigurationService.campLabels = _.assign({}, this.allCampLabels['ddc_libelle_master'], campLabels);
    }else {
      CampConfigurationService.campLabels = campLabels;
    }
    EventManager.campLabelsUpdated.next(this.getCampLabels());
  }

  getLabelByKey(labelKey: string): string {
    return this.getCampLabels()[labelKey];
  }

  static getCampConfigurations(typeCamp: TypeCampEnum) {
    // initialize Camp configurations
    let campConfigurations = new CampConfigurations();
    campConfigurations.focusedThemeIndex = 0;
    campConfigurations.campLabelsIndex = 10;
    if(typeCamp) {
      switch (typeCamp) {
        case TypeCampEnum.SIX_HUIT:
          campConfigurations.focusedThemeIndex = 7;
          campConfigurations.campLabelsIndex = 0;
          break;
        case TypeCampEnum.HUIT_ONZE:
          campConfigurations.focusedThemeIndex = 6;
          campConfigurations.campLabelsIndex = 1;
          break;
        case TypeCampEnum.ONZE_QUATORZE:
          campConfigurations.focusedThemeIndex = 5;
          campConfigurations.campLabelsIndex = 2;
          break;
        case TypeCampEnum.QUATORZE_DIXSEPT:
          campConfigurations.focusedThemeIndex = 4;
          campConfigurations.campLabelsIndex = 3;
          break;
        case TypeCampEnum.AUTRE:
          campConfigurations.focusedThemeIndex = 0;
          campConfigurations.campLabelsIndex = 11;
          break;
        case TypeCampEnum.COMPAGNONS_T1:
          campConfigurations.focusedThemeIndex = 3;
          campConfigurations.campLabelsIndex = 4;
          break;
        case TypeCampEnum.COMPAGNONS_T2:
          campConfigurations.focusedThemeIndex = 3;
          campConfigurations.campLabelsIndex = 5;
          break;
        case TypeCampEnum.COMPAGNONS_T3:
          campConfigurations.focusedThemeIndex = 3;
          campConfigurations.campLabelsIndex = 6;
          break;
        case TypeCampEnum.JADE:
          campConfigurations.focusedThemeIndex = 0;
          campConfigurations.campLabelsIndex = 7;
          break;
        case TypeCampEnum.AUDACE:
          campConfigurations.focusedThemeIndex = 2;
          campConfigurations.campLabelsIndex = 8;
          break;
        case TypeCampEnum.CAMP_ACCOMPAGNE:
          campConfigurations.focusedThemeIndex = 0;
          campConfigurations.campLabelsIndex = 9;
          break;
        case TypeCampEnum.FARFADET:
          campConfigurations.focusedThemeIndex = 7;
          campConfigurations.campLabelsIndex = 12;
          break;
        default:
          campConfigurations.focusedThemeIndex = 0;
          campConfigurations.campLabelsIndex = 10;
      }
    }
    return campConfigurations;
  }

  setCampConfigurationsByTypeCamp(typeCamp, campId?: string) {
    const campConfigurations = CampConfigurationService.getCampConfigurations(typeCamp);
    this.changeFavicon(typeCamp);
    this.setCampLabels(campConfigurations.campLabelsIndex);
    this.setFocusedTheme(campConfigurations.focusedThemeIndex);
    campId ? this.changePageTitle(campId) : this.changePageTitle(null);
  }

  changePageTitle(campId: string){
    if(!campId || campId === null) {
      this.titleService.setTitle('Mon Projet SGDF');
      return;
    } else {
      this.titleService.setTitle(`Camp n°${campId} - Mon Projet SGDF`);
    }
  }

  // Change le favicon en fonction du type de camp
  changeFavicon(campType: TypeCampEnum) {
    if(!campType || campType === null) {
      this.resetFavicon();
      return;
    }
    const faviconPath = `assets/img/favicon/${campType}.svg`;
  
    fetch(faviconPath, { method: 'HEAD' }).then(response => {
      if (response.ok) {
        const favicon = document.querySelector("link[rel='icon'][type='image/x-icon']") as HTMLLinkElement;
        if (favicon) {
          favicon.href = faviconPath;
        }
      } else {
        console.error('Favicon not found:', faviconPath);
      }
    }).catch(error => {
      console.error('Error fetching favicon:', error);
    });
  }

  // Reset favicon to default
  resetFavicon() {
    const favicon = document.querySelector("link[rel='icon'][type='image/x-icon']") as HTMLLinkElement;
    if (favicon) {
      favicon.href = 'favicon.ico';
    }
  }
}
export interface ICampConfigurations {
  focusedThemeIndex?: number;
  campLabelsIndex?: number;
}

export class CampConfigurations implements ICampConfigurations {
  constructor(
    public focusedThemeIndex?: number,
    public campLabelsIndex?: number
  ) {
  }
}
