import { Component, OnInit } from '@angular/core';
import { CampDiscussionSujetService } from '../../../shared/service/camp-discussion-sujet.service';
import { filterOkMapBody } from '../../../shared/util/http.utils';
import { ICampDiscussionSujet } from '../../../shared/model/camp-discussion-sujet.model';
import { convertToHumanDate } from '../../../shared/util/moment.utils';
import { ICampStructure } from '../../../shared/model/camp-structure.model';
import { EventManager } from '../../../shared/util/event-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'ddc-mes-actions',
  templateUrl: './mes-actions.component.html',
  styleUrls: ['mes-actions.component.scss']
})
export class MesActionsComponent implements OnInit {

  campDiscussionSujets: ICampDiscussionSujet[] = [];
  convertToHumanDate = convertToHumanDate;

  isInitialized = false;

  constructor(
    protected router: Router,
    protected campDiscussionSujetService: CampDiscussionSujetService,
  ) { }

  ngOnInit(): void {
    this.initMesActions();
  }

  initMesActions(): void {
    this.campDiscussionSujetService.findActiveDiscussionForConnectedUser()
      .pipe(filterOkMapBody)
      .subscribe((res: ICampDiscussionSujet[]) => {
        this.isInitialized = true;
        this.campDiscussionSujets = res;
        EventManager.mesActionsUpdated.next(this.campDiscussionSujets);
      });
  }

  getLibelleAction(campDiscussionSujet: ICampDiscussionSujet) {
    if(campDiscussionSujet && campDiscussionSujet.camp) {
      const structureOrganisatrice = campDiscussionSujet.camp.campStructures.find((campStructure: ICampStructure) => campStructure.organisatrice == true);
      const libelle =
        `${campDiscussionSujet.camp.id} - ${structureOrganisatrice ? structureOrganisatrice.structure.libelle : ''} - ${campDiscussionSujet.sujet}`;
      return libelle;
    }
    return;
  }

  getTextAction(campDiscussionSujet: ICampDiscussionSujet) {
    if(campDiscussionSujet && campDiscussionSujet.campDiscussionEchanges) {
      const text = `<b>${campDiscussionSujet.sujet}</b> - ${campDiscussionSujet.campDiscussionEchanges[0] ? campDiscussionSujet.campDiscussionEchanges[0].echange : ''}`;
      return text;
    }
    return;
  }

  accessCamp(campDiscussionSujet: ICampDiscussionSujet) {
    const queryParms = {};
    if(campDiscussionSujet.codeModule) {
      queryParms['codeModule'] = campDiscussionSujet.codeModule;
    }
    this.router.navigate([`/camp/${campDiscussionSujet.camp.id}`],{queryParams: queryParms});
  }

}
