<div class="{{focusedTheme}}">
  <h1 mat-dialog-title class="ddc-modal-dialog-title">
    {{data.title}}
  </h1>

  <div mat-dialog-content>
    <div class="container-fluid ddc-no-padding">
      <ddc-modules
        [typeDdc]="typeDdc"
        [ddcModules]="ddcModules"
        (updateDdcModules)="updateDdcModules($event)"
        [isFormSubmitted]="formAlreadySubmitted"
        [isCampEnfant]="isCampEnfant"
        [displayProjetPeda]="displayProjetPeda"
      ></ddc-modules>
    </div>
  </div>

  <div mat-dialog-actions class="row">
    <div class="col-auto ddc-offset-auto">
      <div class="row ddc-right">
        <div class="col-auto" *ngIf="displayButtonConfirmer">
          <button
            mat-raised-button
            class="ddc-button-primary"
            (click)="onYesClick()"
            [disabled]="formAlreadySubmitted && modulesErrorFlag"
          >Confirmer</button>
        </div>
        <div class="col-auto">
          <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</div>
