import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from "@angular/forms";
import {AuthenticationService} from "../service/authentication.service";
import {Adherent, IAdherent} from "../model/adherent.model";
import {EmailService} from "../service/email.service";

@Component({
  selector: 'ddc-gestion-notification-email',
  templateUrl: './gestion-notification-email.component.html',
  styleUrls: ['./gestion-notification-email.component.scss']
})
export class GestionNotificationEmailComponent implements OnInit {

  currentUser: Adherent;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private emailService: EmailService
  ) { }

  mailFormGroup = this.fb.group({
    isActivated: ['']
  });

  ngOnInit() {

    if (this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue;
    }

    this.emailService.getAdherentMailOptions().subscribe(
      mailOptions => {
        console.log(mailOptions)
        this.mailFormGroup.get('isActivated').setValue(mailOptions.body.isActivated);
      }
    )
  }

  save() {
    this.emailService.changeMailOptions(this.mailFormGroup.getRawValue());
  }
}
