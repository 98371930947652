import {
  AdherentMailTemplateEnum,
  CampAdherentParticipantResponsibilitiesEnum,
  CampAdherentStaffRoleEnum,
  CampVieEquipeFilBlancEnum,
  DiplomeMarinEnum,
  NatureProjetMarinEnum,
  ProjetAnneeCodeModulesEnum,
  ProjetAnneePeriodeAnneeEnum,
  ProjetAnneePeriodeVacanceEnum,
  ProjetAnneeStatutEnum,
  ProjetAnneeStatutProjetDeclarationEnum,
  ProprietaireBateuMarinEnum,
  RoleNavigationMarinEnum,
  StatutAvisEnum,
  StatutCampEnum,
  StatutDeclarationEnum,
  StatutDepartCampEnum,
  TransportOptionEnum,
  TrimestreEnum,
  TypeAvisEnum,
  TypeBateauMarinEnum,
  TypeCampEnum,
  TypeCampGroupEnum,
  TypeDossierEnum, TypeNavigationMarinEnum,
  TypeNotificationEnum,
  TypeSoutienEnum,
  TypeContexteMarinEnum
} from './enum';

export const AUTH_STORAGE_KEY = 'ddc-currentUser';
export const DDC_CAMP_SEARCH_CRITERIAS = 'ddc-camp-search-criterias';
export const DDC_PROJET_ANNEE_SEARCH_CRITERIAS = 'ddc-projet-annee-search-criterias';
export const DDC_NUMBER_ITEM_PAGINATION = 'ddc-number-item-pagination';

export const MAX_FILE_SIZE_ACCEPTED = 10000000; //10Mb
export const NUMBER_OF_GRILLE_AVTIVITES_PER_DAY = 3;
export const NUMBER_OF_MENUS_PER_DAY = 4;
export const AGE_MAJORITY = 18;
export const LABEL_KEY_START_INDICATOR = 'ddc-labelKey-start:';
export const LABEL_KEY_END_INDICATOR = ':ddc-labelKey-end';

export const ITEMS_PER_PAGE = 50;

export const CAMP_INFO_GENERALE_STATUS = [
  {value: StatutCampEnum.ENCOURS, text: 'En cours'},
  {value: StatutCampEnum.ANNULE, text: 'Annulé'},
  {value: StatutCampEnum.ARCHIVE, text: 'Archivé'}
];

export const DDC_ADHERENT_STAFF_STAGES = [
  {value: '', text: ''},
  {value: 'validationStagePratiqueBafa', text: 'BAFA'},
  {value: 'validationStagePratiqueBafd', text: 'BAFD'}
];

export const DDC_ADHERENT_STAFF_RESPONSIBILITIES = [
  {value: 'responsabiliteIntendant', text: 'Intendant(e)', icon: 'restaurant'},
  {value: 'responsabiliteAS', text: 'Assistant(e) Sanitaire', icon: 'local_hospital'},
  {value: 'responsabiliteTresorier', text: 'Trésorier(ère)', icon: 'local_atm'},
  {value: 'responsabiliteMateriel', text: 'Matériel', icon: 'local_shipping'},
  {value: 'responsabiliteAutre', text: 'Autre', icon: 'more_horiz'}
];

export const CAMP_ADHERENT_PARTICIPANT_RESPONSIBILITIES = [
  {value: CampAdherentParticipantResponsibilitiesEnum.TRESORIER, text: 'Trésorier(ère)'},
  {value: CampAdherentParticipantResponsibilitiesEnum.COMMUNICATION, text: 'Communication'},
  {value: CampAdherentParticipantResponsibilitiesEnum.INTENDANT, text: 'Intendant(e)'},
  {value: CampAdherentParticipantResponsibilitiesEnum.LOGISTIQUE, text: 'Logistique'},
  {value: CampAdherentParticipantResponsibilitiesEnum.RELATION_PARTENAIRE, text: 'Relation avec le partenaire'}
];

export const CAMP_VIE_EQUIPE_FILS_BLANCS = [
  {value: CampVieEquipeFilBlancEnum.PLAISIR_CROIRE, text: 'Le plaisir de croire'},
  {value: CampVieEquipeFilBlancEnum.JOIE_VIVRE, text: 'La joie de vivre'},
  {value: CampVieEquipeFilBlancEnum.BONHEUR_AIMER, text: 'Le bonheur d\'aimer'},
];

export const DDC_ADHERENT_STAFF_ROLES = [
    {
      value: CampAdherentStaffRoleEnum.DIRECTEUR,
      text: 'Directrice ou directeur',
      icon: 'looks_one',
      displayForTypeDossier: [TypeDossierEnum.CAMP, TypeDossierEnum.PROJET_ANNEE],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON, TypeCampGroupEnum.AUDACE],
      displayForCampNormal: true,
      displayForCampPere: true,
      displayForCampEnfant: false,
    },
    {
      value: CampAdherentStaffRoleEnum.RESPONSABLE_FARFADET,
      text: 'Responsable farfadet',
      icon: 'looks_one',
      displayForTypeDossier: [TypeDossierEnum.CAMP],
      displayForCampGroup: [TypeCampGroupEnum.FARFADET],
      displayForCampNormal: true,
      displayForCampPere: false,
      displayForCampEnfant: false,
    },
    {
      value: CampAdherentStaffRoleEnum.RESPONSABLE_UNITE,
      text: 'Responsable d\'unité',
      icon: 'account_circle',
      displayForTypeDossier: [TypeDossierEnum.CAMP],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON, TypeCampGroupEnum.AUDACE],
      displayForCampNormal: false,
      displayForCampPere: false,
      displayForCampEnfant: true,
    },
    {
      value: CampAdherentStaffRoleEnum.DIRECTEUR_ADJOINT,
      text: 'Adjoint ou adjointe',
      icon: 'looks_two',
      displayForTypeDossier: [TypeDossierEnum.CAMP],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.AUDACE],
      displayForCampNormal: false,
      displayForCampPere: true,
      displayForCampEnfant: false,
    },
    {
      value: CampAdherentStaffRoleEnum.CHEF,
      text: 'Équipier ou équipiere',
      icon: 'group',
      displayForTypeDossier: [TypeDossierEnum.CAMP],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.AUDACE],
      displayForCampNormal: false,
      displayForCampPere: true,
      displayForCampEnfant: false,
    },
    {
      value: CampAdherentStaffRoleEnum.CHEF,
      text: 'Animatrice ou animateur',
      icon: 'child_care',
      displayForTypeDossier: [TypeDossierEnum.CAMP, TypeDossierEnum.PROJET_ANNEE],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON, TypeCampGroupEnum.AUDACE],
      displayForCampNormal: true,
      displayForCampPere: false,
      displayForCampEnfant: true,
    },
    {
      value: CampAdherentStaffRoleEnum.CHEF,
      text: 'Parent animateur',
      icon: 'group',
      displayForTypeDossier: [TypeDossierEnum.CAMP],
      displayForCampGroup: [TypeCampGroupEnum.FARFADET],
      displayForCampNormal: true,
      displayForCampPere: false,
      displayForCampEnfant: false,
    },
  {
      value: CampAdherentStaffRoleEnum.DIRECTEUR_ADJOINT,
      text: 'Directrice ou directeur adjoint(e)',
      icon: 'looks_two',
      displayForTypeDossier: [TypeDossierEnum.CAMP, TypeDossierEnum.PROJET_ANNEE],
      displayForCampGroup: [TypeCampGroupEnum.COMPAGNON],
      displayForCampNormal: true,
      displayForCampPere: false,
      displayForCampEnfant: false,
    },
    {
      value: CampAdherentStaffRoleEnum.AUTRE,
      text: 'Autre',
      icon: 'battery_unknown',
      displayForTypeDossier: [TypeDossierEnum.CAMP, TypeDossierEnum.PROJET_ANNEE],
      displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON, TypeCampGroupEnum.AUDACE, TypeCampGroupEnum.FARFADET],
      displayForCampNormal: true,
      displayForCampPere: true,
      displayForCampEnfant: true,
    },
];

export const TRANSPORT_OPTIONS = [
  {value: TransportOptionEnum.CAR, text: 'Car'},
  {value: TransportOptionEnum.BUS, text: 'Bus'},
  {value: TransportOptionEnum.MINI_BUS, text: 'Mini Bus'},
  {value: TransportOptionEnum.TRAIN, text: 'Train'},
  {value: TransportOptionEnum.AVION, text: 'Avion'},
  {value: TransportOptionEnum.VEHICULE_ADAPTE, text: 'Véhicule adapté (handicap, etc.)'},
  {value: TransportOptionEnum.COVOITURAGE, text: 'Covoiturage'},
  {value: TransportOptionEnum.BATEAU, text: 'Bateau'},
  {value: TransportOptionEnum.MARCHE, text: 'Marche'},
  {value: TransportOptionEnum.VELO, text: 'Vélo'},
  {value: TransportOptionEnum.AUTRE, text: 'Autre'},
];

export const GRILLE_ACTIVITES_CRENEAUX = [
  {value: 1, text: 'Matin'},
  {value: 2, text: 'Après-midi'},
  {value: 3, text: 'Soir'},
];

export const GRILLE_ACTIVITES_CRENEAUX_COMPAGNONS = [
  {value: 1, labelKey: 'appli.camp.onglet.activite.compagnon.creneau.matin'},
  {value: 2, labelKey: 'appli.camp.onglet.activite.compagnon.creneau.midi'},
  {value: 3, labelKey: 'appli.camp.onglet.activite.compagnon.creneau.soir'}
]

export function getCreneauText(value: number) {
  const crenau = GRILLE_ACTIVITES_CRENEAUX.find(item => item.value === value);
  return crenau ? crenau.text : 'Non fourni';
}

export const MENUS_REPAS = [
  {value: 1, text: 'Petit déjeuner'},
  {value: 2, text: 'Déjeuner'},
  {value: 3, text: 'Goûter'},
  {value: 4, text: 'Dîner'},
];

export function getRepasText(value: number) {
  const repas = MENUS_REPAS.find(item => item.value === value);
  return repas ? repas.text : 'Non fourni';
}

export const CAMP_AVIS = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.PREALABLE, text: 'Avis préalable'},
  {value: StatutAvisEnum.FAVORABLE, text: 'Avis favorable'},
  {value: StatutAvisEnum.FAVORABLE_RESERVE, text: 'Avis favorable avec réserve'},
  {value: StatutAvisEnum.DEFAVORABLE, text: 'Avis défavorable'},
  {value: StatutAvisEnum.REFUSE, text: 'Avis refusé'}
];

export const CAMP_AVIS_RG_AP = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.EMIS, text: 'Émis'},
];

export const CAMP_AVIS_VAO = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.FAVORABLE, text: 'Avis favorable'},
  {value: StatutAvisEnum.FAVORABLE_RESERVE, text: 'Avis favorable avec réserve'},
  {value: StatutAvisEnum.DEFAVORABLE, text: 'Avis défavorable'},
];

export const CAMP_AVIS_MARIN = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.DELIVRE, text: 'Visa Delivré'},
  {value: StatutAvisEnum.REFUSE, text: 'Visa refusé'},
  {value: StatutAvisEnum.PROJ_NAV_MODIF, text: 'Projet de navigation modifié'},
  {value: StatutAvisEnum.COMPLETE, text: 'Visa complété'},
];

export const CAMP_AVIS_VENT_DU_LARGE = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.DELIVRE, text: 'Visa Delivré'},
  {value: StatutAvisEnum.REFUSE, text: 'Visa refusé'},
];

export const CAMP_AVIS_INTERNATIONAL = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.PREALABLE, text: 'Avis préalable'},
  {value: StatutAvisEnum.DEFAVORABLE, text: 'Avis défavorable'},
  {value: StatutAvisEnum.FAVORABLE, text: 'Avis favorable'}
];

export const STATUT_DEPART_CAMP = [
  {value: StatutDepartCampEnum.NON_VALIDE, text: 'Non validé'},
  {value: StatutDepartCampEnum.VALIDE, text: 'Validé'},
  {value: StatutDepartCampEnum.PROJ_MODIF, text: 'Projet modifié'},
  {value: StatutDepartCampEnum.NON_EMIS, text: 'Non emis'}
];

export const STATUT_DECLARATION_TAM = [
  {value: StatutDeclarationEnum.PARTIAL_DECLARATION, text: 'Déclaration partielle'},
  {value: StatutDeclarationEnum.FULL_DECLARATION, text: 'Déclaration complète'},
  {value: StatutDeclarationEnum.UNCONFIRMED_DECLARATION, text: 'Non conforme'},
  {value: StatutDeclarationEnum.INSUFFICIENT_DECLARATION, text: 'Insuffisant'},
  {value: StatutDeclarationEnum.VALIDATED_DECLARATION, text: 'Validée par la DDCS'},
  {value: StatutDeclarationEnum.MODIFIED_DECLARARION, text: 'Modifiée depuis la déclaration J&S'},
  {value: StatutDeclarationEnum.NO_DECLARATION, text: 'Non transmis'},
];

export const PROJET_ANNEE_PERIODE_ANNEE = [
  {value: ProjetAnneePeriodeAnneeEnum.MERCREDI, text: 'Mercredi'},
  {value: ProjetAnneePeriodeAnneeEnum.SAMEDI, text: 'Samedi'},
  {value: ProjetAnneePeriodeAnneeEnum.DIMANCHE, text: 'Dimanche'},
  {value: ProjetAnneePeriodeAnneeEnum.AUTRE, text: 'Autre'},
];

export const PROJET_ANNEE_PERIODE_VACANCE = [
  {value: ProjetAnneePeriodeVacanceEnum.TOUSSAINT, text: 'Toussaint'},
  {value: ProjetAnneePeriodeVacanceEnum.NOEL, text: 'Noël'},
  {value: ProjetAnneePeriodeVacanceEnum.HIVER, text: 'Hiver'},
  {value: ProjetAnneePeriodeVacanceEnum.PRINTEMPS, text: 'Printemps'},
  {value: ProjetAnneePeriodeVacanceEnum.JUILLET, text: 'Juillet'},
  {value: ProjetAnneePeriodeVacanceEnum.AOUT, text: 'Août'},
];

export const PROJET_ANNEE_MODULE_CODE_TRIMESTRE = [
  {moduleCode: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_1, trimestre: TrimestreEnum.TRIMESTRE_1},
  {moduleCode: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_2, trimestre: TrimestreEnum.TRIMESTRE_2},
  {moduleCode: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_3, trimestre: TrimestreEnum.TRIMESTRE_3},
  {moduleCode: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_4, trimestre: TrimestreEnum.TRIMESTRE_4},
];

export const PROJET_ANNEE_STATUS = [
  {value: ProjetAnneeStatutEnum.CREE, text: 'Créé'},
  {value: ProjetAnneeStatutEnum.A_TELEDECLARER, text: 'À teledeclarer'},
  {value: ProjetAnneeStatutEnum.TELEDECLARE, text: 'Télédéclaré'}
];

export const PROJET_ANNEE_STATUS_PROJET_DECLARATION = [
  {value: ProjetAnneeStatutProjetDeclarationEnum.EN_COURS, text: 'En cours'},
  {value: ProjetAnneeStatutProjetDeclarationEnum.ARCHIVE, text: 'Archivé'}
];

export const NOTIFICATION_PARAMS = [
  {typeNotification: TypeNotificationEnum.MODIF_AVIS_AP, icon:'question_answer', title: 'Changement d\'avis', color: 'green'},
  {typeNotification: TypeNotificationEnum.MODIF_AVIS_RG, icon:'thumb_up', title: 'Changement d\'avis', color: 'green'},
  {typeNotification: TypeNotificationEnum.MODIF_AVIS_MARIN, icon:'sailing', title: 'Changement d\'avis', color: 'green'},
  {typeNotification: TypeNotificationEnum.MODIF_AVIS_INTER, icon:'public', title: 'Changement d\'avis', color: 'green'},
  {typeNotification: TypeNotificationEnum.AUTORISER_CAMPER, icon:'check_circle', title: 'Autorisé à camper', color: 'green'},
  {typeNotification: TypeNotificationEnum.NOUVEAU_BUDGET, icon:'euro_symbol', title: 'Notificaiton Title', color: 'green'},
  {typeNotification: TypeNotificationEnum.AFFECTER_SOUTIEN, icon:'person_add', title: 'Affectation de soutien', color: 'green'},
  {typeNotification: TypeNotificationEnum.MODIF_PAYS, icon:'public', title: 'Modification du pays', color: 'orange'},
  {typeNotification: TypeNotificationEnum.NOUVELLE_DISCUSSION, icon:'chat', title: 'Nouvelle discussion', color: 'lightgreen'},
  {typeNotification: TypeNotificationEnum.NOUVEAU_MESSAGE, icon:'chat', title: 'Nouveau message', color: 'darkgreen'},
  {typeNotification: TypeNotificationEnum.CLOTURER_DISCUSSION, icon:'chat_bubble', title: 'Discussion clôturé', color: 'darkblue'},
  {typeNotification: TypeNotificationEnum.MODIF_APRES_TELEDECLARER, icon:'publish', title: 'Modification des données après la télédéclaration', color: 'orange'},
  {typeNotification: TypeNotificationEnum.MODIF_STATUS_TELEDECLARER, icon:'publish', title: 'Modification des données après la télédéclaration', color: 'orange'},
  {typeNotification: TypeNotificationEnum.DEFAULT, icon:'notification_important', title: 'Notificaiton Title', color: 'green'},
];

export const MAIL_TEMPLATE = [
  {value: AdherentMailTemplateEnum.AFFECTATION_SOUTIEN, text: 'Affectation soutien'},
  {value: AdherentMailTemplateEnum.CHANGEMENT_DOSSIER, text: 'Changement de dossier'},
  {value: AdherentMailTemplateEnum.CHANGEMENT_VISA, text: 'Changement de visa'},
  {value: AdherentMailTemplateEnum.MESSAGES, text: 'Messages'},
  {value: AdherentMailTemplateEnum.CHANGEMENT_PAYS, text: 'Changement de pays'},
]

export const PARAMETRES_AVIS_SGDF = [
  {
    typeAvis: TypeAvisEnum.RG,
    listAvis: CAMP_AVIS_RG_AP,
    labelKey: 'bdd.campAvis.typeAvis.rg',
    droit: 'donnerAvisRG',
    displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON,TypeCampEnum.AUDACE, TypeCampGroupEnum.FARFADET],
    displayForCampEnfant: true,
    displayForCampPere: false
  },
  {
    typeAvis: TypeAvisEnum.AP,
    listAvis: CAMP_AVIS_RG_AP,
    labelKey: 'bdd.campAvis.typeAvis.ap',
    droit: 'donnerAvisAP',
    displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON,TypeCampEnum.AUDACE, TypeCampGroupEnum.FARFADET],
    displayForCampEnfant: true,
    displayForCampPere: false
  },
  {
    typeAvis: TypeAvisEnum.MARIN,
    listAvis: CAMP_AVIS_MARIN,
    labelKey: 'bdd.campAvis.typeAvis.marin',
    droit: 'commar',
    displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON,TypeCampEnum.AUDACE],
    displayForCampEnfant: false,
    displayForCampPere: true
  },
  {
    typeAvis: TypeAvisEnum.INTERNATIONAL,
    listAvis: CAMP_AVIS_INTERNATIONAL,
    displayForCampGroup: [TypeCampGroupEnum.HUIT_DIXSEPT, TypeCampGroupEnum.COMPAGNON],
    labelKey: 'bdd.campAvis.typeAvis.international',
    droit: 'cmpp',
    displayForCampEnfant: false,
    displayForCampPere: true
  }
];

export const PARAMETRES_AVIS_VAO = [
  {
    typeAvis: TypeAvisEnum.VAO,
    listAvis: CAMP_AVIS_VAO,
    labelKey: 'appli.camp.onglet.suiviVAO.carte.avis.statut',
    droit: 'epa_national',
    displayForCampGroup: [TypeCampEnum.AUDACE],
    displayForCampEnfant: true,
    displayForCampPere: true
  }
];

export const PARAMETRES_ADHERENT_SOUTIEN = [
  {typeSoutien: TypeSoutienEnum.INTERNATIONAL, icon: 'public'},
  {typeSoutien: TypeSoutienEnum.MARIN, icon: 'anchor'},
  {typeSoutien: TypeSoutienEnum.AP, icon: 'question_answer'},
  {typeSoutien: TypeSoutienEnum.ACCOM_COMP, icon: 'people'}
];

export const NATURES_PROJETS_MARIN  = [
  {value: NatureProjetMarinEnum.VOILE_AVEC_PARTENAIRE, text: 'Voile, aviron ou moteur, avec un partenaire'},
  {value: NatureProjetMarinEnum.CANOE_KAYAK_AVEC_PARTENAIRE, text: 'Canoë/ Paddle en mer avec un partenaire'},
  {value: NatureProjetMarinEnum.VOILE_AUTONOMIE, text: 'À la voile en autonomie'},
  {value: NatureProjetMarinEnum.AUTRE_NAVIGATION, text: 'Autre navigation, cas spécifiques'}
];

export const DIPLOMES_MARIN = [
  {value: DiplomeMarinEnum.MONITORAT_FEDERAL, text: 'Monitorat fédéral de voile'},
  {value: DiplomeMarinEnum.BREVET_ETAT, text: 'brevet d\'état'},
  {value: DiplomeMarinEnum.BPJEPS, text: 'BPJEPS'},
  {value: DiplomeMarinEnum.AUTRE, text: 'Autre'}
];

export const TYPES_BATEAUX_MARIN = [
  {value: TypeBateauMarinEnum.VOILE_LEGERE, text: 'Voile légère'},
  {value: TypeBateauMarinEnum.VOILIERS_NON_HABITABLES, text: 'Voiliers collectifs non habitables'},
  {value: TypeBateauMarinEnum.VOILIERS_HABITABLES, text: 'Voiliers habitables '},
  {value: TypeBateauMarinEnum.BATEAU_MOTEUR, text: 'Bateau à moteur'},
  {value: TypeBateauMarinEnum.AUTRE, text: 'Autre'}
];

export const PROPRIETAIRES_BATEAUX_MARIN = [
  {value: ProprietaireBateuMarinEnum.SGDF, text: 'SGDF'},
  {value: ProprietaireBateuMarinEnum.SOCIETE_LOCATION, text: 'Société de location'},
  {value: ProprietaireBateuMarinEnum.PRET_PAR_PROPRIETAIRE, text: 'Prêt par un propriétaire'},
  {value: ProprietaireBateuMarinEnum.AUTRES, text: 'Autres'}
];

export const ROLES_ACTIVITE_NAVIGATION = [
  {value: RoleNavigationMarinEnum.RESPONSABLE_ACTIVITE, text: 'Responsable de l’activité', icon: 'assets/img/ddc-icon-barre-de-roue.png', width: '35px', height: '35px'},
  {value: RoleNavigationMarinEnum.CHEF_BORD, text: 'Chef de bord', icon: 'assets/img/ddc-icon-voile.png',  width: '35px', height: '40px'},
  {value: RoleNavigationMarinEnum.ENCADRANT_SECU, text: 'Encadrant sur sécu', icon: 'assets/img/ddc-icon-moteur.png',  width: '35px', height: '35px'}
];

export const DIPLOMES_MARIN_SGDF = [
  "BAFA Qualification Voile",
  "Brevet d'Etat (activités marines)",
  "Brevet Professionnel (Skipper, marine marchande, .",
  "Carte Mer",
  "Chef de Flottille",
  "Chef de quart",
  "Directeur nautique",
  "Patron d'embarcation",
  "Permis Côtier",
  "Permis fluvial",
];

export const NB_MAX = [
  {value:TypeCampEnum.AUTRE, nombreMax: 300},
  {value:TypeCampEnum.CAMP_ACCOMPAGNE, nombreMax: 1000},
  {value:TypeCampEnum.COMPAGNONS_T1, nombreMax: 300},
  {value:TypeCampEnum.COMPAGNONS_T2, nombreMax: 300},
  {value:TypeCampEnum.COMPAGNONS_T3, nombreMax: 300},
  {value:TypeCampEnum.JADE, nombreMax: 300},
  {value:TypeCampEnum.SIX_HUIT, nombreMax: 300},
  {value:TypeCampEnum.HUIT_ONZE, nombreMax: 300},
  {value:TypeCampEnum.ONZE_QUATORZE, nombreMax: 300},
  {value:TypeCampEnum.QUATORZE_DIXSEPT, nombreMax: 300},
  {value:TypeCampEnum.AUDACE, nombreMax: 300},
  {value:TypeCampEnum.FARFADET, nombreMax: 300},
];

export const SORTED_LIST_PARAMS = 'SORTED_LIST_PARAMS';

export const  FRENCH_CHARECTERS_HTML_CODES = [
  {charecter: 'À', htmlCode: '&Agrave'},
  {charecter: 'à', htmlCode: '&agrave'},
  {charecter: 'Â', htmlCode: '&Acirc'},
  {charecter: 'â', htmlCode: '&acirc'},
  {charecter: 'Æ', htmlCode: '&AElig'},
  {charecter: 'æ', htmlCode: '&aelig'},
  {charecter: 'Ç', htmlCode: '&Ccedil'},
  {charecter: 'ç', htmlCode: '&ccedil'},
  {charecter: 'È', htmlCode: '&Egrave'},
  {charecter: 'è', htmlCode: '&egrave'},
  {charecter: 'É', htmlCode: '&Eacute'},
  {charecter: 'é', htmlCode: '&eacute'},
  {charecter: 'Ê', htmlCode: '&Ecirc'},
  {charecter: 'ê', htmlCode: '&ecirc'},
  {charecter: 'Ë', htmlCode: '&Euml'},
  {charecter: 'ë', htmlCode: '&euml'},
  {charecter: 'Î', htmlCode: '&Icirc'},
  {charecter: 'î', htmlCode: '&icirc'},
  {charecter: 'Ï', htmlCode: '&Iuml'},
  {charecter: 'ï', htmlCode: '&iuml'},
  {charecter: 'Ô', htmlCode: '&Ocirc'},
  {charecter: 'ô', htmlCode: '&ocirc'},
  {charecter: 'Œ', htmlCode: '&OElig'},
  {charecter: 'œ', htmlCode: '&oelig'},
  {charecter: 'Ù', htmlCode: '&Ugrave'},
  {charecter: 'ù', htmlCode: '&ugrave'},
  {charecter: 'Û', htmlCode: '&Ucirc'},
  {charecter: 'û', htmlCode: '&ucirc'},
  {charecter: 'Ü', htmlCode: '&Uuml'},
  {charecter: 'ü', htmlCode: '&uuml'},
  {charecter: '«', htmlCode: '&laquo'},
  {charecter: '»', htmlCode: '&raquo'},
  {charecter: '€', htmlCode: '&euro'},
  {charecter: '₣', htmlCode: ''}
];

export const TYPE_NAVIGATION_MARIN  = [
  {value: TypeNavigationMarinEnum.NAVIGATION_PARTENAIRE, text: 'Navigation avec un partenaire'},
  {value: TypeNavigationMarinEnum.NAVIGATION_AUTONOMIE, text: 'Navigation en autonomie'},
];

export const TYPE_CONTEXTE_MARIN = [
  {value: TypeContexteMarinEnum.CONTEXTE_HABITUEL, text: 'Site et contexte habituel'},
  {value: TypeContexteMarinEnum.ACTIVITE_PONCTUELLE, text: 'Activité ponctuelle'},
];

export const PROJET_ANNEE_AVIS_MARIN = [
  {value: StatutAvisEnum.NON_EMIS, text: 'Non émis'},
  {value: StatutAvisEnum.DELIVRE, text: 'Visa Delivré'},
  {value: StatutAvisEnum.REFUSE, text: 'Visa refusé'},
  {value: StatutAvisEnum.PROJ_NAV_MODIF, text: 'Projet de navigation modifié'},
  {value: StatutAvisEnum.COMPLETE, text: 'Visa complété'},
]

export const PARAMETRES_AVIS_PROJET_ANNEE = [
  {
    typeAvis: TypeAvisEnum.MARIN,
    listAvis: PROJET_ANNEE_AVIS_MARIN,
    labelKey: 'bdd.projetAnneeAvis.typeAvis.marin',
    droit: 'commar'
  },
]

export const PROJET_ANNEE_ALERT_FC = [
  {value: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_1, text: 'Fiche complémentaire Trismestre 1'},
  {value: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_2, text: 'Fiche complémentaire Trismestre 2'},
  {value: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_3, text: 'Fiche complémentaire Trismestre 3'},
  {value: ProjetAnneeCodeModulesEnum.FC_TRIMESTRE_4, text: 'Fiche complémentaire Trismestre 4'},
  {value: ProjetAnneeCodeModulesEnum.FICHE_INITIALE, text: 'Fiche initiale'},
]
