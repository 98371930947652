import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CampConfigurationService } from '../service/camp-configuration.service';
import { ICampModule } from '../model/camp-module.model';
import { ITypeCamp } from '../model/type-camp.model';
import { CampService } from '../service/camp.service';
import { ICamp } from '../model/camp.model';
import { IDdcAdministrationModuleDTO } from '../model/camp-administration-module-dto.model';
import { EventManager } from '../util/event-manager';
import { HttpResponse } from '@angular/common/http';
import { IProjetAnneeModule } from '../model/projet-annee-module.model';
import { ITypeProjetAnnee } from '../model/type-projet-annee.model';
import { ProjetAnneeService } from '../service/projet-annee.service';
import { TypeDossierEnum, TypeProjetAnneeEnum } from '../constant/enum';

@Component({
    selector: 'ddc-edit-module-modal-dialog',
    templateUrl: './edit-ddc-modules-dialog.component.html',
})
export class EditDdcModulesDialogComponent {

    focusedTheme: string;

    idDdc: number;
    ddcModules: ICampModule[] | IProjetAnneeModule[] = [];
    modules: ICampModule[] | IProjetAnneeModule[] = [];
    typeDdc: ITypeCamp | ITypeProjetAnnee;
    displayButtonConfirmer: boolean = true;
    codeModules: object = {};
    formAlreadySubmitted: boolean = false;
    modulesErrorFlag: boolean = false;
    isCampEnfant: boolean = false;
    displayProjetPeda:boolean = true;

    constructor(
        protected campConfigurationService: CampConfigurationService,
        protected campService: CampService,
        protected projetAnneeService: ProjetAnneeService,
        public dialogRef: MatDialogRef<EditDdcModulesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditModuleModalDialogData
    ) {
        this.focusedTheme = this.campConfigurationService.getFocusedTheme();
        this.idDdc = data.idDdc;
        this.typeDdc = data.typeDdc;
        this.ddcModules = this.data.ddcModules || [];
        this.displayButtonConfirmer = data.displayButtonConfirmer === false ? false : true;
        this.isCampEnfant =  this.data.isCampEnfant;
        this.displayProjetPeda = this.data.displayProjetPeda;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick() {
        this.formAlreadySubmitted = true;
        if (this.idDdc && !this.modulesErrorFlag) {
            const ddcModules: IDdcAdministrationModuleDTO = {
                idDdc: this.idDdc,
                selectedModuleCodes: this.codeModules,
            };
            if(this.data.typeDosier === TypeDossierEnum.CAMP) {
                this.campService.updateCampModules(ddcModules)
                    .subscribe((res: HttpResponse<ICamp>) => {
                        EventManager.campModulesUpdated.next(res.body);
                        this.dialogRef.close();
                        this.campService.$modulesChanged.next('true');
                    });
            }else if (this.data.typeDosier === TypeDossierEnum.PROJET_ANNEE) {
              if(this.data.typeDdc.code == TypeProjetAnneeEnum.CAMP_GROUPE){
                ddcModules.selectedModuleCodes["ACTIONS"] = "1";
                ddcModules.selectedModuleCodes["REVES"] = "1";
              }
              this.projetAnneeService.updateProjetAnneeModules(ddcModules)
                    .subscribe((res: HttpResponse<ICamp>) => {
                        EventManager.projetAnneeModulesUpdated.next(res.body);
                        this.dialogRef.close();
                    });
            }

        }
    }

    updateDdcModules(event) {
        this.codeModules = event.codeModules;
        this.modulesErrorFlag = event.modulesErrorFlag;
    }

}

export class EditModuleModalDialogData<T = any> {
    public title?: string;
    public idDdc?: number;
    public ddcModules?: ICampModule[]|ITypeProjetAnnee[];
    public typeDdc?: ITypeCamp|ITypeProjetAnnee;
    public typeDosier?: TypeDossierEnum;
    public displayButtonConfirmer?: boolean;
    public isCampEnfant?: boolean;
    public displayProjetPeda?: boolean;
}
