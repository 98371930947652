import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../service/authentication.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public static readonly REFRESHED_TOKEN = 'x-ddc-refreshed-token';

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ce n'est pas une requête vers l'api
    if (!request || !request.url || (request.url.startsWith('http') && !(environment.apiServerUrl && request.url.startsWith(environment.apiServerUrl)))) {
      return next.handle(request);
    }
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + currentUser.token
        }
      });
    }
    return next.handle(request)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            // Retour d'un appel aux apis.
            // On verifie si un nouveau token est passé dans le header.
            // Si oui, alors on met à jour notre token de session
            if (evt.headers && evt.headers.has(AuthInterceptor.REFRESHED_TOKEN)) {
              console.log('Refreshing Ddc JWT Token...');
              const newDdcToken = evt.headers.get(AuthInterceptor.REFRESHED_TOKEN);
              this.authenticationService.refreshDdcToken(newDdcToken);
            }
          }
        }));
  }
}
