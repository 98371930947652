import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AuthenticationService } from '../shared/service/authentication.service'
import { FormBuilder, FormControl } from '@angular/forms'
import { IDdcFunctionality, IDdcUser } from '../shared/model/ddc-user.model'
import { Router } from '@angular/router'

@Component({
  selector: 'ddc-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent {

  @Output() updateDccLogOutDisplay = new EventEmitter()

  @Input() set state (state: string) {
    this._state = state
    if (this._state === 'open') {
      this.currentUser = this.authenticationService.currentUserValue
      this.initUserFunctionalities()
      this.setDdcUserDefaultFunctionality()
    }
  }

  ddcUserFunctionalityControl: FormControl = this.fb.control([])

  currentUser: IDdcUser
  ddcUserFunctionalities: IDdcFunctionality[] = []
  _state: string

  constructor (
    private authenticationService: AuthenticationService,
    private router: Router,
    protected fb: FormBuilder
  ) {
    this.currentUser = this.authenticationService.currentUserValue
    this.initUserFunctionalities()
    this.setDdcUserDefaultFunctionality()
  }

  initUserFunctionalities () {
    if (this.currentUser && this.currentUser.functionalities && this.currentUser.functionalities.length > 0) {
      this.ddcUserFunctionalities = this.currentUser.functionalities
    }
  }

  setDdcUserDefaultFunctionality () {
    if (this.currentUser && this.currentUser.selectedFunctionality) {
      this.ddcUserFunctionalityControl.setValue(this.currentUser.selectedFunctionality)
    }
  }

  doLogout () {
    this.authenticationService.logout()
    this.close()
  }

  close () {
    this.updateDccLogOutDisplay.emit(false)
  }

  compareDdcUserFunctionalities (param1: IDdcFunctionality, param2: IDdcFunctionality) {
    return param1 && param2 ? param1.codeStructure == param2.codeStructure && param1.codeFonction == param2.codeFonction : false
  }

  changeDdcUserFunctionality (event) {
    if (event && event.value) {
      const functionality: IDdcFunctionality = event.value
      this.authenticationService.changeDdcUserFunctionality(functionality).subscribe((ddcUser: IDdcUser) => {
        this.router.navigate(['/accueil']).then(() => console.log('logged in'))
        location.reload()
      })
    }
  }

  navigateGestionMail () {
    this.router.navigate(['/gestion-email'])
  }
}
