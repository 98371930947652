import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { NatureProjetMarinEnum } from '../constant/enum';
import { ICampModuleMarinEncadrant } from './camp-module-marin-encadrant.model';
import { ICampModuleMarinEncadrantNautique } from './camp-module-marin-encadrant-nautique.model';
import { ICampModuleMarinBateau } from './camp-module-marin-bateau.model';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface ICampModuleMarin {
  id?: number;
  nbreAnimateurs?: number;
  nbreJeunes?: number;
  adherentResponsable?: IAdherent;
  zoneNavigation?: string;
  dateDebutNavigation?: Moment;
  dateFinNavigation?: Moment;
  natureProjet?: NatureProjetMarinEnum;
  descriptionRapideProjet?: string;
  nomPartenaire?: string;
  responsablePartenaire?: string;
  adressePartenaire?: string;
  telephonePartenaire?: string;
  campModuleMarinEncadrants?: ICampModuleMarinEncadrant[];
  typesBateaux?: string;
  campModuleMarinEncadrantsNautiques?: ICampModuleMarinEncadrantNautique[];
  nomCorrespondantTerre?: string;
  prenomCorrespondantTerre?: string;
  adresseCorrespondantTerre?: string;
  campModuleMarinBateaux?: ICampModuleMarinBateau[];
  commentaireCorrespondantTerre?: string;
  descriptionAutreTypeProjet?: string;
  reglementationConfirme?: boolean;
}

export class CampModuleMarin implements ICampModuleMarin {
  constructor(
    public id?: number,
    public nbreAnimateurs?: number,
    public nbreJeunes?: number,
    public adherentResponsable?: IAdherent,
    public zoneNavigation?: string,
    public dateDebutNavigation?: Moment,
    public dateFinNavigation?: Moment,
    public natureProjet?: NatureProjetMarinEnum,
    public descriptionRapideProjet?: string,
    public nomPartenaire?: string,
    public responsablePartenaire?: string,
    public adressePartenaire?: string,
    public telephonePartenaire?: string,
    public campModuleMarinEncadrants?: ICampModuleMarinEncadrant[],
    public typesBateaux?: string,
    public campModuleMarinEncadrantsNautiques?: ICampModuleMarinEncadrantNautique[],
    public nomCorrespondantTerre?: string,
    public prenomCorrespondantTerre?: string,
    public adresseCorrespondantTerre?: string,
    public commentaireCorrespondantTerre?: string,
    public campModuleMarinBateaux?: ICampModuleMarinBateau[],
    public descriptionAutreTypeProjet?: string,
    public reglementationConfirme?: boolean,
  ) {
  }
}

export const CAMP_MODULE_MARIN_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebutNavigation', 'dateFinNavigation']);
