<div class="container-fluid footer-bottom-administation-page">
  <mat-card class="ddc-card-with-title">
    <mat-card-title>
      <span ddcLabel labelKey="appli.accueil.administrationEmail.carte.administrationEmail"></span>
    </mat-card-title>

    <mat-card-content>
      <div ddcLabel labelKey="appli.accueil.administrationEmail.carte.administrationEmail.description"
           class="ddc-appli-description"></div>
      <div class="row">
        <mat-form-field floatLabel="always" class="col-sm-6 col-md-3">
          <mat-select
            (selectionChange)="selectEmailTemplate($event)"
            placeholder="Modèle d'email"
          >
            <mat-option *ngFor="let mailTemplate of MAIL_TEMPLATE" [value]="mailTemplate.value">
              {{mailTemplate.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="emailTemplate">
        <div class="row ddc-block-margin-bottom">
          <div class="col-md-8 col-sm-12">
            <mat-card class="ddc-subcard-with-title">
              <mat-card-title>
                <span ddcLabel labelKey="appli.accueil.administrationEmail.carte.administrationEmail.content"></span>
              </mat-card-title>
              <mat-form-field floatLabel="always" class="col">
          <textarea type="text" [rows]="10" matInput placeholder="Le contenu doit se présenter sous la forme html dans une balise <table></table>."
                    [(ngModel)]="emailTemplate.content"></textarea>
              </mat-form-field>
            </mat-card>
          </div>
          <div class="col-md-4 col-sm-12">
            <mat-card class="ddc-subcard-with-title">
              <mat-card-title>
                <span ddcLabel labelKey="appli.accueil.administrationEmail.carte.administrationEmail.variables"></span>
              </mat-card-title>
              <div class="ddc-section-variable">
                <ng-container *ngFor="let variable of variables">
                  {{variable}}
                </ng-container>
              </div>
            </mat-card>
          </div>

        </div>
        <div class="row">
          <div class="col-md-8 col-sm-12 ddc-button-mofification">
            <button *ngIf="emailTemplate" mat-raised-button class="ddc-button-primary ddc-button-right"
                    (click)="updateEmailTemnplate()">
              Enregistrer
            </button>
          </div>
        </div>

        <div ddcLabel labelKey="appli.accueil.administrationEmail.carte.administrationEmail.descriptionTest"
             class="ddc-appli-description"></div>
        <div class="row">
          <mat-form-field floatLabel="always" class="col-6">
            <input type="text"  matInput [(ngModel)]="emailAddress"/>
          </mat-form-field>
          <div class="col-6 ddc-button-envoyer-test">
            <button *ngIf="emailTemplate"
                    [disabled]="!emailAddress"
                    mat-raised-button class="ddc-button-primary float-left"
                    (click)="sendTestEmail()">
              Envoyer
            </button>
          </div>
        </div>
      </ng-container>

    </mat-card-content>
  </mat-card>

</div>
