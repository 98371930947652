<div class="{{focusedTheme}}">
  <h1 mat-dialog-title class="ddc-modal-dialog-title">Confirmation</h1>
  <div mat-dialog-content>
    <div class="container-fluid ddc-no-padding">
      <div class="row ddc-no-padding">
        <div class="col-12 ddc-no-padding ddc-textfield-div ddc-left">
          <div class="ddc-read-only-data-container">
            <ng-container *ngIf="data && data.contentLabelKey; else simpleTextMessage">
              <span class="ddc-label" ddcLabel [labelKey]="data.contentLabelKey"></span>
            </ng-container>
            <ng-template #simpleTextMessage>
              <span class="ddc-label" [innerHTML]="data?.content"></span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="row">
    <div class="col-auto ddc-offset-auto">
      <div class="row ddc-right">
        <div class="col-auto">
          <button mat-raised-button class="ddc-button-primary" (click)="onYesClick()">{{data?.confirmText}}</button><!--cdkFocusInitial-->
        </div>
        <div class="col-auto">
          <button mat-raised-button color="warn" (click)="onNoClick()">{{data?.cancelText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
