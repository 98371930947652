import {Component} from '@angular/core';
import {CampNotificationService} from '../../../shared/service/camp-notification.service';
import {ICampNotification} from '../../../shared/model/camp-notification.model';
import {filterOkMapBody} from '../../../shared/util/http.utils';
import {NotificationStatutEnum, TypeDossierEnum, TypeNotificationEnum} from '../../../shared/constant/enum';
import {NOTIFICATION_PARAMS} from '../../../shared/constant/constant';
import {Router} from '@angular/router';
import {DdcMatDialogService} from '../../../shared/service/ddc-mat-dialog.service';
import {convertToHumanDate} from '../../../shared/util/moment.utils';
import {EventManager} from '../../../shared/util/event-manager';
import {IProjetAnneeNotification} from "../../../shared/model/projet-annee-notification.model";
import {ProjetAnneeNotificationService} from "../../../shared/service/projet-annee-notification.service";

@Component({
  selector: 'ddc-mes-notifications',
  templateUrl: './mes-notifications.component.html',
  styleUrls: ['mes-notifications.component.scss']
})
export class MesNotificationsComponent {

  campNotifications: ICampNotification[] = [];
  projetAnneeNotifications : IProjetAnneeNotification[] = [];
  convertToHumanDate = convertToHumanDate;

  NotificationStatutEnum = NotificationStatutEnum;
  isInitialized = false;
  TypeNotificationEnum = TypeNotificationEnum;

  constructor(
    protected router: Router,
    protected campNotificationService: CampNotificationService,
    protected projetAnneeNotificationService: ProjetAnneeNotificationService,
    protected ddcMatDialogService: DdcMatDialogService
  ) {
    this.initNotifications();
  }

  initNotifications() {
    this.campNotificationService.findActiveNotificationsByConnectedAdherent()
      .pipe(filterOkMapBody)
      .subscribe(res => {
        this.isInitialized = true;
        this.setNotifications(res,TypeDossierEnum.CAMP);
      });
    this.projetAnneeNotificationService.findActiveNotificationsByConnectedAdherent()
      .pipe(filterOkMapBody)
      .subscribe(res => {
        this.isInitialized = true;
        this.setNotifications(res, TypeDossierEnum.PROJET_ANNEE);
      });
  }

  getNotificationParams(typeNotificationEnum: TypeNotificationEnum) {
    const notificationParams = NOTIFICATION_PARAMS.find(notificationParams => notificationParams.typeNotification === typeNotificationEnum);
    return notificationParams ? notificationParams : NOTIFICATION_PARAMS.find(notificationIcon => notificationIcon.typeNotification === TypeNotificationEnum.DEFAULT);
  }

  getNotificationContenu(notification: ICampNotification | IProjetAnneeNotification) {
    if(notification && "camp" in notification && notification.camp) {
      return 'Dossier ' + notification.campId + ' - ' + notification.campLibelle + ' - ' + notification.contenu;
    }
    if(notification && "projetAnnee" in notification && notification.projetAnnee) {
      return 'Projet d\'année ' + notification.projetAnnee.id + ' - ' + notification.projetAnnee.libelle + ' - ' + notification.contenu;
    }
    return notification ? notification.contenu : '';
  }

  setNotifications(notifications, typeDdc? : TypeDossierEnum) {
    if (typeDdc == TypeDossierEnum.CAMP) {
      this.campNotifications = notifications;
      EventManager.campNotificationsUpdated.next(this.campNotifications);
    }
    if (typeDdc == TypeDossierEnum.PROJET_ANNEE) {
      this.projetAnneeNotifications = notifications;
      EventManager.projetAnneeNotificationsUpdated.next(this.projetAnneeNotifications);
    }
  }

  accessProjet(notification, typeDdc) {
    const queryParms = {};
    if(notification && notification.codeModule) {
      queryParms['codeModule'] = notification.codeModule;
    }
    if (typeDdc == TypeDossierEnum.CAMP) {
      this.router.navigate([`/camp/${notification.campId}`],{queryParams: queryParms});
    } else {
      this.router.navigate([`/projet-annee/${notification.projetAnnee.id}`],{queryParams: queryParms});
    }
  }

  markNotificationAsRead(notification, typeDdc: TypeDossierEnum, accessDdc?: boolean) {
    let service;
    let notifications;
    service = typeDdc == TypeDossierEnum.CAMP ? this.campNotificationService : this.projetAnneeNotificationService
    notifications = typeDdc == TypeDossierEnum.CAMP ? this.campNotifications : this.projetAnneeNotifications
    if (notification && notification.statut == NotificationStatutEnum.UNREAD) {
      service.markAsRead(notification)
        .pipe(filterOkMapBody)
        .subscribe(res => {
          notification.statut = res.statut;
          this.setNotifications(notifications, typeDdc);
          if (accessDdc === true) this.accessProjet(notification, typeDdc);
        })
    } else if (notification && accessDdc === true) {
      this.accessProjet(notification, typeDdc);
    }
  }

  openDialogClearNotification(notification, typeDdc: TypeDossierEnum) {
    this.ddcMatDialogService.openConfirmActionDialog({
      content: 'Voulez-vous effacer cette notification ?',
      confirmText: 'Confirmer',
      cancelText: 'Annuler',
      confirmAction: () => typeDdc == TypeDossierEnum.CAMP ? this.clearNotification(notification, TypeDossierEnum.CAMP) : this.clearNotification(notification, TypeDossierEnum.PROJET_ANNEE)
    });
  }

  clearNotification(notification, typeDdc: TypeDossierEnum) {
    let notificationService;
    let notifications;
    notificationService = typeDdc == TypeDossierEnum.CAMP ? this.campNotificationService : this.projetAnneeNotificationService
    notifications = typeDdc == TypeDossierEnum.CAMP ? this.campNotifications: this.projetAnneeNotifications

    notificationService.clear(notification)
        .pipe(filterOkMapBody)
        .subscribe((res) => {
          // After being sure that the notification has been successfully cleared, remove it from the array and update the global campNotifications
          if(res.statut === NotificationStatutEnum.CLEARED) {
            const projetNotifications = notifications.filter((notification) => notification.id != res.id);
            this.setNotifications(projetNotifications,typeDdc);
          }
        });
  }

  markAllCampNotificationsAsRead() {
    const campNotificationsIds: number[] =
      this.campNotifications
        .filter((campNotification: ICampNotification) => campNotification.statut == NotificationStatutEnum.UNREAD)
        .map((campNotification: ICampNotification) => campNotification.id);

    const projetAnneeNotificationsIds: number[] =
      this.projetAnneeNotifications
        .filter((projetAnneeNotification: IProjetAnneeNotification) => projetAnneeNotification.statut == NotificationStatutEnum.UNREAD)
        .map((projetAnneeNotification: IProjetAnneeNotification) => projetAnneeNotification.id);

    if(campNotificationsIds && campNotificationsIds.length > 0) {
      this.campNotificationService.markAllAsRead(campNotificationsIds)
        .pipe(filterOkMapBody)
        .subscribe((res: ICampNotification[]) => {
          // Update the view after making sure that the notications are well updated in the database
          const campNotifications: ICampNotification[] =
            this.campNotifications.map((campNotification:ICampNotification) => {
              const updatedCampNotification = res.find((notifcation: ICampNotification) => notifcation.id == campNotification.id);
              if(updatedCampNotification && updatedCampNotification.statut == NotificationStatutEnum.READ) {
                campNotification.statut = NotificationStatutEnum.READ;
              }
              return campNotification;
            });
          this.setNotifications(campNotifications, TypeDossierEnum.CAMP);
        });
    }

    if (projetAnneeNotificationsIds && projetAnneeNotificationsIds.length > 0) {
      this.projetAnneeNotificationService.markAllAsRead(projetAnneeNotificationsIds)
        .pipe(filterOkMapBody)
        .subscribe((res: IProjetAnneeNotification[]) => {
          // Update the view after making sure that the notications are well updated in the database
          const projetAnneeNotifications: IProjetAnneeNotification[] =
            this.projetAnneeNotifications.map((projetAnneeNotification: IProjetAnneeNotification) => {
              const updatedProjetAnneeNotification = res.find((notification: IProjetAnneeNotification) => notification.id == projetAnneeNotification.id);
              if (updatedProjetAnneeNotification && updatedProjetAnneeNotification.statut === NotificationStatutEnum.READ) {
                projetAnneeNotification.statut = NotificationStatutEnum.READ;
              }
              return projetAnneeNotification;
            });
          this.setNotifications(projetAnneeNotifications, TypeDossierEnum.PROJET_ANNEE);
        });
    }
  }

  openDialogClearAllCampNotifications() {
    this.ddcMatDialogService.openConfirmActionDialog({
      content: 'Voulez-vous effacer toutes les notifications ?',
      confirmText: 'Confirmer',
      cancelText: 'Annuler',
      confirmAction: () => this.clearAllCampNotifications()
    });
  }

  clearAllCampNotifications() {
    const campNotificationsIds: number[] =
      this.campNotifications.filter((campNotification: ICampNotification) => campNotification.statut != NotificationStatutEnum.CLEARED)
        .map((campNotification: ICampNotification) => campNotification.id);

    const projetAnneeNotificationsIds: number[] =
      this.projetAnneeNotifications.filter((projetAnneeNotification: IProjetAnneeNotification) => projetAnneeNotification.statut != NotificationStatutEnum.CLEARED)
        .map((projetAnneeNotification: IProjetAnneeNotification) => projetAnneeNotification.id);

    if (campNotificationsIds && campNotificationsIds.length > 0) {
      this.campNotificationService.clearAll(campNotificationsIds)
        .pipe(filterOkMapBody)
        .subscribe((res: ICampNotification[]) => {
          this.setNotifications([], TypeDossierEnum.CAMP);
        });
    }
    if (projetAnneeNotificationsIds && projetAnneeNotificationsIds.length > 0) {
      this.projetAnneeNotificationService.clearAll(projetAnneeNotificationsIds)
        .pipe(filterOkMapBody)
        .subscribe((res: IProjetAnneeNotification[]) => {
          this.setNotifications([], TypeDossierEnum.PROJET_ANNEE);
        });
    }
  }

  refreshNotifications() {
    this.initNotifications();
  }

  protected readonly TypeDossierEnum = TypeDossierEnum;
}
