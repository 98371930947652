import { Injectable } from '@angular/core';
import { ConfirmActionDialogComponent, ConfirmActionDialogData } from '../mat-dialog/confirm-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditCampInfoDialogComponent, EditModalDialogData } from '../mat-dialog/edit-camp-info-dialog.component';
import {
  EditDdcModulesDialogComponent,
  EditModuleModalDialogData
} from '../mat-dialog/edit-ddc-modules-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DdcMatDialogService {

  private static readonly CONFIRM_ACTION_DIALOG_WIDTH = '30vw';
  private static readonly EDIT_CAMP_INFO_DIALOG_WIDTH = '80vw';
  private static readonly EDIT_CAMP_MODULES_DIALOG_WIDTH = '80vw';

  constructor(
    protected dialog: MatDialog,
  ){}

  openConfirmActionDialog(data: ConfirmActionDialogData): void {
    const dialogConfig = {
      width: DdcMatDialogService.CONFIRM_ACTION_DIALOG_WIDTH,
      data: data,
      disableClose: false,
      autoFocus: true
    };
		this.dialog.open(ConfirmActionDialogComponent, dialogConfig);
  }

  openCampEditInfoDialog(data: EditModalDialogData) {
    const dialogConfig = {
      width: DdcMatDialogService.EDIT_CAMP_INFO_DIALOG_WIDTH,
      data: data,
      disableClose: false,
      autoFocus: true
    };
    let dialogRef = this.dialog.open(EditCampInfoDialogComponent, dialogConfig);
    return dialogRef;
  }

  openEditCampModulesDialog(data: EditModuleModalDialogData): void {
    const dialogConfig = {
      width: DdcMatDialogService.EDIT_CAMP_MODULES_DIALOG_WIDTH,
      data: data,
      disableClose: false,
      autoFocus: true
    };
    this.dialog.open(EditDdcModulesDialogComponent, dialogConfig)
  }

}
