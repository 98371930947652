<div class="ddc-theme-master">
  <div *ngIf="!isVisible" class="ddc-footer">
    <div class="row ddc-footer-block">

      <div>
        <img src="assets/img/ddc-logo-blanc-1.png" alt="SGDF"/>
      </div>

      <div>
        <span class="ddc-footer-text" ddcLabel labelKey="appli.footer.mentionLegale"></span>
      </div>

      <div>
        <span class="ddc-footer-text">
          v.{{_appVersion}} - Copyright 2020
        </span>
      </div>

    </div>
  </div>
</div>
