import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IEmailTemplate, IEmailTemplateTestDto, IEmailTemplateUpdateDto } from "../model/email.model";
import { IMailOptions } from "../model/mail-options.model";
import { SnackBarService } from "./snackbar.service";

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  constructor(
    protected http: HttpClient,
    private snackBarService: SnackBarService
  ) {
  }

  public resourceUrl = environment.apiServerUrl + 'email';

  findAll(): Observable<HttpResponse<IEmailTemplate[]>> {
    return this.http
      .get<IEmailTemplate[]>(this.resourceUrl, {observe: 'response'});
  }

  findByCode(code: string): Observable<HttpResponse<IEmailTemplate>> {
    return this.http
      .get<IEmailTemplate>(`${this.resourceUrl}/${code}`, {observe: 'response'})
  }

  updateEmailTemplate(emailTemplateUpdateDto: IEmailTemplateUpdateDto) {
    return this.http
      .put<IEmailTemplate>(`${this.resourceUrl}`, emailTemplateUpdateDto, {observe: 'response'})
      .subscribe(
        value => {
          this.snackBarService.showSuccess('Les données ont été enregistrées avec succès', {durationInSeconds: 2});
        }
        ,
        (err) => {
          this.snackBarService.showError(err);
        }
      )
  }

  sendTestEmail(email: IEmailTemplateTestDto): Observable<HttpResponse<IEmailTemplateTestDto>> {
    return this.http
      .post<IEmailTemplateTestDto>(`${this.resourceUrl}/send-test`, email, {observe: 'response'});
  }

  getAdherentMailOptions(): Observable<HttpResponse<any>> {
    return this.http
      .get(`${this.resourceUrl}/adherent-mail-options`, {observe: 'response'})
  }

  changeMailOptions(mailOptions: IMailOptions) {
    return this.http
      .put<IMailOptions>(`${this.resourceUrl}/change-adherent-mail-option`, mailOptions)
      .subscribe(
        value => {
          this.snackBarService.showSuccess('Les données ont été enregistrées avec succès', {durationInSeconds: 2});
        }
        ,
        (err) => {
          this.snackBarService.showError(err);
        }
      )
  }
}
