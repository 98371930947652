import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EventManager } from './shared/util/event-manager';
import { CampConfigurationService } from './shared/service/camp-configuration.service';
import { VERSION } from '../environments/version';
import { CampNotificationService } from './shared/service/camp-notification.service';
import { filterOkMapBody } from './shared/util/http.utils';
import { ICampNotification } from './shared/model/camp-notification.model';
import { CampDiscussionSujetService } from './shared/service/camp-discussion-sujet.service';
import { ICampDiscussionSujet } from './shared/model/camp-discussion-sujet.model';
import { AuthenticationService } from './shared/service/authentication.service';
import { filter } from 'rxjs/operators';
import { LocalCacheService } from './shared/service/local-cache.service';
import { IDdcUser } from './shared/model/ddc-user.model';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'ddc-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterContentChecked {
  title = 'ddc-client';

  /* index for theme selection
  directly injected in the class properties in the HTML (menu component + outlet)
  properties defined in the app.component.scss */

  focusedTheme: string;
  appVersion = VERSION.version;
  currentDdcUser$: Observable<IDdcUser>;
  logOutPopUpState: boolean = false;

  constructor(
    private campConfigurationService: CampConfigurationService,
    private campNotificationService: CampNotificationService,
    private campDiscussionSujetService: CampDiscussionSujetService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    this.currentDdcUser$ = this.authenticationService.currentUser;

    if (authenticationService.currentUserValue && authenticationService.isTokenValid()) {
      // Get the list of notifications for the connected user when accessing the application
      this.campNotificationService.findActiveNotificationsByConnectedAdherent()
        .pipe(filterOkMapBody)
        .subscribe((campNotifications: ICampNotification[]) => {
          EventManager.campNotificationsUpdated.next(campNotifications);
        });

      // Get the list of actions for the connected user when accessing the application
      this.campDiscussionSujetService.findActiveDiscussionForConnectedUser()
        .pipe(filterOkMapBody)
        .subscribe((campDiscussionSujets: ICampDiscussionSujet[]) => {
          EventManager.mesActionsUpdated.next(campDiscussionSujets);
        });
    }

    EventManager.focusedThemeUpdated.subscribe(focusedTheme => {
      this.focusedTheme = focusedTheme;
    });
    campConfigurationService.setCampConfigurationsByTypeCamp(null);

    this.saveLastVisitedUrl();
  }

  isDisplayableMenu() {
    return this.router.url !== '/login';
  }

  saveLastVisitedUrl() {
    this.currentDdcUser$.subscribe((currentDdcUser: IDdcUser) => {
      if(currentDdcUser && currentDdcUser.numero) {
        this.logOutPopUpState = false;
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((navigationEnd: NavigationEnd) => {
            if (navigationEnd && navigationEnd.url !== '/login' && navigationEnd && navigationEnd.url !== '/') {
              LocalCacheService.setLastVisitedUrl(navigationEnd.url.toString(), currentDdcUser);
            }
          });
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  logOutPopUpStateEvent(){
    this.logOutPopUpState = !this.logOutPopUpState;
  }
}
