import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CAMP_DATE_FIELDS_DEFINITION, ICamp, ICampCreationDTO, ICampsPaginationDto } from '../model/camp.model';
import {Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  convertDateFromClient as utils_convertDateFromClient,
  convertDateFromServer as utils_convertDateFromServer
} from '../util/moment.utils';
import {ICampInfoComplementaire} from '../model/camp-info-complementaire.model';
import {CampCodeModulesEnum, CampModificationActionEnum, TypeInfoComplementaireEnum} from '../constant/enum';
import {IDdcAdministrationModuleDTO} from '../model/camp-administration-module-dto.model';
import {CampModificationStaticModuleDTO} from '../model/camp-modification-static-module-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CampService {

  private static PSEUDO_MODULE_ENTETE = 'ENTETE';

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'camps';

  savingEmitter = new Subject();
  $modulesChanged = new Subject();

  static convertCampDateFromServer(camp: ICamp): ICamp {
    if (camp) {
      utils_convertDateFromServer(camp, CAMP_DATE_FIELDS_DEFINITION);
    }
    return camp;
  }

  private static convertDateFromClient(camp: ICamp): ICamp {
    return utils_convertDateFromClient(camp, CAMP_DATE_FIELDS_DEFINITION);
  }

  private static convertDateFromClientForCampCreationDTO(campCreationDTO: ICampCreationDTO): ICampCreationDTO {
    return utils_convertDateFromClient(campCreationDTO, CAMP_DATE_FIELDS_DEFINITION);
  }

  find(params): Observable<HttpResponse<ICampsPaginationDto>> {
    return this.http
      .get<ICampsPaginationDto>(`${this.resourceUrl}`, {observe: 'response', params: params})
      .pipe(map(res => this.convertCampsPaginationFromServer(res)));
  }

  findOneByIdAndModuleCode(id: number, moduleCode?: string): Observable<HttpResponse<ICamp>> {
    const moduleCodeQuery = `?module=${moduleCode ? moduleCode : CampService.PSEUDO_MODULE_ENTETE}`;
    return this.http
      .get<ICamp>(`${this.resourceUrl}/${id}${moduleCodeQuery}`, {observe: 'response'})
      .pipe(map(res => this.convertCampDateFromServer(res)));
  }

  findWithCriterias(params: HttpParams): Observable<HttpResponse<ICampsPaginationDto>> {
    return this.http
      .get<ICampsPaginationDto>(`${this.resourceUrl}/multi-criteres?`,{ observe: 'response', params: params })
      .pipe(map(res => this.convertCampsPaginationFromServer(res)));
  }

  downloadCSVFile(params: HttpParams): Observable<Blob> {
    return this.http.get<Blob>(`${this.resourceUrl}/multi-criteres?`, {params: params, responseType: 'blob' as 'json'});
  }

  /**
   * Appel du service de mise à jour des données statiques d'un camp.
   * La différentiation des modules est effetuée coté serveur.
   * On transmet également la représentation JSON calculée coté client des modifications apportées
   * afin de mettre à jour l'historique.
   * L'utilisation du calcul des maj coté client n'est pas aussi sécure que si effectuée coté serveur
   * mais le TS permet un traitement/calcul beaucoup plus simple et souple que PHP.
   * De plus, le contexte de cette application (pour les chef/responsable) et le but final de l'historique des modifications (infos)
   * ne représente pas un grand risque.
   */
  updateByCampAndModuleCode(camp: ICamp, modificationJson: object, moduleCode?: string, action?: CampModificationActionEnum): Observable<HttpResponse<ICamp>> {
    const dto = new CampModificationStaticModuleDTO(moduleCode, CampService.convertDateFromClient(camp), modificationJson ? JSON.stringify(modificationJson) : null, action);
    const moduleCodePath = '/module';
    return this.http
      .put<ICamp>(`${this.resourceUrl}${moduleCodePath}`, dto, {observe: 'response'})
      .pipe(
        map(res => this.convertCampDateFromServer(res))
      );
  }

  findCampInfoComplementaire(id: number, typeIC: TypeInfoComplementaireEnum, moduleCode?: string, idDynModule?: number): Observable<HttpResponse<ICampInfoComplementaire>> {
    moduleCode = moduleCode == undefined ? CampCodeModulesEnum.HISTORIQUE_COMPLET : moduleCode;
    const moduleCodeQuery = moduleCode ? `module=${moduleCode}` : '';
    const dynModuleQuery = idDynModule ? `idDynModule=${idDynModule}` : '';
    let query = '';
    if (moduleCodeQuery && dynModuleQuery) {
      query = `?${moduleCodeQuery}&${dynModuleQuery}`;
    } else if (moduleCodeQuery && !dynModuleQuery) {
      query = `?${moduleCodeQuery}`;
    } else if (!moduleCodeQuery && dynModuleQuery) {
      query = `?${dynModuleQuery}`;
    }
    return this.http
      .get<ICampInfoComplementaire>(`${this.resourceUrl}/${id}/infos-complementaires/${typeIC}${query}`, {observe: 'response'})
      .pipe(res => res);
  }

  updateCampModules(campModules: IDdcAdministrationModuleDTO): Observable<HttpResponse<ICamp>> {
    return this.http
      .put<ICamp>(`${this.resourceUrl}/administration-modules`, campModules, {observe: 'response'})
      .pipe(map(res => this.convertCampDateFromServer(res)));
  }

  create(campCreationDTO: ICampCreationDTO): Observable<HttpResponse<ICamp>> {
    const copy = CampService.convertDateFromClientForCampCreationDTO(campCreationDTO);
    return this.http
      .post<ICamp>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map(res => this.convertCampDateFromServer(res)));
  }

  createCampEnfant(campCreationDTO: ICampCreationDTO): Observable<HttpResponse<ICamp>> {
    const copy = CampService.convertDateFromClientForCampCreationDTO(campCreationDTO);
    return this.http
      .post<ICamp>(`${this.resourceUrl}/enfant`, copy, {observe: 'response'})
      .pipe(map(res => this.convertCampDateFromServer(res)));
  }

  deleteCampEnfant(idCampEnfant: number) {
    return this.http
      .put<ICamp>(`${this.resourceUrl}/enfant/delete/${idCampEnfant}`, {idCampEnfant}, {observe: 'response'})
      .pipe(map(res => this.convertCampDateFromServer(res)));
  }

  modifierDatePresenceParticipants(idCamp: number) {
    return this.http
      .put<any>(`${this.resourceUrl}/modifier-date-presence-participants?idCamp=${idCamp}`, {idCamp})
  }

  getDatesInterval(): Observable<HttpResponse<any[]>> {
    return this.http
      .get<any[]>(`${this.resourceUrl}/creation/dates-interval`, {observe: 'response'});
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour un camp)
   * @param response
   */
  protected convertCampDateFromServer(response: HttpResponse<ICamp>): HttpResponse<ICamp> {
    return new HttpResponse({
      ...response,
      body: CampService.convertCampDateFromServer(response.body)
    });
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour plusieurs camp)
   * @param res
   */
  protected convertDateArrayFromServer(res: HttpResponse<ICamp[]>): HttpResponse<ICamp[]> {
    if (res.body) {
      res.body.forEach((camp: ICamp) => {
        CampService.convertCampDateFromServer(camp);
      });
    }
    return res;
  }

  protected convertCampsPaginationFromServer(res: HttpResponse<ICampsPaginationDto>): HttpResponse<ICampsPaginationDto>{
    if(res && res.body) {
      res.body.camps.forEach((camp: ICamp) => {
        CampService.convertCampDateFromServer(camp);
      });
    }
    return res;
  }
}
