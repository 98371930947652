export interface IEmailTemplate {
  code?: string;
  content?: string;
  variables?: string;
}

export class EmailTemplate implements IEmailTemplate {
  constructor(
    public code?: string,
    public content?: string,
    public variables?: string
  ) {
  }
}

export interface IEmailTemplateUpdateDto {
  code?: string;
  content?: string;
}

export class EmailTemplateUpdateDto implements IEmailTemplateUpdateDto {
  constructor(
    public code?: string,
    public content?: string,
  ) { }
}

export interface IEmailTemplateTestDto {
  code?: string;
  emailAddress?: string;
}

export class EmailTemplateTestDto implements IEmailTemplateTestDto {
  constructor(
    public code?: string,
    public emailAddress?: string
  ) {
  }
}
