<ng-container *ngIf="focusedTheme && appVersion">
  <ddc-menu *ngIf="currentDdcUser$ | async" (logOutCloseEvent)="logOutPopUpStateEvent()" [logOutPopUpState]="logOutPopUpState" [focusedTheme]="focusedTheme" [hidden]="!isDisplayableMenu()"></ddc-menu>
  <div ddcMouseEventManager (logOutPopUpStateEvent)="logOutPopUpStateEvent()" class="ddc-content {{focusedTheme}}">
    <ddc-loading></ddc-loading>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 ddc-no-padding">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <ddc-footer [appVersion]="appVersion" [focusedTheme]="focusedTheme" [hidden]="!isDisplayableMenu()"></ddc-footer>
</ng-container>
