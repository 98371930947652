import { Subject } from 'rxjs';
import { ICamp } from '../model/camp.model';
import { IProjetAnnee } from '../model/projet-annee.model';
import { ICampNotification } from '../model/camp-notification.model';
import { ICampDiscussionSujet } from '../model/camp-discussion-sujet.model';
import {IProjetAnneeNotification} from "../model/projet-annee-notification.model";

export class EventManager {
  public static campOngletUpdated = new Subject<ICamp>();
  public static projetAnneeOngletUpdated = new Subject<IProjetAnnee>();
  public static campModulesUpdated = new Subject<ICamp>();
  public static projetAnneeModulesUpdated = new Subject<IProjetAnnee>();
  public static focusedThemeUpdated = new Subject<string>();
  public static campLabelsUpdated = new Subject<object>();
  public static campNotificationsUpdated = new Subject<ICampNotification[]>();
  public static projetAnneeNotificationsUpdated = new Subject<IProjetAnneeNotification[]>();
  public static mesActionsUpdated = new Subject<ICampDiscussionSujet[]>()
}
