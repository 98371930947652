import { ADHERENT_DATE_FIELDS_DEFINITION, IAdherent } from './adherent.model';
import { ICampAdherent } from './camp-adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition, SubDateFieldDefinition } from '../util/moment.utils';

export interface ICampAdherentStaff extends ICampAdherent {
    roleStaff?: string;
    validationStagePratiqueBafa?: boolean;
    validationStagePratiqueBafd?: boolean;
    responsabiliteIntendant?: boolean;
    responsabiliteTresorier?: boolean;
    responsabiliteAS?: boolean;
    responsabiliteMateriel?: boolean;
    responsabiliteAutre?: boolean;
    responsabiliteAutreDetail?: string;
    commentaireAdherentStaff?: string;
    _isDirectAttachment?: boolean;
}

export class CampAdherentStaff implements ICampAdherentStaff {
  constructor(
    public id?: number,
    public adherent?: IAdherent,
    public dateDebutPresence?: Moment,
    public dateFinPresence?: Moment,
    public roleStaff?: string,
    public validationStagePratiqueBafa?: boolean,
    public validationStagePratiqueBafd?: boolean,
    public responsabiliteIntendant?: boolean,
    public responsabiliteTresorier?: boolean,
    public responsabiliteAS?: boolean,
    public responsabiliteMateriel?: boolean,
    public responsabiliteAutre?: boolean,
    public responsabiliteAutreDetail?: string,
    public commentaireAdherentStaff?: string,
    public _isDirectAttachment?: boolean
  ) { }
}

export const CAMP_ADHERENT_STAFF_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebutPresence', 'dateFinPresence'],{
  adherent: new SubDateFieldDefinition(ADHERENT_DATE_FIELDS_DEFINITION)
});
