import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private totalRequests = 0;

  private loading$ = new BehaviorSubject(false);

  public increaseRequests() {
    this.totalRequests++;
    this.loading$.next(true);
  }

  public decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loading$.next(false);
    }
  }

  public isLoadingAsObservable(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}
