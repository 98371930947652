<div class="{{focusedTheme}}">
  <h1 mat-dialog-title class="ddc-modal-dialog-title">
    {{dialogTitle}}
  </h1>

  <div
    *ngIf="data.codeModule === 'GRILLE_ACTIVITE'"
    mat-dialog-content>
    <div class="container-fluid">
      <div *ngFor="let form of tmpFormArray.controls; let i = index;" class="row">
        <mat-card class="ddc-card-with-title col-12">
          <mat-card-title>
            <ng-container *ngIf="data.typeCamp.codeGroupeCamp != TypeCampGroupEnum.COMPAGNON; else compagnonCreneauText">
              {{getCreneauText(form.get('creneau')?.value)}}
            </ng-container>
            <ng-template #compagnonCreneauText>
              <span ddcLabel labelKey="{{getCreneauText(form.get('creneau')?.value)}}"></span>
            </ng-template>
          </mat-card-title>
          <mat-card-content>
            <div class="row">
              <mat-form-field floatLabel="always" class="col-md-6">
                <mat-label ddcLabel labelKey="bdd.campGrilleActivite.description"></mat-label>
                <textarea type="text" matInput [rows]="1" [formControl]="form.get('description')"></textarea>
              </mat-form-field>
              <ng-container *ngIf="data.typeCamp &&
              (
                data.typeCamp.codeGroupeCamp == TypeCampGroupEnum.HUIT_DIXSEPT ||
                data.typeCamp.codeGroupeCamp == TypeCampGroupEnum.AUDACE ||
                data.typeCamp.codeGroupeCamp == TypeCampGroupEnum.FARFADET
              )">
                <mat-form-field floatLabel="always" class="col-md-6">
                  <mat-label ddcLabel labelKey="bdd.campGrilleActivite.imaginaire"></mat-label>
                  <textarea type="text" matInput [rows]="1" [formControl]="form.get('imaginaire')"></textarea>
                </mat-form-field>
                <mat-form-field floatLabel="always" class="col-md-6">
                  <mat-label ddcLabel labelKey="bdd.campGrilleActivite.staffResponsable"></mat-label>
                  <mat-select multiple
                              [compareWith]="compareCampAdherentStaffs"
                              (selectionChange)="setcampGrilleActiviteCampAdherentStaffs(form, $event)"
                              [value]="getcampGrilleActiviteCampAdherentStaffs(form)"
                  >
                    <mat-option *ngFor="let campAdherentStaff of data.campAdherentStaffs"
                                [value]="campAdherentStaff">
                      {{campAdherentStaff.adherent.prenom}} {{campAdherentStaff.adherent.nom}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="data.typeCamp && data.typeCamp.codeGroupeCamp == TypeCampGroupEnum.COMPAGNON">
                <mat-form-field floatLabel="always" class="col-md-6">
                  <mat-label ddcLabel labelKey="bdd.campGrilleActivite.participantResponsable"></mat-label>
                  <mat-select [formControl]="form.get('participantResponsable')"
                              [compareWith]="compareGrilleActiviteValues"
                  >
                    <mat-option *ngFor="let campAdherentParticipant of data.campAdherentParticipants"
                                [value]="campAdherentParticipant">
                      {{campAdherentParticipant.adherent.prenom}} {{campAdherentParticipant.adherent.nom}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <mat-form-field floatLabel="always" class="col-md-6">
                <mat-label ddcLabel labelKey="bdd.campGrilleActivite.objectifPedagogique"></mat-label>
                <textarea type="text" matInput [rows]="1" [formControl]="form.get('objectifPedagogique')"></textarea>
              </mat-form-field>

            </div>
            <div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div *ngIf="data.codeModule === 'MENU'" mat-dialog-content>
    <div class="container-fluid">
      <div *ngFor="let form of tmpFormArray.controls" class="row">
        <mat-card class="ddc-card-with-title col-12">
          <mat-card-title>
            {{getRepasText(form.get('repas')?.value)}}
          </mat-card-title>
          <mat-card-content>
            <div class="row">
              <mat-form-field floatLabel="always" class="col-md-12">
                <!--<mat-label ddcLabel labelKey="bdd.campMenu.description"></mat-label>-->
                <textarea type="text" matInput [rows]="1" [formControl]="form.get('description')"></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="row">
    <div class="col-lg-auto col-12">
      <div class="row ddc-padding">
        <button mat-raised-button class="ddc-button-primary col-lg-auto col" (click)="changeDay('BACKWARD')"
                [disabled]="selectedFormIndex === 0">
          Jour précédent
        </button>

        <button mat-raised-button class="ddc-button-primary col-lg-auto col" (click)="changeDay('FORWARD')"
                [disabled]="selectedFormIndex === maximumIndexThatCanBeSelected">
          Jour suivant
        </button>
      </div>
    </div>

    <div class="col-lg col-12">
      <div class="row">
        <div class="col">
          <button mat-raised-button color="warn" class="col-lg-auto col ddc-button-left " (click)="cancelModifications()">
            {{data?.ignoreModificationsBtnText}}
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-auto col-12 ddc-right">
      <div class="row">
        <div class="col">
          <button mat-raised-button class="ddc-button-primary col-lg-auto col" (click)="onYesClick()">
            {{data?.closeBtnText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
