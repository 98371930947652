import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { AuthenticationService } from '../shared/service/authentication.service'
import { SnackBarService } from '../shared/service/snackbar.service'

export const AdministrationAuthGuard: CanActivateFn = (route, state) => {

  const authenticationService = inject(AuthenticationService)
  const snackBarService = inject(SnackBarService)

  if (authenticationService.currentUserValue && authenticationService.currentUserValue.administrerPays === true) {
    return true
  } else {
    snackBarService.showError('Vous n\'avez pas le droit d\'accèder à cette page.')
    return false
  }
}

export const AdministrationMailAuthGuard: CanActivateFn = (route, state) => {

  const authenticationService = inject(AuthenticationService)
  const snackBarService = inject(SnackBarService)

  if (authenticationService.currentUserValue && authenticationService.currentUserValue.isADMIN === true) {
    return true
  } else {
    snackBarService.showError('Vous n\'avez pas le droit d\'accèder à cette page.')
    return false
  }
}
