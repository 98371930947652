<div class="row">
  <div [ngClass]="(moduleOpts && moduleOpts.length > 0) ? 'col-lg-6' : 'col-lg-12'">
    <mat-card *ngIf="moduleMoss && moduleMoss.length > 0" class="ddc-card-with-title">
      <mat-card-title>
        <span ddcLabel labelKey="appli.modules.carte.moss"></span>
      </mat-card-title>
      <mat-card-content>
        <div ddcLabel labelKey="appli.modules.moss.description" class="ddc-modules-title"></div>
        <div *ngIf="_isFormSubmitted && modulesErrorFlag" class="ddc-camp-moss-modules-error-container">
          Vous devez répondre à toutes les questions ci-dessous
        </div>
        <div *ngFor="let moduleControl of moduleMossFormGroups;">
          <ng-container *ngIf="moduleControl.get('module').value.code != CampModuleEnum.MARIN">
              <mat-radio-group [formControl]="moduleControl.get('isChecked')"
                               (change)="setDdcModules(moduleControl.get('module').value, $event)">
                <label>{{moduleControl.get('module').value?.question}}</label>
                <div class="ddc-modules-response">
                  <ng-container *ngIf="moduleControl.get('module').value.code === CampModuleEnum.MARIN_1 || moduleControl.get('module').value.code === CampModuleEnum.MARIN_2 ; else noMarin">
                    <a class="ddc-modules-response-marin-link">{{moduleControl.get('module').value?.description}}</a>
                  </ng-container>
                  <ng-template #noMarin>
                    {{moduleControl.get('module').value?.description}}
                  </ng-template>
                  <mat-radio-button value="0">Non</mat-radio-button>
                  <mat-radio-button value="1">Oui</mat-radio-button>
                </div>
              </mat-radio-group>
            </ng-container>
        </div>
        <ng-container *ngIf="_typeDdc.code === TypeProjetAnneeEnum.CAMP_GROUPE && _displayProjetPeda">
          <mat-radio-group (change)="setPreviousProjetPeda($event.value)">
            <label>Voulez-vous reprendre les données du projet pédagogique de la saison précédente ?</label>
            <ng-container *ngIf="_defaultNoValueCopyProjetPeda; else yesAndNoRadioButton">
              <mat-radio-button disabled="disabled" [checked]="true" value="0">Non</mat-radio-button>
            </ng-container>
            <ng-template #yesAndNoRadioButton>
              <mat-radio-button  value="0">Non</mat-radio-button>
              <mat-radio-button value="1">Oui</mat-radio-button>
            </ng-template>
          </mat-radio-group>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-6" *ngIf="moduleOpts && moduleOpts.length > 0">
    <mat-card class="ddc-card-with-title">
      <mat-card-title>
        <span ddcLabel labelKey="appli.modules.carte.optionnels"></span>
      </mat-card-title>
      <mat-card-content>
        <div ddcLabel labelKey="appli.modules.optionnels.description" class="ddc-modules-title"></div>
        <div *ngFor="let moduleControl of moduleOptsFormGroups;">
          <mat-checkbox [formControl]="moduleControl.get('isChecked')"
                        (change)="setDdcModules(moduleControl.get('module').value, $event)">
            {{moduleControl.get('module').value?.question}}
          </mat-checkbox>
          <div class="sv_q_description" *ngIf="moduleControl.get('module').value?.description">
            {{moduleControl.get('module').value?.description}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
