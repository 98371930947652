import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CAMP_NOTIFICATION_DATE_FIELDS_DEFINITION, ICampNotification } from '../model/camp-notification.model';
import { convertDateFromServer } from '../util/moment.utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CampNotificationService {

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'camp-notifications';

  static convertCampNotificationDateFromServer(campNotification: ICampNotification): ICampNotification {
    if (campNotification) {
      convertDateFromServer(campNotification, CAMP_NOTIFICATION_DATE_FIELDS_DEFINITION);
    }
    return campNotification;
  }

  findActiveNotificationsByConnectedAdherent(): Observable<HttpResponse<ICampNotification[]>> {
    return this.http
      .get<ICampNotification[]>(`${this.resourceUrl}`, {observe: 'response'})
      .pipe(map(res => this.convertCampNotificationDateArrayFromServer(res)));
  }

  markAsRead(campNotification: ICampNotification): Observable<HttpResponse<ICampNotification>> {
    return this.http
      .put<ICampNotification>(`${this.resourceUrl}/mark-as-read/${campNotification.id}`, null, {observe: 'response'})
      .pipe(map(res => this.convertCampNotificationDateFromServer(res)));
  }

  clear(campNotification: ICampNotification): Observable<HttpResponse<ICampNotification>> {
    return this.http
      .put<ICampNotification>(`${this.resourceUrl}/clear/${campNotification.id}`, null, {observe: 'response'})
      .pipe(map(res => this.convertCampNotificationDateFromServer(res)));
  }

  markAllAsRead(campNotificationsIds: number[]): Observable<HttpResponse<ICampNotification[]>> {
    return this.http
      .put<ICampNotification[]>(`${this.resourceUrl}/mark-all-as-read`, {campNotificationsIds}, {observe: 'response'})
      .pipe(map(res => this.convertCampNotificationDateArrayFromServer(res)));
  }

  clearAll(campNotificationsIds: number[]): Observable<HttpResponse<ICampNotification[]>> {
    return this.http
      .put<ICampNotification[]>(`${this.resourceUrl}/clear-all`, {campNotificationsIds}, {observe: 'response'})
      .pipe(map(res => this.convertCampNotificationDateArrayFromServer(res)));
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour un notification)
   * @param response
   */
  protected convertCampNotificationDateFromServer(response: HttpResponse<ICampNotification>): HttpResponse<ICampNotification> {
    return new HttpResponse({
      ...response,
      body: CampNotificationService.convertCampNotificationDateFromServer(response.body)
    });
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour plusieurs notification)
   * @param res
   */
  protected convertCampNotificationDateArrayFromServer(res: HttpResponse<ICampNotification[]>): HttpResponse<ICampNotification[]> {
    if (res.body) {
      res.body.forEach((campNotification: ICampNotification) => {
        CampNotificationService.convertCampNotificationDateFromServer(campNotification);
      });
    }
    return res;
  }

}
