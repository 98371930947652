import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION,
  ICampHistoriqueModification, ICampHistoModuleDerniereModificationContainer
} from '../model/camp-historique-modification.model';
import { convertDateFromServer } from '../util/moment.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CampHistoriqueModificationService {

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'camp-historique-modifications';

  public static mapCampHistoModuleDerniereModificationHeader<T>(httpResponseObservable: Observable<HttpResponse<T>>): Observable<ICampHistoModuleDerniereModificationContainer<T>> {
    return httpResponseObservable.pipe(
      map(CampHistoriqueModificationService.httpResponseToCampHistoModuleDerniereModificationContainer)
    );
  }

  public static httpResponseToCampHistoModuleDerniereModificationContainer<T>(response: HttpResponse<T>) {
    return {
      data: response.body,
      campHistoModuleDerniereModification: CampHistoriqueModificationService.convertDateFromServer(
        JSON.parse(
          response.body['_ddcCampHistoModuleDerniereModification'] ? response.body['_ddcCampHistoModuleDerniereModification'] : 'null'
        )
      ),
      serverValue: response.body
    };
  }

  static convertDateFromServer(campHistoriqueModification: ICampHistoriqueModification): ICampHistoriqueModification {
    if (campHistoriqueModification) {
      convertDateFromServer(campHistoriqueModification, CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION)
    }
    return campHistoriqueModification;
  }
}
