<ng-container>
  <div id="ddc-log-out-container">
    <div class="ddc-log-out-header">
      <p>Consulter mon espace en tant que</p>
      <span class="material-icons iconExpend" (click)="close()">close</span>
    </div>
    <div>
     <mat-form-field id="mat-form-arrow" appearance="outline" style="width: 300px">
       <mat-label>profil sélectionné</mat-label>
       <mat-select

         [formControl]="ddcUserFunctionalityControl"
         [compareWith]="compareDdcUserFunctionalities"
         (selectionChange)="changeDdcUserFunctionality($event)"
       >
         <mat-option
           *ngFor="let ddcUserFunctionality of ddcUserFunctionalities"
           [value]="ddcUserFunctionality"
         >
           {{ddcUserFunctionality.libelleFonction}} - {{ddcUserFunctionality.libelleStructure}}

         </mat-option>
       </mat-select>
     </mat-form-field>
   </div>

    <div class="ddc-log-out-icon-div">
      <div class="ddc-log-out-icon" (click)="navigateGestionMail()">
        <button mat-raised-button class="ddc-button-log-out">
          <i class="material-icons my-0">mail</i>
          Gestion mail
          <!-- <span>Gestion mail</span> -->
        </button>
      </div>

      <div class="ddc-log-out-icon" (click)="doLogout()">
        <button mat-raised-button class="ddc-button-log-out">
          <i class="material-icons my-0">lock</i>
          <span>Déconnexion</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
