import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { TypeNotificationEnum } from '../constant/enum';
import {IProjetAnnee} from "./projet-annee.model";

export interface IProjetAnneeNotification {
  id?: number;
  projetAnnee?: IProjetAnnee;
  adherent?: IAdherent;
  typeNotification?: TypeNotificationEnum;
  contenu?: string;
  statut?: number;
  dateHeureNotification?: Moment;
  codeModule?: string;
  idProjetAnneeModule?: number;
}

export class IProjetAnneeNotification {
  constructor(
    public id?: number,
    public projetAnnee?: IProjetAnnee,
    public adherent?: IAdherent,
    public typeNotification?: TypeNotificationEnum,
    public contenu?: string,
    public statut?: number,
    public dateHeureNotification?: Moment,
    public codeModule?: string,
    public idProjetAnneeModule?: number
  ) {
  }
}

export const PROET_ANNEE_NOTIFICATION_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureNotification']);
