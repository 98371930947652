export enum TypeSoutienEnum {
  AP = 'AP',
  INTERNATIONAL = 'INTERNATIONAL',
  MARIN = 'MARIN',
  VAO = 'VAO',
  ACCOM_COMP = 'ACCOM_COMP',
  LOCAL = 'LOCAL'
};

export enum CampDiscussionSujetStatusEnum {
  OUVERTE = 1,
  CLOTUREE = 2
};

export enum TypeInfoComplementaireEnum {
  DISCUSSIONS = 'DISCUSSIONS',
  HISTORIQUE = 'HISTORIQUE',
  PJ = 'PJ',
  MODULES = 'MODULES'
}

export enum CampNumeroUtileCategorieEnum {
  STRUCTURE = 1,
  SECOURS = 2,
  PRINCIPALE = 3,
  INTERNATIONALE = 4
}

export enum SortOrderEnum {
  DESC = 'DESCENDING',
  ASC = 'ASCENDING'
}

export enum TypeModuleEnum {
  MOSS = 'MOSS',
  OPTIONNEL = 'OPTIONNEL'
}

export enum CampCodeModulesEnum {
  INFO_GENERALE = 'INFO_GENERALE',
  LIEUX = 'LIEUX',
  STAFF = 'STAFF',
  PARTICIPANT = 'PARTICIPANT',
  JOURNEE_TYPE = 'JOURNEE_TYPE',
  GRILLE_ACTIVITE = 'GRILLE_ACTIVITE',
  MENU = 'MENU',
  PROJET_PEDA = 'PROJET_PEDA',
  PROJET_ACCOMP = 'PROJET_ACCOMP',
  NUMERO_UTILE = 'NUMERO_UTILE',
  INTERNATIONAL = 'INTERNATIONAL',
  SUIVI_SGDF = 'SUIVI_SGDF',
  SUIVI_DDCS = 'SUIVI_DDCS',
  BUDGET = 'BUDGET',
  EXPERIMENT = 'EXPERIMENT',
  VIE_EQUIPE = 'VIE_EQUIPE',
  PARTENAIRE = 'PARTENAIRE',
  RENCONTRE = 'RENCONTRE',
  PROG_PERSO = 'PROG_PERSO',
  RETOUR = 'RETOUR',
  MARIN = 'MARIN',
  CHECKLIST = 'CHECKLIST',
  UNITE_ETRANGERE = 'UNITE_ETRANGERE',
  UNITEES_PARTICIPANTS = 'UNITEES_PARTICIPANTS',
  BESOIN_ACCOMP = 'BESOIN_ACCOMP',
  VAO = 'VAO',
  HISTORIQUE_COMPLET = 'HISTORIQUE_COMPLET',
  EMAIL = 'EMAIL',
  INFO_COMP = 'INFO_COMP'
}

export enum ProjetAnneeCodeModulesEnum {
  STAFF = 'STAFF',
  FICHE_INITIALE = 'FICHE_INITIALE',
  FC_TRIMESTRE_1 = 'FC_TRIMESTRE_1',
  FC_TRIMESTRE_2 = 'FC_TRIMESTRE_2',
  FC_TRIMESTRE_3 = 'FC_TRIMESTRE_3',
  FC_TRIMESTRE_4 = 'FC_TRIMESTRE_4',
  MARIN = 'MARIN',
  ACTIONS = 'ACTIONS',
  REVES = 'REVES',
  SUIVI_SGDF = 'SUIVI_SGDF',
  APAS = 'APAS'
}

export enum TrimestreEnum {
  TRIMESTRE_1 = "TRIMESTRE_1",
  TRIMESTRE_2 = "TRIMESTRE_2",
  TRIMESTRE_3 = "TRIMESTRE_3",
  TRIMESTRE_4 = "TRIMESTRE_4",
}

export enum TypeCampEnum {
  SIX_HUIT = '6-8',
  HUIT_ONZE = '8-11',
  ONZE_QUATORZE = '11-14',
  QUATORZE_DIXSEPT = '14-17',
  COMPAGNONS_T1 = 'COMPAGNONS-T1',
  COMPAGNONS_T2 = 'COMPAGNONS-T2',
  COMPAGNONS_T3 = 'COMPAGNONS-T3',
  JADE = 'JADE',
  AUDACE = 'AUDACE',
  CAMP_ACCOMPAGNE = 'CAMP-ACCOMPAGNE',
  AUTRE = 'AUTRE',
  FARFADET = 'FARFADET'
}

export enum TypeCampGroupEnum {
  SIX_HUIT = '6-8',
  HUIT_DIXSEPT = '8-17',
  COMPAGNON = 'COMPAGNON',
  JADE = 'JADE',
  AUDACE = 'AUDACE',
  FARFADET = 'FARFADET'
}

export enum TypeMossEnum {
  INTERNATIONAL = 'INTERNATIONAL',
  AUTONOMIE = 'AUTONOMIE',
  INTEGRATION_CA = 'INTEGRATION-CA',
  ACCUEIL_ETRANGER = 'ACCUEIL-ETRANGER',
  JUMELAGE = 'JUMELAGE',
  MARIN = 'MARIN',
  APAS = 'APAS',
  CHECKLIST = 'CHECKLIST',
  VAO = 'VAO',
  ACTIONS = 'ACTIONS',
  REVES = 'REVES'
}

export enum CampAdherentStaffRoleEnum {
  DIRECTEUR = 'D',
  RESPONSABLE_UNITE = 'RU',
  RESPONSABLE_FARFADET = 'RF',
  CHEF = 'C',
  DIRECTEUR_ADJOINT = 'DA',
  AUTRE = 'A'
}
export enum CampAdherentParticipantResponsibilitiesEnum {
  TRESORIER = 'TRESORIER',
  COMMUNICATION = 'COMMUNICATION',
  INTENDANT = 'INTENDANT',
  LOGISTIQUE = 'LOGISTIQUE',
  RELATION_PARTENAIRE = 'RELATION_PARTENAIRE'
}

export enum CampVieEquipeFilBlancEnum {
  PLAISIR_CROIRE = 'PLAISIR_CROIRE',
  JOIE_VIVRE = 'JOIE_VIVRE',
  BONHEUR_AIMER = 'BONHEUR_AIMER'
}

export enum MesCampsTypeEnum {
  DIRECT = 'direct',
  INDIRECT = 'indirect'
}

export enum TypeAvisEnum {
  RG = 'RG',
  AP = 'AP',
  MARIN = 'MARIN',
  INTERNATIONAL = 'INTERNATIONAL',
  VAO = 'VAO'
}

export enum TypeAdherentEnum {
  STAFF = 'staff',
  JEUNE = 'jeune'
}

export enum CampAdherentType {
  STAFF = 'STAFF',
  PARTICIPANT = 'PARTICIPANT',
}

export enum StatutCampEnum {
  ENCOURS = 1,
  ANNULE = 2,
  ARCHIVE = 3
}

export enum StatutAvisEnum {
  EMIS = 'emis',
  NON_EMIS = 'non-emis',
  PREALABLE = 'prealable',
  FAVORABLE = 'favorable',
  FAVORABLE_RESERVE = 'favorable-reserve',
  DEFAVORABLE = 'defavorable',
  REFUSE = 'refuse',
  DELIVRE = 'delivre',
  PROJ_NAV_MODIF = 'proj-nav-modif',
  COMPLETE = 'complete',
}

export enum StatutDepartCampEnum {
  VALIDE = 'valide',
  NON_VALIDE = 'non-valide',
  PROJ_MODIF = 'proj-modif',
  NON_EMIS =  'non-emis'
}

export enum CampModuleEnum {
  INTERNATIONAL_JADE = 'INTERNATIONAL-JADE',
  INTERNATIONAL_COMP = 'INTERNATIONAL-COMP',
  AUTONOMIE = 'AUTONOMIE',
  HALP = 'HALP',
  ITINERANCE = 'ITINERANCE',
  EMPREINTE = 'EMPREINTE',
  CHECK_LIST = 'CHECK-LIST',
  CHECK_LIST_6_8 = 'CHECK-LIST-6-8',
  CHECK_LIST_COMP = 'CHECK-LIST-COMP',
  CHECK_LIST_JADE = 'CHECK-LIST-JADE',
  CHECK_LIST_VDL = 'CHECK-LIST-VDL',
  APPEL_AGE_SUP = 'APPEL-AGE-SUP',
  CHECK_LIST_8_17 = 'CHECK-LIST-8-17',
  INTEGRATION_CA = 'INTEGRATION-CA',
  OUVERTURE = 'OUVERTURE',
  ACCUEIL_ETRANGER = 'ACCUEIL-ETRANGER',
  MAITRISE = 'MAITRISE',
  SPIRIT = 'SPIRIT',
  JUMELAGE = 'JUMELAGE',
  INTERNATIONAL_8_17 = 'INTERNATIONAL-8-17',
  MARIN = 'MARIN',
  APAS = 'APAS',
  SCOUTE = 'SCOUTE',
  SCOUTE_S_GUIDES = 'SCOUTE-S_GUIDES',
  SCOUTE_LOUVETEAUX = 'SCOUTE-LOUVETEAUX',
  SCOUTE_P_CARAVELLES = 'SCOUTE-P_CARAVELLES',
  SCOUTE_AUTRE = 'SCOUTE-AUTRE',
  SCOUTE_FARFADETS = 'SCOUTE-FARFADETS',
  BAFA_BAFD = 'BAFA/BAFD',
  CHECKLIST = 'CHECKLIST',
  VAO = 'VAO',
  MARIN_1 = 'MARIN-1',
  MARIN_2 = 'MARIN-2'
}

export enum StatutDeclarationEnum {
  NO_DECLARATION = 'ND',
  PARTIAL_DECLARATION = 'PD',
  FULL_DECLARATION = 'FD',
  UNCONFIRMED_DECLARATION = 'UD',
  INSUFFICIENT_DECLARATION = 'ID',
  VALIDATED_DECLARATION = 'VD',
  MODIFIED_DECLARARION = 'MD'
}

export enum CampFichierCategorieEnum {
  DOSSIER_CAMP = 'DOSSIER_CAMP',
  AUTORISATION_CAMPER = 'AUTORISATION_CAMPER'
}

export enum TypeProjetAnneeEnum {
  CAMP_GROUPE = 'CAMP-GROUPE',
  CAMP_TERRITOIRE = 'CAMP_TERRITOIRE',
}

export enum TypeDossierEnum {
  CAMP = 'CAMP',
  PROJET_ANNEE = 'PROJET_ANNEE'
}

export enum ProjetAnneePeriodeAnneeEnum {
  MERCREDI = 'MERCREDI',
  SAMEDI = 'SAMEDI',
  DIMANCHE = 'DIMANCHE',
  AUTRE = 'AUTRE'
}

export enum ProjetAnneePeriodeVacanceEnum {
  TOUSSAINT = 'TOUSSAINT',
  NOEL = 'NOEL',
  HIVER = 'HIVER',
  PRINTEMPS = 'PRINTEMPS',
  JUILLET = 'JUILLET',
  AOUT = 'AOUT'
}

export enum ProjetAnneeTypePeriodeEnum {
  ANNEE = 'ANNEE',
  VACANCE = 'VACANCE'
}

export enum ProjetAnneeStatutEnum {
  CREE = 1,
  A_TELEDECLARER = 2,
  TELEDECLARE = 3
}

export enum ProjetAnneeStatutProjetDeclarationEnum {
  EN_COURS = 1,
  ARCHIVE = 2
}

export enum ProjetAnneeModificationActionEnum {
  UPDATE = 'update',
  DECLARE = 'declare',
  REINITIALISATION = 'reinitialisation'
}

export enum ValidationValueEnum {
  DEFAULT = 0,
  INFO = 1,
  SUCCESS = 2,
  WARNING = 3,
  ERROR = 4,
}

export enum NotificationStatutEnum {
  UNREAD = 1,
  READ = 2,
  CLEARED = 3
}

export enum TypeNotificationEnum {
  MODIF_AVIS_AP = 'MODIF_AVIS_AP',
  MODIF_AVIS_RG = 'MODIF_AVIS_RG',
  MODIF_AVIS_MARIN = 'MODIF_AVIS_MARIN',
  MODIF_AVIS_INTER = 'MODIF_AVIS_INTER',
  AUTORISER_CAMPER = 'AUTORISER_CAMPER',
  NOUVEAU_BUDGET = 'NOUVEAU_BUDGET',
  AFFECTER_SOUTIEN = 'AFFECTER_SOUTIEN',
  MODIF_PAYS = 'MODIF_PAYS',
  NOUVELLE_DISCUSSION = 'NOUVELLE_DISCUSSION',
  NOUVEAU_MESSAGE = 'NOUVEAU_MESSAGE',
  CLOTURER_DISCUSSION = 'CLOTURER_DISCUSSION',
  MODIF_APRES_TELEDECLARER = 'MODIF_APRES_TELEDECLARER',
  MODIF_STATUS_TELEDECLARER = 'MODIF_STATUS_TELEDECLARER',
  DEFAULT = 'DEFAULT'
}

export enum AdherentMailTemplateEnum {
  AFFECTATION_SOUTIEN = 'AFFECTATION_SOUTIEN',
  CHANGEMENT_VISA = 'CHANGEMENT_VISA',
  CHANGEMENT_DOSSIER = 'CHANGEMENT_DOSSIER',
  MESSAGES = 'MESSAGES',
  CHANGEMENT_PAYS = 'CHANGEMENT_PAYS'
}

export enum NatureProjetMarinEnum {
  VOILE_AVEC_PARTENAIRE = 'VOILE_AVEC_PARTENAIRE',
  CANOE_KAYAK_AVEC_PARTENAIRE = 'CANOE_KAYAK_AVEC_PARTENAIRE',
  VOILE_AUTONOMIE = 'VOILE_AUTONOMIE',
  AUTRE_NAVIGATION = 'AUTRE_NAVIGATION'
}

export enum TypeNavigationMarinEnum {
  NAVIGATION_AUTONOMIE = 'NAVIGATION_AUTONOMIE',
  NAVIGATION_PARTENAIRE = 'NAVIGATION_PARTENAIRE'
}

export enum TypeContexteMarinEnum {
  CONTEXTE_HABITUEL = 'CONTEXTE_HABITUEL',
  ACTIVITE_PONCTUELLE = 'ACTIVITE_PONCTUELLE'
}

export enum DiplomeMarinEnum {
  MONITORAT_FEDERAL = 'MONITORAT_FEDERAL',
  BREVET_ETAT = 'BREVET_ETAT',
  BPJEPS = 'BPJEPS',
  AUTRE = 'AUTRE'
}

export enum TypeBateauMarinEnum {
  VOILE_LEGERE = 'VOILE_LEGERE',
  VOILIERS_NON_HABITABLES = 'VOILIERS_NON_HABITABLES',
  VOILIERS_HABITABLES = 'VOILIERS_HABITABLES',
  BATEAU_MOTEUR = 'BATEAU_MOTEUR',
  AUTRE = 'AUTRE'
}

export enum ProprietaireBateuMarinEnum {
  SGDF = 'SGDF',
  SOCIETE_LOCATION = 'SOCIETE_LOCATION',
  PRET_PAR_PROPRIETAIRE = 'PRET_PAR_PROPRIETAIRE',
  AUTRES = 'AUTRES'
}

export enum RoleNavigationMarinEnum {
  RESPONSABLE_ACTIVITE = 'RESPONSABLE_ACTIVITE',
  CHEF_BORD = 'CHEF_BORD',
  ENCADRANT_SECU = 'ENCADRANT_SECU'
}

export enum CampModuleMarinSubCategoriesEnum {
  PARTENAIRE = 'PARTENAIRE',
  ZONE_NAVIGATION = 'ZONE_NAVIGATION',
  BATEAUX = 'BATEAUX',
  PROJET_NAVIGATION = 'PROJET_NAVIGATION',
  DESCRIPTION_AUTRE_PROJET = 'DESCRIP_AUTRE_PROJ',
}

export enum ProjetAnneeModuleMarinSubCategoriesEnum {
  INFO_GENERALE = 'INFO_GENERALE',
  ENCADRANTS = 'ENCADRANTS',
  BATEAUX = 'BATEAUX',
  SITES_CONTEXTE = 'SITES_CONTEXTE',
  VISA_REGLEMENTATION = 'VISA_REGLEMENTATION',
  SITES_CONTEXTE_FICHE_ANALYSE = 'SITES_CONTEXTE_FICHE_ANALYSE'
}

export enum AdherentCiviliteEnum {
  MALE = 1,
  FEMALE = 2
}

export enum TransportOptionEnum {
  CAR = 'car',
  BUS = 'bus',
  MINI_BUS = 'mini-bus',
  TRAIN = 'train',
  AVION = 'avion',
  VEHICULE_ADAPTE = 'vehicule-adapte',
  COVOITURAGE = 'covoiturage',
  BATEAU = 'bateau',
  MARCHE = 'marche',
  VELO = 'velo',
  AUTRE = 'autre',
}

export enum AlertLevelEnum {
  ERROR = 'error',
  WARNING = 'warn'
}

export enum AccueilTabEnum {
  HOME_PAGE = 'home-page',
  MES_CAMPS = 'mes-camps',
  MES_CAMPS_ATTACHES = 'mes-camps-attaches',
  MES_PROJETS_ANNEE = 'mes-projets-annee',
  MES_PROJETS_ANNEE_ATTACHES = 'mes-projets-annee-attaches',
  RECHERCHE_PROJETS_CAMP = 'recherche-projets-camp',
  RECHERCHE_PROJETS_INTERNATIONAUX = 'recherche_projets_internationaux',
  ADMINSTRATION_PAYS = 'adminstration_pays'
}

export enum TableProcessedEnum {
  MES_CAMPS = 'MES_CAMP',
  RECHERCHE_CAMPS = 'RECHERCHE_CAMPS',
  PROJET_ANNEE = 'DECLARATION_ANNEE',
  RECHERCHE_PROJET_ANNEE = 'RECHERCHE_DECLARATION_ANNEE'
}

export enum TooltipIconSymbolEnum {
  AVIS_RG = "thumb_up",
  AVIS_TERRITOIRE = "question_answer",
  AVIS_INTERNATIONAL = "public",
  AVIS_MARIN = "Avis marin",
  AVIS_VAO = "accessibility_new",
  AUTORISATION_CAMPER = "check_circle",
  TELEDECLARATION = "publish",
  SOUTIEN_TERRITORIAL = "Soutien territorial",
  SOUTIEN_INTERNATIONAL =" Soutien international",
  FICHE_INITIALE = 'info',
  FC_TRIMESTRE_1 = 'looks_one',
  FC_TRIMESTRE_2 = 'looks_two',
  FC_TRIMESTRE_3 = 'looks_3',
  FC_TRIMESTRE_4 = 'looks_4',
  DISCUSSIONS = 'speaker_notes'

}

export enum TooltipIconTextEnum {
  AVIS_RG = "Avis RG",
  AVIS_TERRITOIRE = "Avis territoire",
  AVIS_INTERNATIONAL = " Avis international",
  AVIS_MARIN = "Avis marin",
  AVIS_VAO = "Visa VAO",
  AUTORISATION_CAMPER = "Autorisation de camper",
  TELEDECLARATION = " Télédéclaration",
  SOUTIEN_TERRITORIAL = "Soutien territorial",
  SOUTIEN_INTERNATIONAL =" Soutien international",
  FICHE_INITIALE = 'Fiche initiale',
  FC_TRIMESTRE_1 = 'Fiche complémentaire - trimestre 1',
  FC_TRIMESTRE_2 = 'Fiche complémentaire - trimestre 2',
  FC_TRIMESTRE_3 = 'Fiche complémentaire - trimestre 3',
  FC_TRIMESTRE_4 = 'Fiche complémentaire - trimestre 4',
  DISCUSSIONS = 'Nombre de discussions ouvertes'
}

export enum TypeProjetInternationalEnum {
  JUM_INT = 'JUM-INT',
  JAM_NAT = 'JAM-NAT',
  JAM_INT = 'JAM-INT',
  PROJ_AVEC_SCOUTS = 'PROJ-AVEC-SCOUTS',
  PROJ_ACCORD_SCOUTS = 'PROJ-ACCORD-SCOUTS',
  PROJ_SANS_SCOUTS = 'PROJ-SANS-SCOUTS',
  PROJ_EXP_ERASCOUT = 'PROJ-EXP-ERASCOUT'
}

export enum TableDonneeDeclarerDDCSEnum {
  INTERVENANT = 'INTERVENANT' ,
  LIEUX = 'LIEUX',
  INTERVENANT_TO_DO = 'INTERVENANT_TO_DO'
}

export enum TrancheAgeEnum {
  six_treize = 'six-treize',
  quatorze_dix_sept = 'quatorze-dix-sept'
}

export enum CampModificationActionEnum {
  DECLARE = 'declare',
  REINITIALISATION = 'reinitialisation'
}

export enum ValidationLevelEnum {
 REGLE_JS = 'reglesJS',
 AVIS_MARIN = 'avisMarin',
 TELEDECLARATION = 'teledeclaration',
 FICHE_INITIALE = 'fiche initiale',
 FC_1 = 'fiche complémentaire 1',
 FC_2 = 'fiche complémentaire 2',
 FC_3 = 'fiche complémentaire 3',
 FC_4 = 'fiche complémentaire 4'
}


