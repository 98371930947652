<div class="ddc-cich-title">
  <span ddcLabel labelKey="{{_item.labelKey}}"></span>
</div>
<div class="ddc-cich-area">
  <div *ngFor="let changedItem of _item.changes">
    <ng-container *ngIf="changedItem.hdType === 'obj'">
      <ddc-camp-info-complementaire-historique-hdtype-obj [item]="changedItem"></ddc-camp-info-complementaire-historique-hdtype-obj>
    </ng-container>
    <ng-container *ngIf="changedItem.hdType === 'list'">
      <ddc-camp-info-complementaire-historique-hdtype-list [item]="changedItem"></ddc-camp-info-complementaire-historique-hdtype-list>
    </ng-container>
    <ng-container *ngIf="!changedItem.hdType">
      <ddc-camp-info-complementaire-historique-hdtype-scalar [item]="changedItem"></ddc-camp-info-complementaire-historique-hdtype-scalar>
    </ng-container>
  </div>
</div>
