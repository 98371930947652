import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../service/loading.service';

/**
 * Loading interceptor inspiré par https://stackoverflow.com/a/51813213/535203 et https://medium.com/@zeljkoradic/loader-bar-on-every-http-request-in-angular-6-60d8572a21a9
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.loadingService.increaseRequests();
    return next.handle(request).pipe(
      finalize(() => this.loadingService.decreaseRequests())
    );
  }
}
