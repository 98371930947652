import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { AuthenticationService } from '../shared/service/authentication.service'
import { IDdcUser } from '../shared/model/ddc-user.model'
import { EventManager } from '../shared/util/event-manager'
import { ICampNotification } from '../shared/model/camp-notification.model'
import { NotificationStatutEnum } from '../shared/constant/enum'
import { ICampDiscussionSujet } from '../shared/model/camp-discussion-sujet.model'
import { environment } from '../../environments/environment'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { IMenuTabInterface } from './menu-tab/menu-tab.component'
import { IProjetAnneeNotification } from '../shared/model/projet-annee-notification.model'

@Component({
  selector: 'ddc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('ddcLogOutIsDisplayState', [
      state('close', style({
        visibility: 'hidden'
      })),
      state('open', style({
        visibility: 'visible'
      })),
      transition('close => open', animate('600ms ease-out')),
      transition('open => close', animate('100ms ease-in'))
    ])
  ]
})
export class MenuComponent {

  private static readonly campMenuLogoDirectory: string = '../assets/img/';

  @Input() set focusedTheme(focusedTheme: string) {
    this._focusedTheme = focusedTheme;
    this.initCampMenuLogoUrl(focusedTheme);
  }

  @Input() set logOutPopUpState(logOutPopUpState) {
    // check if the the Log Out pop up is open.
    if(logOutPopUpState === false){
      this.state = 'close'
    }
  }

  @Output() logOutCloseEvent = new EventEmitter();

  @HostListener('click', ['$event']) onClick($event){
    if($event.target.className != 'mat-mdc-select-min-line ng-tns-c1771602899-2 ng-star-inserted'
      && $event.target.className != 'ddc-log-out-icon'
      && $event.target.className != 'ddc-log-out-header'
      && $event.target.className != ''
      && $event.target.className === typeof Object) {
      this.logOutCloseEvent.emit($event);
    }
  }

  menuTabs: IMenuTabInterface[] = [];

  _focusedTheme = '';
  choosedCampMenuLogoName = 'ddc-logo-blanc.png';
  campMenuLogoUrl: string = MenuComponent.campMenuLogoDirectory + this.choosedCampMenuLogoName;
  currentDdcUser: IDdcUser;
  environment = environment;

  unReadCampNotificationsCount = 0;
  unReadProjetAnneeNotificationsCount=0;
  mesActionsCount = 0;

  state = 'close';
  whiteScoutLogoIsDisplay = false;

  constructor(
    private authenticationService: AuthenticationService,
  ) {
    this.currentDdcUser = this.authenticationService.currentUserValue;
    EventManager.campNotificationsUpdated.subscribe((campNotifications: ICampNotification[]) => {
      const unReadCampNotifications = campNotifications.filter((campNotification: ICampNotification) => campNotification.statut === NotificationStatutEnum.UNREAD);
      this.unReadCampNotificationsCount = unReadCampNotifications ? unReadCampNotifications.length : 0;
    });
    EventManager.projetAnneeNotificationsUpdated.subscribe((projetAnneeNotification: IProjetAnneeNotification[]) => {
      const unReadProjetAnneeNotifications = projetAnneeNotification.filter((projetAnneeNotification: IProjetAnneeNotification) => projetAnneeNotification.statut === NotificationStatutEnum.UNREAD);
      this.unReadProjetAnneeNotificationsCount = unReadProjetAnneeNotifications ? unReadProjetAnneeNotifications.length : 0;
    });

    EventManager.mesActionsUpdated.subscribe((mesActions: ICampDiscussionSujet[]) => {
      this.mesActionsCount = mesActions ? mesActions.length : 0;
    });

    // Initiate menu tabs
    this.initMenuTabs();

    this.closeLogOut();
  }

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    this.whiteScoutLogoIsDisplay = window.pageYOffset > 100;
  }

  initCampMenuLogoUrl(focusedTheme: string) {
    if (focusedTheme) {
      this.campMenuLogoUrl = MenuComponent.campMenuLogoDirectory + focusedTheme + '-logo.png';
    } else {
      this.campMenuLogoUrl = MenuComponent.campMenuLogoDirectory + 'ddc-logo-blanc.png';
    }
  }

  openDdcLogOut() {
    this.state === 'close' ? this.state = 'open' : this.state = 'close';
  }

  closeLogOut() {
    this.state === 'open' ? this.state = 'close' : this.state = 'open';
  }

  initMenuTabs() {
    if(this.currentDdcUser) {
      this.menuTabs = [
        {
          titleLabelKey: 'appli.header.navLink.camp',
          subTabs: [
            {
              labelKey:'appli.header.navLink.camp.mesCamps',
              routerLink: '/camp/mes-camps',
              displayed: true
            },
            {
              labelKey:'appli.header.navLink.camp.rechercherCamp',
              routerLink: '/camp/recherche-avancee',
              displayed: true
            },
            {
              labelKey:'appli.header.navLink.camp.creerCamp',
              routerLink: '/camp/nouveau',
              displayed: true
            },
          ],
          isDisplayed: true
        },
        {
          titleLabelKey: 'appli.header.navLink.projetAnnee',
          subTabs: [
            {
              labelKey: 'appli.header.navLink.projetAnnee.mesProjetsAnnee',
              routerLink: '/projet-annee/mes-projets-annee',
              disabled: true,
              displayed: true
            },
            {
              labelKey: 'appli.header.navLink.projetAnnee.rechercherProjet',
              routerLink: '/projet-annee/recherche-avancee',
              disabled: true,
              displayed: true
            },
            {
              labelKey: 'appli.header.navLink.projetAnnee.creerProjet',
              routerLink: '/projet-annee/nouveau',
              disabled: true,
              displayed: true
            },
          ],
          isDisplayed: false
        },
        {
          titleLabelKey: 'appli.header.navLink.declarationAnnee',
          subTabs: [
            {
              labelKey: 'appli.header.navLink.declarationAnnee.declarationAnnee',
              routerLink: '/projet-annee/mes-projets-annee',
              displayed: this.currentDdcUser.accesProjetAnnee
            },
            {
              labelKey: 'appli.header.navLink.declarationAnnee.rechercherDeclaration',
              routerLink: '/projet-annee/recherche-avancee',
              displayed: this.currentDdcUser.accesProjetAnnee

            },
            {
              labelKey: 'appli.header.navLink.declarationAnnee.crerProjetDeclaration',
              routerLink: '/projet-annee/nouveau',
              displayed: this.currentDdcUser.creerProjetAnnee
            }
          ],
          isDisplayed: this.currentDdcUser && this.currentDdcUser.accesProjetAnnee
        },
        {
          titleLabelKey: 'appli.accueil.carte.administration',
          subTabs: [
            {
              labelKey: 'appli.accueil.carte.administrationPays',
              routerLink: '/administration/pays',
              displayed: this.currentDdcUser.administrerPays
            },
            {
              labelKey: 'appli.accueil.carte.administrationEmail',
              routerLink: '/administration/email',
              displayed: this.currentDdcUser.isADMIN
            },
          ],
          isDisplayed: this.currentDdcUser.administrerPays || this.currentDdcUser.isADMIN
        }
      ]
    }
  }
}
