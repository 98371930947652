import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITypeCamp } from '../../shared/model/type-camp.model';
import { ICampModule } from '../../shared/model/camp-module.model';
import { CampModuleEnum, TypeModuleEnum, TypeProjetAnneeEnum } from '../../shared/constant/enum';
import { FormBuilder } from '@angular/forms';
import { IModule } from '../../shared/model/module.model';
import { ITypeProjetAnnee } from '../../shared/model/type-projet-annee.model';
import { IProjetAnneeModule } from '../../shared/model/projet-annee-module.model';

@Component({
  selector: 'ddc-modules',
  templateUrl: './ddc-modules.component.html',
  styleUrls: ['./ddc-modules.component.scss']
})
export class DdcModulesComponent implements OnInit {

  @Input() set typeDdc(typeDdc: ITypeCamp|ITypeProjetAnnee) {
    this._typeDdc = typeDdc;
    if(this.initialized) {
      this.initModules();
    }
  }

  @Input() set ddcModules(ddcModules: ICampModule[]|IProjetAnneeModule[]) {
    this._ddcModules = ddcModules;
  }

  @Input() set defaultNoValueCopyProjetPeda(defaultNoValueCopyProjetPeda: boolean) {
    this._defaultNoValueCopyProjetPeda = defaultNoValueCopyProjetPeda;
  }

  @Output() updateDdcModules = new EventEmitter();

  @Output() copyProjetPeda = new EventEmitter();

  @Input() set isFormSubmitted(isFormSubmitted: boolean) {
    this._isFormSubmitted = isFormSubmitted;
    if(this._isFormSubmitted) {
      this.checkIfAllRequiredModulesChecked();
    }
  }

  @Input() set isCampEnfant(isCampEnfant: boolean){
    this._isCampEnfant = isCampEnfant;
  }

  @Input() set displayProjetPeda(displayProjetPeda: boolean){
    this._displayProjetPeda = displayProjetPeda;
  }

  _ddcModules: ICampModule[]|IProjetAnneeModule[] = [];
  moduleMoss;
  moduleOpts;
  codeModules: object = {};
  _typeDdc: ITypeCamp|ITypeProjetAnnee;
  initialized: boolean = false;
  _isFormSubmitted: boolean = false;
  _isCampEnfant: boolean;
  _displayProjetPeda: boolean = true;
  _defaultNoValueCopyProjetPeda: boolean = false;

  moduleMossFormGroups = this.fb.group({});
  moduleOptsFormGroups = this.fb.group({});
  moduleMossCodes: string[] = [];
  modulesErrorFlag: boolean = false;
  CampModuleEnum = CampModuleEnum;
  TypeProjetAnneeEnum = TypeProjetAnneeEnum;

  // FIXME: This is done temporary, it should be changed after
  modulesToHide = [
    "INTERNATIONAL-JADE",
    "AUTONOMIE",
    "HALP",
    "ITINERANCE",
    "EMPREINTE",
    "CHECK-LIST-6-8",
    "CHECK-LIST-COMP",
    "CHECK-LIST-JADE",
    "CHECK-LIST-VDL",
    "REVES",
    "ACTIONS"
  ];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initModules();
    this.initialized = true;
  }

  checkIfAllRequiredModulesChecked() {
    this.modulesErrorFlag = false;

    const selectedModulesCodes = Object.keys(this.codeModules);
    this.moduleMossCodes.forEach(requiredModule => {
      const in_array = selectedModulesCodes.includes(requiredModule);
      if(!in_array) {
        this.modulesErrorFlag = true;
      }
    });
    this.updateDdcModules.emit({codeModules: this.codeModules, modulesErrorFlag: this.modulesErrorFlag});
  }

  initModules() {
    this.codeModules = {};
    if(this._isCampEnfant === true){
      this.moduleMoss = this._typeDdc.modules.filter((module: any) => (module.module.typeModule.code === TypeModuleEnum.MOSS && module.displayForCampEnfant));
      this.moduleOpts = this._typeDdc.modules.filter((module: any) => (module.module.typeModule.code === TypeModuleEnum.OPTIONNEL && module.displayForCampEnfant));
    } else {
      this.moduleMoss = this._typeDdc.modules.filter((module: any) => (module.module.typeModule.code === TypeModuleEnum.MOSS && !this.modulesToHide.includes(module.module.code)));
      this.moduleOpts = this._typeDdc.modules.filter((module: any) => (module.module.typeModule.code === TypeModuleEnum.OPTIONNEL && !this.modulesToHide.includes(module.module.code)));
    }

    this.moduleMossFormGroups = this.moduleMoss.map(module => {
        const indexActif = this._ddcModules.findIndex(ddcModule => ddcModule.module.code === module.module.code && ddcModule.actif === true);
        const indexInActif = this._ddcModules.findIndex(ddcModule => ddcModule.module.code === module.module.code && ddcModule.actif === false);
        let isChecked = '';
        if (indexActif !== -1) {
          isChecked = '1';
          this.codeModules[module.module.code] = '1';
        } else if (indexInActif !== -1) {
          this.codeModules[module.module.code] = '0';
          isChecked = '0';
        }

        return this.fb.group({
          isChecked: isChecked,
          module: module.module
        });
    });

    this.moduleOptsFormGroups = this.moduleOpts.map(module => {
      const index = this._ddcModules.findIndex(ddcModule => ddcModule.module.code === module.module.code && ddcModule.actif === true);
      let isChecked = false;
      if (index !== -1) {
        this.codeModules[module.module.code] = '1';
        isChecked = true;
      }
      return this.fb.group({
        isChecked: isChecked,
        module: module.module
      });
    });

    this.moduleMossCodes = this.moduleMoss.map(module => {
      return module.module.code;
    });

    //Check if all the required questions (modules) are answered on initialization
    this.checkIfAllRequiredModulesChecked();
  }

  setDdcModules(module: IModule, event) {

    if (module.code == CampModuleEnum.MARIN_1 || module.code == CampModuleEnum.MARIN_2) {
      if(event.value === '1') {
        if(module.code == CampModuleEnum.MARIN_1){
          this.codeModules[CampModuleEnum.MARIN] = '1';
          this.codeModules[CampModuleEnum.MARIN_1] = '1';
        } else {
          this.codeModules[CampModuleEnum.MARIN] = '1';
          this.codeModules[CampModuleEnum.MARIN_2] = '1';
        }
      } else {
        if(module.code == CampModuleEnum.MARIN_1){
          this.codeModules[CampModuleEnum.MARIN_1] = '0';
        } else {
          this.codeModules[CampModuleEnum.MARIN_2] = '0';
        }
        if(this.codeModules[CampModuleEnum.MARIN_1] == '0' && this.codeModules[CampModuleEnum.MARIN_2] == '0') {
          this.codeModules[CampModuleEnum.MARIN] = '0';
        }
      }
    } else if (event.checked === true || event.value === '1') {
      this.codeModules[module.code] = '1';
    } else {
      this.codeModules[module.code] = '0';
    }

    //Check if all the required questions (modules) are answered while the process of answering
    this.checkIfAllRequiredModulesChecked();
  }

  setPreviousProjetPeda(isUsePreviousProjetPeda){
    this.copyProjetPeda.emit(isUsePreviousProjetPeda);
  }
}
