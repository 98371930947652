import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from './auth/auth.guard';
import {GestionNotificationEmailComponent} from "./shared/gestion-notification-email/gestion-notification-email.component";


const routes: Routes = [
  { path: 'camp', loadChildren: () => import('./camp/camp.module').then(m => m.CampModule), canActivate: [AuthGuard] },
  { path: 'accueil', loadChildren: () => import('./accueil/accueil.module').then(m => m.AccueilModule), canActivate: [AuthGuard] },
  { path: 'projet-annee', loadChildren: () => import('./projet-annee/projet-annee.module').then(m => m.ProjetAnneeModule), canActivate: [AuthGuard]},
  { path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule), canActivate: [AuthGuard]},
  { path: '', redirectTo: '/accueil', pathMatch: 'full' },
  //{ path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'gestion-email', pathMatch: 'full', component: GestionNotificationEmailComponent},
  { path: '**', redirectTo: '/accueil' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
