import { Component, Input} from '@angular/core';
import { LoadingService } from "../shared/service/loading.service";

@Component({
  selector: 'ddc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() set focusedTheme(fucusedTheme: string) {
    this._focusedTheme = fucusedTheme;
  }

  @Input() set appVersion(appVersion: string) {
    this._appVersion = appVersion;
  }

  _focusedTheme: string = '';
  _appVersion: string = '';

  isVisible:boolean = false;

  constructor(public loadingService: LoadingService) {
    loadingService.isLoadingAsObservable().subscribe(value => {
      this.isVisible = value;
    })
  }
}
