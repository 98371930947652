import { Injectable } from '@angular/core';
import { SnackBarService } from './snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ExternalRedirectService } from './external-redirect.service';


@Injectable({
  providedIn: 'root'
})
export class GeneralErrorHandlerService {

  constructor(
    private snackBarService: SnackBarService,
    private externalRedirectService: ExternalRedirectService
  ) {}

  public resourceUrl = environment.apiServerUrl;

  customError = ['libelle-camp-master.csv', 'favicon.ico', 'bootstrap.js', 'zocial.css'];

  handleNotHandledError(err: any) {
    if (err instanceof HttpErrorResponse && !this.customError.find(val => val === err.url.split('/').pop())) {
      if (err && err.status === 401) {
        if (err.error.message === 'Expired JWT Token') {
          this.snackBarService.showError('Votre session a expiré, veuillez vous reconnecter.');
        }else {
          this.snackBarService.showError(err.error.message);
        }
        this.externalRedirectService.triggerLogin();
      }else if (err.error && err.error.details) {
        this.snackBarService.showError(err.error.details);
      } else if (err.error && err.error.message) {
        this.snackBarService.showError(err.error.message);
      } else {
        let details: string;
        try {
          details = '(détails techniques : ' + JSON.stringify(err) + ')';
        } catch (e) {
          details = '(détails disponibles dans la console de debug)';
          console.error(err);
        }
        this.snackBarService.showError(`Un problème est apparu${details}`);
      }
    }
  }
}
