import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { TypeAvisEnum } from '../constant/enum';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface ICampAvis {
  id?: number;
  typeAvis?: TypeAvisEnum;
  dateHeureDernierAvis?: Moment;
  statut?: string;
  commentaire?: string;
  adherentDernierAvis?: IAdherent;
}

export class CampAvis implements ICampAvis {
  constructor(
    public id?: number,
    public typeAvis?: TypeAvisEnum,
    public dateHeureDernierAvis?: Moment,
    public statut?: string,
    public commentaire?: string,
    public adherentDernierAvis?: IAdherent
  ) { }
}

export const CAMP_AVI_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureDernierAvis']);
