import {ICamp} from './camp.model';
import {CampModificationActionEnum} from "../constant/enum";

export interface ICampModificationStaticModuleDTO {
  moduleCode?: string;
  camp?: ICamp;
  modificationJson?: string;
  action?: CampModificationActionEnum;
}

export class CampModificationStaticModuleDTO implements ICampModificationStaticModuleDTO {
  constructor(
    public moduleCode?: string,
    public camp?: ICamp,
    public modificationJson?: string,
    public action?: CampModificationActionEnum

  ) { }

}
