import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { convertDateFromServer } from '../util/moment.utils';
import { map } from 'rxjs/operators';
import {
  IProjetAnneeNotification, PROET_ANNEE_NOTIFICATION_DATE_FIELDS_DEFINITION
} from "../model/projet-annee-notification.model";

@Injectable({
  providedIn: 'root'
})
export class ProjetAnneeNotificationService {

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'projet-annee-notifications';

  static convertProjetAnneeNotificationDateFromServer(projetAnneeNotification: IProjetAnneeNotification): IProjetAnneeNotification {
    if (projetAnneeNotification) {
      convertDateFromServer(projetAnneeNotification, PROET_ANNEE_NOTIFICATION_DATE_FIELDS_DEFINITION);
    }
    return projetAnneeNotification;
  }

  findActiveNotificationsByConnectedAdherent(): Observable<HttpResponse<IProjetAnneeNotification[]>> {
    return this.http
      .get<IProjetAnneeNotification[]>(`${this.resourceUrl}`, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeNotificationDateArrayFromServer(res)));
  }

  markAsRead(projetAnneeNotification: IProjetAnneeNotification): Observable<HttpResponse<IProjetAnneeNotification>> {
    return this.http
      .put<IProjetAnneeNotification>(`${this.resourceUrl}/mark-as-read/${projetAnneeNotification.id}`, null, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeNotificationDateFromServer(res)));
  }

  clear(projetAnneeNotification: IProjetAnneeNotification): Observable<HttpResponse<IProjetAnneeNotification>> {
    return this.http
      .put<IProjetAnneeNotification>(`${this.resourceUrl}/clear/${projetAnneeNotification.id}`, null, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeNotificationDateFromServer(res)));
  }

  markAllAsRead(projetAnneeNotificationsIds: number[]): Observable<HttpResponse<IProjetAnneeNotification[]>> {
    return this.http
      .put<IProjetAnneeNotification[]>(`${this.resourceUrl}/projet-annee-mark-all-as-read`, {projetAnneeNotificationsIds}, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeNotificationDateArrayFromServer(res)));
  }

  clearAll(projetAnneeNotificationsIds: number[]): Observable<HttpResponse<IProjetAnneeNotification[]>> {
    return this.http
      .put<IProjetAnneeNotification[]>(`${this.resourceUrl}/clear-all`, {projetAnneeNotificationsIds}, {observe: 'response'})
      .pipe(map(res => this.convertProjetAnneeNotificationDateArrayFromServer(res)));
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour un notification)
   * @param response
   */
  protected convertProjetAnneeNotificationDateFromServer(response: HttpResponse<IProjetAnneeNotification>): HttpResponse<IProjetAnneeNotification> {
    return new HttpResponse({
      ...response,
      body: ProjetAnneeNotificationService.convertProjetAnneeNotificationDateFromServer(response.body)
    });
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour plusieurs notification)
   * @param res
   */
  protected convertProjetAnneeNotificationDateArrayFromServer(res: HttpResponse<IProjetAnneeNotification[]>): HttpResponse<IProjetAnneeNotification[]> {
    if (res.body) {
      res.body.forEach((projetAnneeNotification: IProjetAnneeNotification) => {
        ProjetAnneeNotificationService.convertProjetAnneeNotificationDateFromServer(projetAnneeNotification);
      });
    }
    return res;
  }

}
