<div class="container-fluid footer-bottom-administation-page">
  <mat-card class="ddc-card-with-title">
    <mat-card-title>
      <span ddcLabel labelKey="appli.accueil.administrationPays.carte.administrationPays"></span>
    </mat-card-title>
    <mat-card-content>
      <div ddcLabel labelKey="appli.accueil.administrationPays.administrationPays.description"
           class="ddc-appli-description"></div>
      <div class="row">
        <mat-form-field floatLabel="always" class="col-sm-6 col-md-3">
          <mat-label ddcLabel labelKey="appli.accueil.administrationPays.pays"></mat-label>
          <mat-select (valueChange)="setSelectedPays($event)" [value]="selectedPays" [compareWith]="comparePaysValues"
                      #matSelect>
            <mat-select-trigger>{{selectedPays?.libelle}}</mat-select-trigger>
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let pays of tamRefPays" [value]="pays">
              {{pays.libelle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="selectedPays">

        <div class="row ddc-block-margin-bottom">
          <div class="col-12">
            <mat-card class="ddc-subcard-with-title">
              <mat-card-title>
                <span ddcLabel labelKey="appli.accueil.administrationPays.pays.carte.detailsPays"></span>
              </mat-card-title>
              <mat-card-content>
                <ng-container *ngIf="selectedPays.dateHeureModification && selectedPays.adherentModification">
                  <div class="row ddc-block-margin-bottom">
                    <div class="col-12">
                      <i>La dernière modification a été effectuée
                      par <b>{{selectedPays.adherentModification.prenom}} {{selectedPays.adherentModification.nom}}</b>
                        le <b>{{convertToHumanDate(selectedPays.dateHeureModification, true, true)}}</b></i>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-md-3">
                    <div class="ddc-read-only-data-container">
                      <span ddcLabel labelKey="appli.accueil.administrationPays.pays.libelle"></span>&nbsp;
                      <span class="ddc-value">{{selectedPays.libelle}}</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="ddc-read-only-data-container">
                      <span ddcLabel labelKey="appli.accueil.administrationPays.pays.idTamPays"></span>&nbsp;
                      <span class="ddc-value">{{selectedPays.idTamPays}}</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="ddc-read-only-data-container">
                      <span ddcLabel labelKey="appli.accueil.administrationPays.pays.codeContinent"></span>&nbsp;
                      <span class="ddc-value">{{selectedPays.codeContinent}}</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="ddc-read-only-data-container">
                      <span ddcLabel labelKey="appli.accueil.administrationPays.pays.codeContinentOmms"></span>&nbsp;
                      <span class="ddc-value">{{selectedPays.continentOmms && selectedPays.continentOmms.code}}</span>
                    </div>
                  </div>
                </div>
                <hr/>

                <div class="row">
                  <mat-form-field floatLabel="always" class="col-md-5">
                    <mat-label ddcLabel labelKey="appli.accueil.administrationPays.pays.emailContact"></mat-label>
                    <input type="email" matInput [formControl]="selectedPaysFormGroup.get('emailContact')" required/>
                    <mat-error ddcMatError [control]="selectedPaysFormGroup.get('emailContact')"></mat-error>
                  </mat-form-field>

                  <div class="offset-1 col-md-3">
                    <mat-slide-toggle [formControl]="selectedPaysFormGroup.get('paysOuvert')">
                      <span ddcLabel labelKey="appli.accueil.administrationPays.pays.paysOuvert"></span>
                    </mat-slide-toggle>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button mat-raised-button class="ddc-button-primary ddc-button-right"
                    [disabled]="this.selectedPaysFormGroup.invalid"
                    (click)="save()"
            >
              Enregistrer
            </button>
          </div>
        </div>

      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
