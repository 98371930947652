<div class="ddc-cich-title">
  <span ddcLabel labelKey="{{_item.labelKey}}"></span>
</div>
<div class="ddc-cich-area">
  <div *ngFor="let changedItem of _item.changes">
    <ng-container *ngIf="changedItem.hdActionType === 'removed'">
      <div class="ddc-cich-list-item ddc-cich-oldVal"><i class="material-icons">remove_circle_outline</i> <span>{{changedItem.itemLabel}}</span></div>
    </ng-container>
    <div *ngIf="changedItem.hdActionType === 'added'">
      <div class="ddc-cich-list-item ddc-cich-newVal"><i class="material-icons">add_circle_outline</i> <span>{{changedItem.itemLabel}}</span></div>
      <ng-container *ngFor="let changedItemDetail of changedItem.newVal | keyvalue">
        <ddc-camp-info-complementaire-historique-hdtype-scalar class="ddc-cich-area"
          [item]="{labelKey: changedItemDetail.key, newVal: changedItemDetail.value}"></ddc-camp-info-complementaire-historique-hdtype-scalar>
      </ng-container>
    </div>
    <div *ngIf="changedItem.hdActionType === 'changed'">
      <div class="ddc-cich-list-item"><i class="material-icons">play_circle_outline</i> <span>{{changedItem.itemLabel}}</span></div>
      <ng-container *ngFor="let changedItemDetail of changedItem.changes">
        <ddc-camp-info-complementaire-historique-hdtype-scalar class="ddc-cich-area" [item]="changedItemDetail"></ddc-camp-info-complementaire-historique-hdtype-scalar>
      </ng-container>
    </div>
  </div>
</div>
