import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ITamRefCommune } from '../model/tam-ref-commune.model';
import { ITamRefPays, TAM_REF_PAYS_DATE_FIELDS_DEFINITION } from '../model/tam-ref-pays.models';
import { ITamRefExercice, TamRefExercice } from '../model/tam-ref-exercice.model';
import { ICamp } from '../model/camp.model';
import { convertDateFromServer } from '../util/moment.utils';
import { map } from 'rxjs/operators';
import { IContinentOmms } from '../model/continent-omms.model';

@Injectable({providedIn: 'root'})
export class TamRefService {

  public resourceUrl = environment.apiServerUrl + 'tam-ref';

  static convertTamRefPaysDateFromServer(tamRefPays: ITamRefPays): ITamRefPays {
    if (tamRefPays) {
      convertDateFromServer(tamRefPays, TAM_REF_PAYS_DATE_FIELDS_DEFINITION);
    }
    return tamRefPays;
  }

  constructor(private http: HttpClient) {
  }

  getCommunesByCodePostal(codePostale: string): Observable<HttpResponse<ITamRefCommune[]>> {
    return this.http
      .get<ITamRefCommune[]>(`${this.resourceUrl}/commune?codePostale=${codePostale}`, {observe: 'response'})
  }

  getCountriesList(): Observable<HttpResponse<ITamRefPays[]>> {
    return this.http
      .get<ITamRefPays[]>(`${this.resourceUrl}/pays`, {observe: 'response'})
      .pipe(map(res => this.convertTamRefPaysDateArrayFromServer(res)));
  }

  getContinentsOmms(): Observable<HttpResponse<IContinentOmms[]>> {
    return this.http
      .get<IContinentOmms[]>(`${this.resourceUrl}/continents-omms`, {observe: 'response'});
  }

  getCountriesListByCodeContinentOmms(): Observable<HttpResponse<ITamRefPays[]>> {
    return this.http
      .get<ITamRefPays[]>(`${this.resourceUrl}/pays`, {observe: 'response'})
  }

  getExercices(): Observable<HttpResponse<ITamRefExercice[]>> {
    return this.http
      .get<ITamRefExercice[]>(`${this.resourceUrl}/exercices`, {observe: 'response'});
  }

  getCurrentExercice(): Observable<HttpResponse<ITamRefExercice>> {
    return this.http
      .get<TamRefExercice>(`${this.resourceUrl}/exercices/current`, {observe: 'response'});
  }

  updateTamRefPays(tamRefPays: ITamRefPays): Observable<HttpResponse<ITamRefPays>> {
    return this.http
      .put<ICamp>(`${this.resourceUrl}/pays`, tamRefPays, {observe: 'response'})
      .pipe(map(res => this.convertTamRefPaysDateFromServer(res)));
  }

  protected convertTamRefPaysDateFromServer(response: HttpResponse<ITamRefPays>): HttpResponse<ITamRefPays> {
    return new HttpResponse({
      ...response,
      body: TamRefService.convertTamRefPaysDateFromServer(response.body)
    });
  }

  protected convertTamRefPaysDateArrayFromServer(res: HttpResponse<ITamRefPays[]>): HttpResponse<ITamRefPays[]> {
    if (res.body) {
      res.body.forEach((tamRefPays: ITamRefPays) => {
        TamRefService.convertTamRefPaysDateFromServer(tamRefPays);
      });
    }
    return res;
  }

}
