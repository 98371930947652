import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdministrationPaysComponent } from './administration-pays/administration-pays.component';
import { AdministrationAuthGuard, AdministrationMailAuthGuard } from "../auth/administration.guard";
import { AdministrationEmailComponent } from './administration-email/administration-email.component';

const routes: Routes = [
  {path: 'pays', component: AdministrationPaysComponent, canActivate: [AdministrationAuthGuard]},
  {path: 'email', component: AdministrationEmailComponent, canActivate: [AdministrationMailAuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
