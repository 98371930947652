<ng-container *ngIf="_focusedTheme">
  <nav class="navbar navbar-light ddc-header-main">
      <a class="navbar-brand" [routerLink]="'/accueil'">
        <img id="ddc-header-logo-master" src="../assets/img/ddc-theme-master-logo.png" alt="SGDF" [routerLink]="['/accueil']"/>
      </a>
      <div class="ddc-header-title">
        <span class="title" ddcLabel labelKey="appli.header.title"></span>
      </div>

      <div class="ddc-header-connected-user" *ngIf="currentDdcUser">
        <div class="ddc-header-connected-user-left">
          <div class="ddc-header-connected-user-notification">
            <div>
              <span [routerLink]="'/accueil'" class="material-icons icon">assignment</span>
            </div>
            <div *ngIf="mesActionsCount && mesActionsCount > 0" class="ddc-header-connected-user-notification-text-div">
              <div class="ddc-header-connected-user-notification-text">{{mesActionsCount}}</div>
            </div>
          </div>

          <div class="ddc-header-connected-user-notification">
            <div>
              <span [routerLink]="'/accueil'" class="material-icons icon">notifications</span>
            </div>
            <div *ngIf="(unReadCampNotificationsCount || unReadProjetAnneeNotificationsCount) && (unReadCampNotificationsCount || unReadProjetAnneeNotificationsCount) > 0" class="ddc-header-connected-user-notification-text-div">
              <div class="ddc-header-connected-user-notification-text">{{unReadCampNotificationsCount + unReadProjetAnneeNotificationsCount}}</div>
            </div>
          </div>
        </div>
        <div class="ddc-header-connected-user-right" (click)="openDdcLogOut()">
          <div class="ddc-header-connected-user-name">{{currentDdcUser.fullName}}</div>
          <span class="material-icons iconExpend">expand_more</span>
        </div>
      </div>
    </nav>
  </ng-container>

  <ng-container>
    <nav class="navbar navbar-expand-lg navbar-dark sticky-top ddc-header-nav ddc-navbar-sticky">

      <ng-container *ngIf="whiteScoutLogoIsDisplay">
        <a class="navbar-brand-white-logo" [routerLink]="'/accueil'">
          <img id="ddc-nav-logo" src="./assets/img/ddc-logo-blanc-1.png" alt="SGDF" [routerLink]="['/accueil']"/>
        </a>
      </ng-container>
      <!-- Home Button -->
      <div class="home-icon">
        <a class="valign-center" mat-icon-button [routerLink]="['/accueil']">
          <mat-icon>home</mat-icon>
        </a>
      </div>

      <!-- Burger button (displayed only in responsive mode for mobiles and tablets) -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div>
        <a class="navbar-brand-nav" [routerLink]="'/accueil'">
          <img id="ddc-header-logo-navbar" src="../assets/img/ddc-logo-blanc.png" alt="SGDF" [routerLink]="['/accueil']"/>
        </a>
      </div>

      <div class="ddc-header-connected-user-notification-burger">
        <span [routerLink]="'/accueil'" class="material-icons icon-nav">assignment</span>
        <div *ngIf="mesActionsCount && mesActionsCount > 0" class="ddc-header-connected-user-notification-text-burger-div">
          <div class="ddc-header-connected-user-notification-text icon-nav-burger">{{mesActionsCount}}</div>
        </div>
      </div>

      <div class="ddc-header-connected-user-notification-burger">
        <span [routerLink]="'/accueil'" class="material-icons icon-nav">notifications</span>
        <div *ngIf="(unReadCampNotificationsCount || unReadProjetAnneeNotificationsCount) && (unReadCampNotificationsCount || unReadProjetAnneeNotificationsCount) > 0" class="ddc-header-connected-user-notification-text-burger-div">
          <div class="ddc-header-connected-user-notification-text icon-nav-burger">{{unReadCampNotificationsCount + unReadProjetAnneeNotificationsCount}}</div>
        </div>
      </div>

      <!-- Profile Icon -->
      <div class="dropdown profil-icon">
        <span class="material-icons icon profil" (click)="openDdcLogOut()">
          account_circle
        </span>
      </div>

      <!-- Menu Tabs -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">

          <!-- Home Button -->
          <div class="home-icon-buger">
            <a class="valign-center" mat-icon-button [routerLink]="['/accueil']">
              <mat-icon>home</mat-icon>
            </a>
          </div>
          <ng-container *ngFor="let menuTab of menuTabs">
            <ddc-menu-tab
              [titleLabelKey]="menuTab.titleLabelKey"
              [subTabs]="menuTab.subTabs"
              [isDisplayed]="menuTab.isDisplayed"
            >
            </ddc-menu-tab>
          </ng-container>
        </ul>
      </div>
    </nav>

    <ng-container>
      <div class="ddc-log-out-div" [@ddcLogOutIsDisplayState] = state>
        <ddc-log-out
          (updateDccLogOutDisplay) = closeLogOut()
          [state] = state
        >
        </ddc-log-out>
      </div>
    </ng-container>

  </ng-container>
