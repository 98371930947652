import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampConfigurationService } from '../service/camp-configuration.service';

@Component({
  selector: 'ddc-mat-dialog',
  templateUrl: './confirm-action-dialog.component.html'
})

export class ConfirmActionDialogComponent {
  focusedTheme: string;
  constructor(
    protected campConfigurationService: CampConfigurationService,
    public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data : ConfirmActionDialogData
  ) {
    this.focusedTheme = this.campConfigurationService.getFocusedTheme();
  }

  onNoClick(): void {
    if(this.data.cancelAction) {
      this.data.cancelAction()
    }
    this.dialogRef.close();
  }

  onYesClick() {
    this.data.confirmAction();
    this.dialogRef.close();
  }
}

export class ConfirmActionDialogData <T = any>{
  public content?: string;
  public contentLabelKey?: string;
  public cancelText?: string;
  public confirmText?: string;
  public confirmAction?: Function;
  public cancelAction?: Function;
}
