import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { IContinentOmms } from './continent-omms.model';

export interface ITamRefPays {
  id?: number;
  libelle?: string;
  idTamPays?: number;
  codeContinent?: number;
  continentOmms?: IContinentOmms;
  adherent?: IAdherent;
  paysOuvert?: boolean;
  emailContact?: boolean;
  dateHeureModification?: Moment;
  adherentModification?: IAdherent;
}

export class TamRefPays {
  constructor(
    public id?: number,
    public libelle?: string,
    public idTamPays?: number,
    public codeContinent?: number,
    public continentOmms?: IContinentOmms,
    public adherent?: IAdherent,
    public paysOuvert?: boolean,
    public emailContact?: boolean,
    public dateHeureModification?: Moment,
    public adherentModification?: IAdherent
  ) { }
}


export const TAM_REF_PAYS_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureModification']);
