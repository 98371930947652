import { Component } from "@angular/core";
import {
  EmailTemplate,
  EmailTemplateTestDto,
  EmailTemplateUpdateDto,
  IEmailTemplate
} from "../../shared/model/email.model";
import { EmailService } from "../../shared/service/email.service";
import { MAIL_TEMPLATE } from "../../shared/constant/constant";
import { filterOkMapBody } from "../../shared/util/http.utils";
import { SnackBarService } from "../../shared/service/snackbar.service";

@Component({
  selector: 'ddc-administration-email',
  templateUrl: './administration-email.component.html',
  styleUrls: ['administration-email.component.scss']
})

export class AdministrationEmailComponent {

  emailTemplate: IEmailTemplate;
  emailAddress: string

  MAIL_TEMPLATE = MAIL_TEMPLATE;

  variables = [];

  constructor(
    protected emailService: EmailService,
    private snackBarService: SnackBarService
  ) {
  }


  selectEmailTemplate(event: any): void {
    const code = event && event.value;
    this.emailService.findByCode(code)
      .pipe(filterOkMapBody)
      .subscribe((emailTemplate: EmailTemplate) => {
        this.emailTemplate = emailTemplate;
        this.variables = this.emailTemplate.variables
          .split(',');
      })
  }

  // Update the content of the email template
  updateEmailTemnplate() {
    const emailTemplateUpdateDto = new EmailTemplateUpdateDto()
    emailTemplateUpdateDto.content = this.emailTemplate.content;
    emailTemplateUpdateDto.code = this.emailTemplate.code;
    this.emailService.updateEmailTemplate(emailTemplateUpdateDto)
  }

  sendTestEmail() {
    const emailTemplateTestDto = new EmailTemplateTestDto();
    emailTemplateTestDto.code = this.emailTemplate.code;
    emailTemplateTestDto.emailAddress = this.emailAddress;
    this.emailService.sendTestEmail(emailTemplateTestDto)
      .subscribe(
        value => {
          this.snackBarService.showSuccess('Le mail a été envoyé avec succès', {durationInSeconds: 2});
        }
        ,
        (err) => {
          this.snackBarService.showError(err);
        }
      )
  }
}
