import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {
  constructor(protected http: HttpClient) {
  }

  getFileFromAssets(fileName: string, params: object) {
    return this.http.get(fileName, params);
  }
}
