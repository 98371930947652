<ng-container *ngIf="isInitialized">
  <div class="ddc-notifications-container"
       *ngIf="campNotifications && campNotifications.length > 0 || projetAnneeNotifications.length > 0 ; else noNotifications">

    <div class="ddc-notifications-actions">
      <span class="material-icons" (click)="refreshNotifications()">cached</span>
      <span class="material-icons" (click)="markAllCampNotificationsAsRead()">clear_all</span>
      <span class="material-icons" (click)="openDialogClearAllCampNotifications()">delete_sweep</span>
    </div>

    <ng-container *ngIf="campNotifications && campNotifications.length > 0 ">
    <div style=" margin-bottom: 2%"><span style="font-weight: bolder; padding-left: 3%;">Camps</span></div>
    <div
      [ngClass]="campNotification.statut == NotificationStatutEnum.UNREAD ? 'ddc-notification-bubble ddc-unread-notification' : 'ddc-notification-bubble ddc-read-notification'"
      *ngFor="let campNotification of campNotifications">
      <div class="ddc-notification-header">
        <div class="ddc-notification-title">
          <ng-container *ngIf="getNotificationParams(campNotification.typeNotification); let projetAnneeNotificationParams">
            <span
              [ngStyle]="{'color': campNotification.statut == NotificationStatutEnum.READ ? projetAnneeNotificationParams.color : 'grey'}"
              class="material-icons">
              <ng-container *ngIf="campNotification.typeNotification === TypeNotificationEnum.MODIF_AVIS_MARIN; else noMarin"><img class="ddc-notification-anchor" src="assets/img/ddc-icon-marin.png" alt="ancre"></ng-container>
              <ng-template #noMarin>{{projetAnneeNotificationParams.icon}}</ng-template>
            </span>
            <span>{{projetAnneeNotificationParams.title}}</span>
          </ng-container>
        </div>
        <div class="ddc-notification-date">
          <span>{{convertToHumanDate(campNotification.dateHeureNotification, true, true)}}</span>
        </div>
        <div class="ddc-notification-delete">
          <span (click)="openDialogClearNotification(campNotification, TypeDossierEnum.CAMP)" class="material-icons">delete_forever</span>
        </div>
      </div>

      <div class="ddc-notification-body">
        <div class="ddc-notification-contenu">
        <span (click)="markNotificationAsRead(campNotification, TypeDossierEnum.CAMP, true)">
          {{getNotificationContenu(campNotification)}}
        </span>
        </div>
        <div class="ddc-notification-mark-as-read">
        <span (click)="markNotificationAsRead(campNotification, TypeDossierEnum.CAMP)" class="material-icons">
          {{campNotification.statut == NotificationStatutEnum.UNREAD ? 'visibility_off' : 'visibility'}}
        </span>
        </div>
      </div>
    </div>
    </ng-container>

    <!-- NOTIF PROJET ANNEE-->
    <ng-container *ngIf="projetAnneeNotifications && projetAnneeNotifications.length > 0 ">
    <div  style=" margin-bottom: 2%"><span style="font-weight: bolder; padding-left: 3%; margin-bottom: 100%">Projet d'année</span></div>
    <div
      [ngClass]="projetAnneeNotification.statut == NotificationStatutEnum.UNREAD ? 'ddc-notification-bubble ddc-unread-notification' : 'ddc-notification-bubble ddc-read-notification'"
      *ngFor="let projetAnneeNotification of projetAnneeNotifications">
      <div class="ddc-notification-header">
        <div class="ddc-notification-title">
          <ng-container *ngIf="getNotificationParams(projetAnneeNotification.typeNotification); let projetAnneeNotificationParams">
            <span
              [ngStyle]="{'color': projetAnneeNotification.statut == NotificationStatutEnum.READ ? projetAnneeNotificationParams.color : 'grey'}"
              class="material-icons">
              <ng-container *ngIf="projetAnneeNotification.typeNotification === TypeNotificationEnum.MODIF_AVIS_MARIN; else noMarin"><img class="ddc-notification-anchor" src="assets/img/ddc-icon-marin.png" alt="ancre"></ng-container>
              <ng-template #noMarin>{{projetAnneeNotificationParams.icon}}</ng-template>
            </span>
            <span>{{projetAnneeNotificationParams.title}}</span>
          </ng-container>
        </div>
        <div class="ddc-notification-date">
          <span>{{convertToHumanDate(projetAnneeNotification.dateHeureNotification, true, true)}}</span>
        </div>
        <div class="ddc-notification-delete">
          <span (click)="openDialogClearNotification(projetAnneeNotification, TypeDossierEnum.PROJET_ANNEE)" class="material-icons">delete_forever</span>
        </div>
      </div>

          <div class="ddc-notification-body">
            <div class="ddc-notification-contenu">
              <span (click)="markNotificationAsRead(projetAnneeNotification, TypeDossierEnum.PROJET_ANNEE, true)">
                {{getNotificationContenu(projetAnneeNotification)}}
              </span>
            </div>
            <div class="ddc-notification-mark-as-read">
              <span (click)="markNotificationAsRead(projetAnneeNotification, TypeDossierEnum.PROJET_ANNEE)" class="material-icons">
                {{projetAnneeNotification.statut == NotificationStatutEnum.UNREAD ? 'visibility_off' : 'visibility'}}
              </span>
            </div>
          </div>
    </div>
    </ng-container>
  </div>

  <ng-template #noNotifications>
    <div class="ddc-no-notifications">
      <span class="material-icons">notifications</span>
      <span>Aucune notification</span>
      <span class="material-icons" (click)="refreshNotifications()">cached</span>
    </div>
  </ng-template>
</ng-container>

