import { CAMP_CHECKLIST_ITEM_DATE_FIELDS_DEFINITION, ICampChecklistItem } from './camp-checklist-item.model';
import { DateFieldsDefinition, SubDateFieldDefinition } from '../util/moment.utils';

export interface ICampModuleChecklist {
  id?: number;
  campChecklistItems?: ICampChecklistItem[];
  commentaire?: string;
}

export class CampModuleChecklist {
  constructor(
    public id?: number,
    public commentaire?: string,
  ) { }
}

export const CAMP_MODULE_CHECKLIST_DATE_FIELDS_DEFINITION = new DateFieldsDefinition([], {
  campChecklistItems: new SubDateFieldDefinition(CAMP_CHECKLIST_ITEM_DATE_FIELDS_DEFINITION, true)
});
