import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface ICampMenu {
  id?: number;
  dateMenu?: Moment;
  repas?: number;
  description?: string
}

export class CampMenu {
  constructor(
    public id?: number,
    public dateMenu?: Moment,
    public repas?: number,
    public description?: string
  ) { }
}

export const CAMP_MENU_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateMenu']);
