import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertDateFromServer as utils_convertDateFromServer } from '../util/moment.utils';
import { DDC_PROJET_DATE_FIELDS_DEFINITION, IDdcProjet } from '../model/ddc-projet.model';

@Injectable({
  providedIn: 'root'
})
export class DdcProjetService {

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'ddc-projets';

  static convertDdcProjetDateFromServer(ddcProjet: IDdcProjet): IDdcProjet {
    if (ddcProjet) {
      utils_convertDateFromServer(ddcProjet, DDC_PROJET_DATE_FIELDS_DEFINITION);
    }
    return ddcProjet;
  }

  find(): Observable<HttpResponse<IDdcProjet[]>> {
    return this.http
      .get<IDdcProjet[]>(this.resourceUrl, {observe: 'response'})
      .pipe(map(res => this.convertDdcProjetDateArrayFromServer(res)));
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour un ddc projet)
   * @param response
   */
  protected convertDdcProjetDateFromServer(response: HttpResponse<IDdcProjet>): HttpResponse<IDdcProjet> {
    return new HttpResponse({
      ...response,
      body: DdcProjetService.convertDdcProjetDateFromServer(response.body)
    });
  }

  /**
   * Convertit les dates renvoyées par le serveur en date cliente (pour plusieurs ddc projet)
   * @param res
   */
  protected convertDdcProjetDateArrayFromServer(res: HttpResponse<IDdcProjet[]>): HttpResponse<IDdcProjet[]> {
    if (res.body) {
      res.body.forEach((ddcProjet: IDdcProjet) => {
        DdcProjetService.convertDdcProjetDateFromServer(ddcProjet);
      });
    }
    return res;
  }
}
