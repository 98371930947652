import { Moment } from 'moment';
import {
  CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION,
  ICampHistoriqueModification
} from './camp-historique-modification.model';
import { ICamp817 } from './camp817.model';
import {
  CAMP_ADHERENT_PARTICIPANT_DATE_FIELDS_DEFINITION,
  ICampAdherentParticipant
} from './camp-adherent-participant.model';
import { ICampStructure } from './camp-structure.model';
import { ICampAdherentSoutien } from './camp-adherent-soutien.model';
import { CAMP_ADHERENT_STAFF_DATE_FIELDS_DEFINITION, ICampAdherentStaff } from './camp-adherent-staff.model';
import { ICampModule } from './camp-module.model';
import { CAMP_LIEU_DATE_FIELDS_DEFINITION, ICampLieu } from './camp-lieu.model';
import { ITypeCamp } from './type-camp.model';
import { ICampJourneeType } from './camp-journee-type.model';
import { DateFieldsDefinition, SubDateFieldDefinition } from '../util/moment.utils';
import { ICampNumeroUtile } from './camp-numero-utile.model';
import { CAMP_GRILLE_ACTIVITE_DATE_FIELDS_DEFINITION, ICampGrilleActivite } from './camp-grille-activite.model';
import { CAMP_MENU_DATE_FIELDS_DEFINITION, ICampMenu } from './camp-menu.model';
import { CAMP_AVI_DATE_FIELDS_DEFINITION, ICampAvis } from './camp-avis.model';
import { IAdherent } from './adherent.model';
import { IAdherentInformations } from './adherent-informations.model';
import { ICampModuleInternational } from './camp-module-international.model';
import { CAMP_17_PLUS_DATE_FIELDS_DEFINITION, ICamp17Plus } from './camp-17-plus.model';
import { ICampDiscussionSujet } from './camp-discussion-sujet.model';
import { CAMP_MODULE_MARIN_DATE_FIELDS_DEFINITION, ICampModuleMarin } from './camp-module-marin.model';
import { CAMP_MODULE_CHECKLIST_DATE_FIELDS_DEFINITION, ICampModuleChecklist } from './camp-module-checklist.model';
import { IDdcAlertMessge } from './ddc-alert-message.model';
import { ICampModuleUniteEtrangere } from './camp-module-unite-etrangere.model';
import { ICampAudace } from './camp-audace.model';
import { ICampValidation } from './camp-validation.model';

export interface IDroitsUtilisateurCourant {
  supprimerDdc?: boolean;
  exportDdc?: boolean;
  validerDdc?: boolean;
  teledeclarerDDCS?: boolean;
  modifierAutoriseCQ?: boolean;
  donnerAvisRG?: boolean;
  donnerAvisAP?: boolean;
  modifierInterlocAP?: boolean;
  modifierStructures?: boolean;
  autoriserDepart?: boolean;
  commar?: boolean;
  cmpp?: boolean;
  epa?: boolean;
  epa_national?: boolean;
  admin?: boolean;
}

export class DroitsUtilisateurCourant {
  constructor(
    public supprimerDdc?: boolean,
    public exportDdc?: boolean,
    public validerDdc?: boolean,
    public teledeclarerDDCS?: boolean,
    public modifierAutoriseCQ?: boolean,
    public donnerAvisRG?: boolean,
    public donnerAvisAP?: boolean,
    public modifierInterlocAP?: boolean,
    public modifierStructures?: boolean,
    public autoriserDepart?: boolean,
    public commar?: boolean,
    public cmpp?: boolean,
    public epa?: boolean,
    public epa_national?: boolean,
    public admin?: boolean
  ) {
  }
}

export interface ICamp {
  id?: number;
  _droitsUtilisateurCourant?: IDroitsUtilisateurCourant;
  _ddcCampHistoModuleDerniereModification?: string;
  _campAlertMessages?: IDdcAlertMessge[];
  _campValidationLevels?: ICampValidation;
  _nombreMaximumParticipants?: number;
  _isCampPere?: boolean;
  _isCampEnfant?: boolean;
  _datesInterval?: Moment[];
  codeGroupeCamp?: string;
  libelle?: string;
  statut?: number;
  synthese?: string;
  dateDebut?: Moment;
  dateFin?: Moment;
  typeCamp?: ITypeCamp;
  campPere?: ICamp;
  camp817?: ICamp817;
  camp17Plus?: ICamp17Plus;
  campAudace?: ICampAudace;
  campModuleInternational?: ICampModuleInternational;
  campModuleMarin?: ICampModuleMarin;
  campModuleChecklist?: ICampModuleChecklist;
  campModuleUniteEtrangere?: ICampModuleUniteEtrangere;
  campLieuPrincipal?: ICampLieu;
  nbreKm?: number;
  moyenTransport?: string;
  histoCreation?: ICampHistoriqueModification;
  histoDerniereModification?: ICampHistoriqueModification;
  campStructures?: ICampStructure[];
  structureOrganisatrice?: string;
  campAdherentParticipants?: ICampAdherentParticipant[];
  campAdherentSoutiens?: ICampAdherentSoutien[];
  campAdherentStaffs?: ICampAdherentStaff[];
  campLieux?: ICampLieu[];
  campDiscussionSujets?: ICampDiscussionSujet[];
  campEnfants?: ICamp[],
  commentaireParticipant?: string;
  commentaireStaff?: string;
  telephoneContactStaff?: string;
  campModules?: ICampModule[];
  campJourneeTypes?: ICampJourneeType[];
  commentaireJourneeType?: string;
  campNumeroUtiles?: ICampNumeroUtile[];
  campGrilleActivites?: ICampGrilleActivite[];
  campMenus?: ICampMenu[];
  campAvis?: ICampAvis[];
  statutDepartCamp?: string;
  commentaireDepartCamp?: string;
  commentaireDeclarationTam?: string;
  dateHeureDeclarationTam?: Moment;
  adherentDeclarationTam?: IAdherent;
  adherentValidationDeclarationTam?: IAdherent;
  statutDeclarationTam?: string;
  errorsDeclarationTam?: string;
  dateModificationDeclarationTam?: Moment;
  commentaireModificationDeclarationTam?: string;
  commentaireMoyenPaiementBudget?: string;
  campAdherentStaffsInformations?: IAdherentInformations[];
  commentaireActivitesSpecifiques?: string;
  commentairePreparationActivites?: string;
  commentaireTransport?: string;
  isAttached?: boolean;
  campDeclarationHistorique?: IcampDeclarationHistorique[];

  modifierDateParticipants?: boolean
}

export class Camp implements ICamp {
  constructor(
    public id?: number,
    public _droitsUtilisateurCourant?: IDroitsUtilisateurCourant,
    public _ddcCampHistoModuleDerniereModification?: string,
    public _campAlertMessages?: IDdcAlertMessge[],
    public _campValidationLevels?: ICampValidation,
    public _nombreMaximumParticipants?: number,
    public _isCampPere?: boolean,
    public _isCampEnfant?: boolean,
    public _datesInterval?: Moment[],
    public codeGroupeCamp?: string,
    public libelle?: string,
    public statut?: number,
    public synthese?: string,
    public dateDebut?: Moment,
    public dateFin?: Moment,
    public typeCamp?: ITypeCamp,
    public campPere?: ICamp,
    public camp817?: ICamp817,
    public camp17Plus?: ICamp17Plus,
    public campAudace?: ICampAudace,
    public campModuleInternational?: ICampModuleInternational,
    public campModuleMarin?: ICampModuleMarin,
    public campModuleChecklist?: ICampModuleChecklist,
    public campModuleUniteEtrangere?: ICampModuleUniteEtrangere,
    public campLieuPrincipal?: ICampLieu,
    public nbreKm?: number,
    public moyenTransport?: string,
    public histoCreation?: ICampHistoriqueModification,
    public histoDerniereModification?: ICampHistoriqueModification,
    public campStructures?: ICampStructure[],
    public structureOrganisatrice?: string,
    public campAdherentParticipants?: ICampAdherentParticipant[],
    public campAdherentSoutiens?: ICampAdherentSoutien[],
    public campAdherentStaffs?: ICampAdherentStaff[],
    public campLieux?: ICampLieu[],
    public campDiscussionSujets?: ICampDiscussionSujet[],
    public campEnfants?: ICamp[],
    public commentaireParticipant?: string,
    public commentaireStaff?: string,
    public telephoneContactStaff?: string,
    public campModules?: ICampModule[],
    public campJourneeTypes?: ICampJourneeType[],
    public commentaireJourneeType?: string,
    public campNumeroUtiles?: ICampNumeroUtile[],
    public campGrilleActivites?: ICampGrilleActivite[],
    public campMenus?: ICampMenu[],
    public campAvis?: ICampAvis[],
    public statutDepartCamp?: string,
    public commentaireDepartCamp?: string,
    public commentaireDeclarationTam?: string,
    public dateHeureDeclarationTam?: Moment,
    public adherentDeclarationTam?: IAdherent,
    public adherentValidationDeclarationTam?: IAdherent,
    public statutDeclarationTam?: string,
    public errorsDeclarationTam?: string,
    public dateModificationDeclarationTam?: Moment,
    public commentaireModificationDeclarationTam?: string,
    public commentaireMoyenPaiementBudget?: string,
    public campAdherentStaffsInformations?: IAdherentInformations[],
    public commentaireActivitesSpecifiques?: string,
    public commentairePreparationActivites?: string,
    public commentaireTransport?: string,
    public isAttached?: boolean,
    public campDeclarationHistorique?: IcampDeclarationHistorique[],
    public modifierDateParticipants?: boolean

  ) {
  }
}

export interface ICampsPaginationDto {
  camps: ICamp[],
  campsTotalCount: number
}

export interface ICampCreationDTO {
  codeTypeCamp?: string;
  libelle?: string;
  dateDebut?: Moment;
  dateFin?: Moment;
  codemodules?: object;
  codeStructure?: string;
  idCampPere?: number;
}

export class CampCreationDTO implements ICampCreationDTO {
  constructor(
    public codeTypeCamp?: string,
    public libelle?: string,
    public dateDebut?: Moment,
    public dateFin?: Moment,
    public codemodules?: string[]
  ) {
  }
}

export const CAMP_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebut', 'dateFin', 'dateHeureDeclarationTam', 'dateModificationDeclarationTam'], {
  histoCreation: new SubDateFieldDefinition(CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION),
  histoDerniereModification: new SubDateFieldDefinition(CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION),
  campAdherentParticipants: new SubDateFieldDefinition(CAMP_ADHERENT_PARTICIPANT_DATE_FIELDS_DEFINITION, true),
  campLieux: new SubDateFieldDefinition(CAMP_LIEU_DATE_FIELDS_DEFINITION, true),
  campGrilleActivites: new SubDateFieldDefinition(CAMP_GRILLE_ACTIVITE_DATE_FIELDS_DEFINITION, true),
  campMenus: new SubDateFieldDefinition(CAMP_MENU_DATE_FIELDS_DEFINITION, true),
  campAdherentStaffs: new SubDateFieldDefinition(CAMP_ADHERENT_STAFF_DATE_FIELDS_DEFINITION, true),
  campAvis: new SubDateFieldDefinition(CAMP_AVI_DATE_FIELDS_DEFINITION, true),
  camp17Plus: new SubDateFieldDefinition(CAMP_17_PLUS_DATE_FIELDS_DEFINITION),
  campModuleMarin: new SubDateFieldDefinition(CAMP_MODULE_MARIN_DATE_FIELDS_DEFINITION),
  campModuleChecklist: new SubDateFieldDefinition(CAMP_MODULE_CHECKLIST_DATE_FIELDS_DEFINITION)
});

export interface IcampDeclarationHistorique {
  dateHeureDeclarationTam?:string;
  numeroAdherentDeclarationTam?:string;
  idCamp?: number
}
