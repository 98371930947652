import {Moment} from 'moment';
import {IAdherent} from './adherent.model';
import {DateFieldsDefinition} from '../util/moment.utils';

export interface ICampHistoriqueModification {
  id?: number;
  dateHeureModification?: Moment;
  codeModule?: string;
  adherent?: IAdherent;
  modificationJson?: string;
  idCampModule?: number;
}

export interface ICampHistoModuleDerniereModificationContainer<T> {
  data?: T;
  campHistoModuleDerniereModification?: ICampHistoriqueModification;
  serverValue?: T;
}

export const CAMP_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureModification']);

export class CampHistoriqueModification implements ICampHistoriqueModification {
  constructor(
    public id?: number,
    public dateHeureModification?: Moment,
    public adherent?: IAdherent,
    public modificationJson?: string,
    idCampModule?: number
  ) {
  }
}
