import { ITrimestre } from './trimestre.model';
import { Moment } from 'moment';
import { IAdherent } from './adherent.model';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface IProjetAnneeFcTrimestre {
  id?: number;
  trimestre?: ITrimestre;
  statut?: number,
  dateDebut?: Moment;
  dateFin?: Moment;
  nbreJeunes613?: number;
  nbreJeunes1417?: number;
  adherentResponsable?: IAdherent;
  adherentDeclarant?: IAdherent;
  idTam?: number;
  commentaireDeclarationTam?: string;
  commentaireModificationDeclarationTam?: string;
  statutDeclarationTam?: string;
  errorsDeclarationTam?: string;
  dateHeureDeclarationTam?: Moment;
  adherentDeclarationTam?: IAdherent;
  dateModificationDeclarationTam?: Moment;
  adherentValidationDeclarationTam?: IAdherent;

}

export class ProjetAnneeFcTrimestre implements IProjetAnneeFcTrimestre {
  constructor(
    public id?: number,
    public trimestre?: ITrimestre,
    public statut?: number,
    public dateDebut?: Moment,
    public dateFin?: Moment,
    public nbreJeunes613?: number,
    public nbreJeunes1417?: number,
    public adherentResponsable?: IAdherent,
    public adherentDeclarant?: IAdherent,
    public idTam?: number,
    public commentaireDeclarationTam?: string,
    public commentaireModificationDeclarationTam?: string,
    public statutDeclarationTam?: string,
    public errorsDeclarationTam?: string,
    public dateHeureDeclarationTam?: Moment,
    public adherentDeclarationTam?: IAdherent,
    public dateModificationDeclarationTam?: Moment,
    public adherentValidationDeclarationTam?: IAdherent
  ) {
  }
}

export const PROJET_ANNEE_FC_TRIMESTRE_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebut','dateFin', 'dateHeureDeclarationTam', 'dateModificationDeclarationTam']);
