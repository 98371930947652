import { Moment } from 'moment';
import { IAdherent } from './adherent.model';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface IProjetAnneeHistoriqueModification {
  id?: number;
  dateHeureModification?: Moment;
  codeModule?: string;
  adherent?: IAdherent;
  modificationJson?: string;
  idProjetAnneeModule?: number;
}

export interface IProjetAnneeHistoModuleDerniereModificationContainer<T> {
  data?: T;
  projetAnneeHistoModuleDerniereModification?: IProjetAnneeHistoriqueModification;
  serverValue?: T;
}

export const PROJET_ANNEE_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureModification']);

export class ProjetAnneeHistoriqueModification implements IProjetAnneeHistoriqueModification {
  constructor(
    public id?: number,
    public dateHeureModification?: Moment,
    public adherent?: IAdherent,
    public modificationJson?: string,
    idProjetAnneeModule?: number
  ) {
  }
}
