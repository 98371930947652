import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackBarAlertComponent } from './shared/snackbar/snackbar-alert.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingInterceptor } from './shared/interceptor/loading.interceptor';
import { ErrorHandlerInterceptor } from './shared/interceptor/error-handler.interceptor';
import { ConfirmActionDialogComponent } from './shared/mat-dialog/confirm-action-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditCampInfoDialogComponent } from './shared/mat-dialog/edit-camp-info-dialog.component';
import { EditDdcModulesDialogComponent } from './shared/mat-dialog/edit-ddc-modules-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { AccueilModule } from './accueil/accueil.module';
import { LogOutComponent } from './log-out/log-out.component';
import { MenuTabComponent } from './menu/menu-tab/menu-tab.component';
import { AdministrationModule } from './administration/administration.module';
import { AuthenticationService } from './shared/service/authentication.service';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { SurveyModule } from "survey-angular-ui";
import { SentryErrorHandler } from './shared/interceptor/sentry-error-handler';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MenuTabComponent,
    SnackBarAlertComponent,
    LoadingComponent,
    ConfirmActionDialogComponent,
    EditCampInfoDialogComponent,
    EditDdcModulesDialogComponent,
    LoginComponent,
    FooterComponent,
    LogOutComponent
  ],
  imports: [
    SurveyModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatGridListModule,
    MatRadioModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MaterialFileInputModule,
    MatDialogModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTooltipModule,
    SharedModule,
    AccueilModule,
    AdministrationModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [AuthenticationService],
    },
    { 
      provide: ErrorHandler, 
      useClass: SentryErrorHandler,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

function initializer(authenticationService: AuthenticationService): () => Promise<any> {
  return (): Promise<any> => {
    const url = new URL(window.location.href);
    const { pathname, search, searchParams } = url;
    if (pathname === '/login' && searchParams && searchParams.has('code')) {
      return authenticationService.loginWithKeycloak(search);
    } else {
      return Promise.resolve();
    }
  };
}
