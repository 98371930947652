import { IProjetAnnee } from './projet-annee.model';
import { ProjetAnneeModificationActionEnum } from '../constant/enum';

export interface IProjetAnneeModificationStaticModuleDTO {
  moduleCode?: string;
  projetAnnee?: IProjetAnnee;
  modificationJson?: string;
  action?: ProjetAnneeModificationActionEnum;
}

export class ProjetAnneeModificationStaticModuleDTO implements IProjetAnneeModificationStaticModuleDTO {
  constructor(
    public moduleCode?: string,
    public projetAnnee?: IProjetAnnee,
    public modificationJson?: string,
    public action?: ProjetAnneeModificationActionEnum,
  ) {
  }

}
