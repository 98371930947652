import { IChecklistItem } from './checklist-item.model';
import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface ICampChecklistItem {
  id?: number;
  checklistItem?: IChecklistItem;
  adherent?: IAdherent;
  dateHeureModification?: Moment;
  blActif?: boolean;
  commentaire?: string;
}

export class CampChecklistItem {
  constructor(
    public id?: number,
    public checklistItem?: IChecklistItem,
    public adherent?: IAdherent,
    public dateHeureModification?: Moment,
    public blActif?: boolean,
    public commentaire?: string
  ) { }
}

export const CAMP_CHECKLIST_ITEM_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureModification']);
