import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { AuthenticationService } from '../shared/service/authentication.service'
import { ExternalRedirectService } from '../shared/service/external-redirect.service'

export const AuthGuard: CanActivateFn = (route, state) => {

  const authenticationService = inject(AuthenticationService)
  const externalRedirectService = inject(ExternalRedirectService)

  if (authenticationService.currentUserValue) {
    return true
  } else {
    externalRedirectService.triggerLogin()
    return false
  }
}
