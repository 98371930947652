import { Component, ViewChild } from '@angular/core';
import { ITamRefPays, TamRefPays } from '../../shared/model/tam-ref-pays.models';
import { TamRefService } from '../../shared/service/tam-ref.service';
import { filterOkMapBody } from '../../shared/util/http.utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from '../../shared/service/snackbar.service';
import { DdcMatDialogService } from '../../shared/service/ddc-mat-dialog.service';
import { comparePaysValues } from '../../shared/util/ddc-lieu-utils';
import { MatSelect } from '@angular/material/select';
import { convertToHumanDate } from '../../shared/util/moment.utils';

@Component({
  selector: 'ddc-administration-pays',
  templateUrl: './administration-pays.component.html',
  styleUrls: ['administration-pays.component.scss']
})
export class AdministrationPaysComponent {

  @ViewChild("matSelect") matSelect: MatSelect;

  tamRefPays: ITamRefPays[];
  selectedPays: ITamRefPays;
  comparePaysValues = comparePaysValues;

  selectedPaysFormGroup: FormGroup;
  convertToHumanDate = convertToHumanDate;

  codeEntryForm: FormGroup = this.fb.group({
    codeAdherent: ['']
  });

  constructor(
    protected refService: TamRefService,
    protected snackBarService: SnackBarService,
    protected ddcMatDialogService: DdcMatDialogService,
    protected fb: FormBuilder
  ) {
    //initialize tamRefPays
    this.refService.getCountriesList()
      .pipe(filterOkMapBody)
      .subscribe((res: ITamRefPays[]) => {
        this.tamRefPays = res;
      });
  }

  setSelectedPays(event, forceSetPays = false) {
    if(this.selectedPays && this.checkIfPaysValuesChanged() && !forceSetPays) {
      this.openDialogChangeSelectedPays(event);
    }else {
      this.selectedPays = event;
      this.initPaysSelectionFormGroup(this.selectedPays);
    }
  }

  onCancelAction() {
    this.matSelect.value = this.selectedPays;
  }

  openDialogChangeSelectedPays(event) {
    this.ddcMatDialogService.openConfirmActionDialog({
      content: 'Les modifications ne sont pas enregistrées. Êtes vous sur de vouloir continuer ?',
      confirmText: 'Confirmer',
      cancelText: 'Annuler',
      confirmAction: () => this.setSelectedPays(event, true),
      cancelAction: () => this.onCancelAction()
    });
  }

  initPaysSelectionFormGroup(tamRefPays: ITamRefPays) {
    if (tamRefPays) {
      this.selectedPaysFormGroup = this.fb.group(new TamRefPays());
      this.selectedPaysFormGroup.get('emailContact').setValidators([Validators.required])
      this.selectedPaysFormGroup.patchValue(tamRefPays);
    } else {
      this.selectedPaysFormGroup = null;
    }
  }

  checkIfPaysValuesChanged() {
    const tamRefPays: ITamRefPays = this.selectedPaysFormGroup ? this.selectedPaysFormGroup.getRawValue() : '';
    if(tamRefPays && this.selectedPays) {
      return (
        tamRefPays.paysOuvert !== this.selectedPays.paysOuvert ||
        tamRefPays.emailContact !== this.selectedPays.emailContact
      );
    }
    return false;
  }

  save() {
    if(this.selectedPaysFormGroup) {
      if(this.selectedPaysFormGroup.invalid) {
        this.snackBarService.showError("Complete the required data");
      }else {
        const pays: ITamRefPays = this.selectedPaysFormGroup.getRawValue();
        this.refService.updateTamRefPays(pays)
          .pipe(filterOkMapBody)
          .subscribe((res: ITamRefPays) => {
            this.selectedPays = res;
            this.updateTamRefPaysList(res);
            this.snackBarService.showSuccess('Les données ont été enregistrées avec succès', {durationInSeconds: 2});
          },
          (err) => {
            this.snackBarService.showError(err);
          })
      }
    }
  }

  updateTamRefPaysList(updatedTamRefPays: ITamRefPays) {
    const index = this.tamRefPays.findIndex((tamRefPays: ITamRefPays) => tamRefPays.idTamPays == updatedTamRefPays.id);
    if(index != -1) {
      this.tamRefPays[index] = updatedTamRefPays;
    }
  }

}
