import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDdcFunctionality, IDdcUser } from '../model/ddc-user.model';
import { environment } from '../../../environments/environment';
import { AUTH_STORAGE_KEY } from '../constant/constant';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { LocalCacheService } from './local-cache.service';
import { utils } from 'knockout';
import parseJson = utils.parseJson;
import { ExternalRedirectService } from './external-redirect.service';

class AuthResp {
  constructor(
    public token: string,
    public userData: IDdcUser,
  ) {}
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<IDdcUser>;
  public currentUser: Observable<IDdcUser>;

  constructor(private http: HttpClient, private router: Router, private externalRedirectService: ExternalRedirectService) {
    this.currentUserSubject = new BehaviorSubject<IDdcUser>(JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): IDdcUser {
    return this.currentUserSubject.value;
  }

  login(numero, password) {
    return this.http.post<AuthResp>(`${environment.apiServerUrl}login`, {numero, password})
      .pipe(
        map((authResp: AuthResp) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const ddcUser = authResp.userData;
          ddcUser.token = authResp.token;
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(ddcUser));
          this.currentUserSubject.next(ddcUser);
          return ddcUser;
        }));
  };

  loginWithKeycloak(params: string) {
    return this.http.get<AuthResp>(`${environment.apiServerUrl}login/check${params}`).toPromise()
      .then((authResp: AuthResp) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const ddcUser = authResp.userData;
          ddcUser.token = authResp.token;
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(ddcUser));
          this.currentUserSubject.next(ddcUser);
          return ddcUser;
        });
  };

  refreshDdcToken(newDdcToken: string) {
    const currentUser = this.currentUserValue;
    currentUser.token = newDdcToken;
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
  }

  logout() {
    LocalCacheService.removeCampSearchCriterias();
    LocalCacheService.removeProjetAnneeSearchCriterias();
    LocalCacheService.removeLastVisitedUrl(this.currentUserValue);
    // remove user from local storage and set current user to null
    localStorage.removeItem(AUTH_STORAGE_KEY);
    this.currentUserSubject.next(null);
    this.externalRedirectService.triggerLogout();
  }

  changeDdcUserFunctionality(selectedFunctionality: IDdcFunctionality) {
    return this.http
      .post<AuthResp>(`${environment.apiServerUrl}adherents/change-functionality`, selectedFunctionality)
      .pipe(
        map((authResp: AuthResp) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const ddcUser = authResp.userData;
        ddcUser.token = authResp.token;
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(ddcUser));
        LocalCacheService.removeCampSearchCriterias();
        this.currentUserSubject.next(ddcUser);
        return ddcUser;
      }));
  }

  isTokenValid() {
    const token = this.currentUserValue ? this.currentUserValue.token : null;
    if(token) {
      const decodedToken: IDecodedToken = jwtDecode(token);
      const currentDate = moment().utc().valueOf();
      if( decodedToken.exp >= Math.trunc(currentDate / 1000) ) {
        return true;
      }
    }
    return false;
  }

  redirectTo(ddcUser: IDdcUser) {
    const lastVisitedUrl = LocalCacheService.getLastVisitedUrl(ddcUser);
    if(lastVisitedUrl){
      this.router.navigateByUrl(this.router.parseUrl(lastVisitedUrl));
    } else {
      this.router.navigate(['/accueil']).then(() => console.log('logged in'));
    }
  }

  checkLoginStatus(){
   if(localStorage.getItem(AUTH_STORAGE_KEY) && this.isTokenValid()){
     const ddcUser = parseJson(localStorage.getItem(AUTH_STORAGE_KEY));
    this.redirectTo(ddcUser);
   } else {
      localStorage.removeItem(AUTH_STORAGE_KEY);
   }}}

interface IDecodedToken {
  exp: number;
}

