import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TypeDossierEnum } from '../shared/constant/enum';
import { AuthenticationService } from '../shared/service/authentication.service';
import { IDdcUser } from '../shared/model/ddc-user.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { IDdcProjet } from '../shared/model/ddc-projet.model';
import { filterOkMapBody } from '../shared/util/http.utils';
import { DdcProjetService } from '../shared/service/ddc-projet.service';
import { CampConfigurationService } from '../shared/service/camp-configuration.service';

@Component({
  selector: 'ddc-accueil-page',
  templateUrl: './accueil.page.component.html',
  styleUrls: ['./accueil.page.component.scss']
})
export class AccueilPageComponent implements OnInit, AfterContentChecked {

  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

  currentUser: IDdcUser;
  targetTab: string;

  ddcProjets: IDdcProjet[];
  mesDdcProjetsDisplayedColumns = ['icon', 'libelle', 'structure'];

  TypeDossierEnum = TypeDossierEnum;
  constructor(
    protected authentication: AuthenticationService,
    protected ddcProjetService: DdcProjetService,
    protected campConfigurationService: CampConfigurationService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef
  ) {
    this.currentUser = this.authentication.currentUserValue;

    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    // Set query params
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.targetTab = queryParams && queryParams['targetTab'] ? queryParams['targetTab'] : null;
    });

  }

  ngOnInit(): void {
    this.ddcProjetService.find()
      .pipe(filterOkMapBody)
      .subscribe((ddcProjets: IDdcProjet[]) => {
        this.initDdcProjets(ddcProjets);
      });

    // reset the color of the theme
    this.campConfigurationService.setCampConfigurationsByTypeCamp(null);
  }

  initDdcProjets(ddcProjets: IDdcProjet[]) {
    ddcProjets.sort((a: IDdcProjet, b:IDdcProjet) => {
      return a.dateHeureCreation.diff(b.dateHeureCreation,'seconds') > 0 ? -1 : 1;
    });
    this.ddcProjets = ddcProjets;
  }


  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
