<ng-container *ngIf="isInitialized">
  <div class="ddc-actions-container" *ngIf="campDiscussionSujets && campDiscussionSujets.length > 0; else noActions">
    <div class="ddc-action-bubble" *ngFor="let campDiscussionSujet of campDiscussionSujets">
      <div class="ddc-action-header">
        <div class="ddc-action-title">
          <span class="material-icons">swap_horizantal_circle</span>
          <span>{{getLibelleAction(campDiscussionSujet)}}</span>
        </div>
        <div class="ddc-action-date">
          <span>{{convertToHumanDate(campDiscussionSujet.dateHeureCreation, true, true)}}</span>
        </div>
      </div>
      <div class="ddc-action-body">
        <div class="ddc-action-contenu">
          <span (click)="accessCamp(campDiscussionSujet)" [innerHTML]="getTextAction(campDiscussionSujet)"></span>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noActions>
    <div class="ddc-no-notifications">
      <span class="material-icons">swap_horizantal_circle</span>
      <span>Aucune tâches</span>
    </div>
  </ng-template>
</ng-container>
