import { ADHERENT_DATE_FIELDS_DEFINITION, IAdherent } from './adherent.model';
import { ICampAdherent } from './camp-adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition, SubDateFieldDefinition } from '../util/moment.utils';

export interface ICampAdherentParticipant extends ICampAdherent {
  chefEquipe?: boolean;
  coordonneesParents?: string;
  responsabilites?: string;
}

export class CampAdherentParticipant implements ICampAdherentParticipant {
  constructor(
    public id?: number,
    public adherent?: IAdherent,
    public dateDebutPresence?: Moment,
    public dateFinPresence?: Moment,
    public chefEquipe?: boolean,
    public coordonneesParents?: string,
    public responsabilites?: string
  ) { }
}

export const CAMP_ADHERENT_PARTICIPANT_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebutPresence', 'dateFinPresence'],{
  adherent: new SubDateFieldDefinition(ADHERENT_DATE_FIELDS_DEFINITION)
});
