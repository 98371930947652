import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { ICamp17PlusMotivation } from './camp-17-plus-motivation.model';
import { ICamp17PlusPisteAction } from './camp-17-plus-piste-action.model';
import { ICamp17PlusPartenaire } from './camp-17-plus-partenaire.model';

export interface ICamp17Plus {
  id?: number;
  equipeContacter?: string;
  equipeDescription?: string;
  equipeBilanAnnee?: string;
  equipeEnrichissementAC?: string;
  experimentDescription?: string;
  experimentEnvies?: string;
  experimentDecision?: string;
  camp17PlusPistesActions?: ICamp17PlusPisteAction[];
  experimentExplicationChoix?: string;
  experimentobjectif?: string;
  vie_equipeDetails?: string;
  vieEquipeCharte?: boolean;
  vieEquipeSpi?: string;
  vieEquipeFilsBlancs?: string;
  vieEquipeDemarche?: string;
  vieEquipeEnrichissementAC?: string;
  camp17PlusPartenaires?: ICamp17PlusPartenaire[];
  partenaireEnrichissementAC?: string;
  rencontrePreparation?: string;
  rencontreDifferenceCulturelle?: string;
  rencontreEnrichissementAC?: string;
  camp17PlusMotivations?: ICamp17PlusMotivation[];
  progPersoEnrichissementAC?: string;
  retourPrevision?: string;
  retourDateRelectureChaud?: Moment;
  retourDateSpi?: Moment;
  retourDatePartenaire?: Moment;
}

export class Camp17Plus {
  constructor(
    public id?: number,
    public equipeContacter?: string,
    public equipeDescription?: string,
    public equipeBilanAnnee?: string,
    public equipeEnrichissementAC?: string,
    public experimentDescription?: string,
    public experimentEnvies?: string,
    public experimentDecision?: string,
    public camp17PlusPistesActions?: ICamp17PlusPisteAction[],
    public experimentExplicationChoix?: string,
    public experimentobjectif?: string,
    public vie_equipeDetails?: string,
    public vieEquipeCharte?: boolean,
    public vieEquipeSpi?: string,
    public vieEquipeFilsBlancs?: string,
    public vieEquipeDemarche?: string,
    public vieEquipeEnrichissementAC?: string,
    public camp17PlusPartenaires?: ICamp17PlusPartenaire[],
    public partenaireEnrichissementAC?: string,
    public rencontrePreparation?: string,
    public rencontreDifferenceCulturelle?: string,
    public rencontreEnrichissementAC?: string,
    public camp17PlusMotivations?: ICamp17PlusMotivation[],
    public progPersoEnrichissementAC?: string,
    public retourPrevision?: string,
    public retourDateRelectureChaud?: Moment,
    public retourDateSpi?: Moment,
    public retourDatePartenaire?: Moment,
  ) {
  }
}

export const CAMP_17_PLUS_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['retourDateRelectureChaud', 'retourDateSpi', 'retourDatePartenaire']);
