import { ICamp } from './camp.model';
import { IAdherent } from './adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { TypeNotificationEnum } from '../constant/enum';

export interface ICampNotification {
  id?: number;
  camp?: ICamp;
  adherent?: IAdherent;
  typeNotification?: TypeNotificationEnum;
  contenu?: string;
  statut?: number;
  dateHeureNotification?: Moment;
  codeModule?: string;
  idCampModule?: number;
  campId?: number;
  campLibelle?: string;
}

export class CampNotification {
  constructor(
    public id?: number,
    public camp?: ICamp,
    public adherent?: IAdherent,
    public typeNotification?: TypeNotificationEnum,
    public contenu?: string,
    public statut?: number,
    public dateHeureNotification?: Moment,
    public codeModule?: string,
    public idCampModule?: number,
    public campLibelle?: string,
    public campId?: number
  ) {
  }
}

export const CAMP_NOTIFICATION_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureNotification']);
