import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'ddcMatError, [ddcMatError]',
  template: '{{errorMessage}}',
  styleUrls: []
})
export class ControlErrorComponent implements OnInit {
  @Input() public control: FormControl;

  errorMessage: string = '';

  ngOnInit() {
    // Call on page load, to initialize the errors of the input fields
    this.initErrorMessage();

    //Call when the status of the input field is changed to re-initialize the errors of the field or delete them
    this.control.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        this.initErrorMessage();
      }
    });
  }

  initErrorMessage() {
    let errors = (this.control && this.control.errors) ? this.control.errors : undefined;
    if (errors) {
      if (errors.errorMessage) {
        this.errorMessage = errors.errorMessage;
      }
      if (errors.required) {
        this.errorMessage = 'Ce champ est requis';
      }
      if (errors.min) {
        this.errorMessage = 'La valeur minimale est ' + errors.min.min;
      }
      if (errors.max) {
        this.errorMessage = 'La valeur maximale est ' + errors.max.max;
      }
    }
  }
}
