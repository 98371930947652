import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {TypeDossierEnum} from "../constant/enum";

@Directive({
  selector: '[ddcMouseEventManager]',
})
export class MouseEventManagerDirective implements OnInit {


  @Input() set typeDdc(typeDdc: TypeDossierEnum) {
    this._typeDdc = typeDdc;
  }

  @Input() set idDdc(idDdc: number) {
    this._idDdc = idDdc;
  }

  @Output() logOutPopUpStateEvent = new EventEmitter();

  _typeDdc: TypeDossierEnum;
  _idDdc: number;

  constructor(private route: Router) {

  }

  ngOnInit() {

  }

  @HostListener('click', ['$event']) onClick($event){
    this.clickEventManager($event, 'same');
    this.clickEventManager($event, 'close');
  }

  @HostListener('auxclick', ['$event']) onAuxClick($event){
    this.clickEventManager($event, 'new');
  }

  @HostListener('contextmenu', ['$event']) contexMenu($event){
    this.clickEventManager($event, 'new');
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.clickEventManager(event, 'close');
  }

  clickEventManager($event: any, tab: string) {

    switch (tab) {
      case 'same':
        if($event.ctrlKey == true ) this.openLinkInNewTab();
        else this.openLinkInSameTab()
        break;
      case 'new':
        this.openLinkInNewTab();
        break;
      case 'close':
        this.logOutPopUpStateEvent.emit('close');
    }
  }

  openLinkInNewTab() {
    if(this._typeDdc === TypeDossierEnum.PROJET_ANNEE) {
      this.route.navigate([]).then(result => {  window.open('/projet-annee/'+this._idDdc, '_blank'); });
    } else if (this._typeDdc === TypeDossierEnum.CAMP) {
      this.route.navigate([]).then(result => {  window.open('/camp/'+this._idDdc, '_blank'); });
    }
  }

  openLinkInSameTab() {
    if(this._typeDdc === TypeDossierEnum.PROJET_ANNEE) {
      this.route.navigate(['/projet-annee/'+this._idDdc])
    } else if (this._typeDdc === TypeDossierEnum.CAMP) {
      this.route.navigate(['/camp/'+this._idDdc])
    }
  }

}
