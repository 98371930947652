import { Moment } from 'moment';
import { IAdherent } from './adherent.model';
import { DateFieldsDefinition } from '../util/moment.utils';
import { ICampGrilleActiviteCampAdherentStaff } from './camp-grille-activite-camp-adherent-staff.model';

export interface ICampGrilleActivite {
  id?: number;
  dateActivite?: Moment;
  creneau?: number;
  description?: number;
  imaginaire?: string;
  campGrilleActiviteCampAdherentStaffs?: ICampGrilleActiviteCampAdherentStaff[];
  staffResponsable?: IAdherent;
  participantResponsable?: IAdherent;
  objectifPedagogique?: string;
}

export class CampGrilleActivite implements ICampGrilleActivite {
  constructor(
    public id?: number,
    public dateActivite?: Moment,
    public creneau?: number,
    public description?: number,
    public imaginaire?: string,
    public campGrilleActiviteCampAdherentStaffs?: ICampGrilleActiviteCampAdherentStaff[],
    public staffResponsable?: IAdherent,
    public participantResponsable?: IAdherent,
    public objectifPedagogique?: string
  ) {
  }
}

export const CAMP_GRILLE_ACTIVITE_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateActivite']);
