import { IStructureAdherent } from './structure-adherent.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { ICivilite } from './civilite.model';

export interface IAdherent {
  numero?: string;
  prenom?: string;
  nom?: string;
  nomNaissance?: string;
  civilite?: ICivilite;
  dateNaissance?: Moment;
  structureAdherents?: IStructureAdherent[];
  telephonePere?: string;
  telephoneMere?: string;
  telephonePortable?: string;
  mailAffectationSoutien?: boolean;
  mailChangementAvis?: boolean;
  mailChangementDossier?: boolean;
  mailMessage?: boolean;
  email?: string;
}

export class Adherent implements IAdherent {
  constructor(
    public numero?: string,
    public prenom?: string,
    public nom?: string,
    public nomNaissance?: string,
    public civilite?: ICivilite,
    public dateNaissance?: Moment,
    public structureAdherents?: IStructureAdherent[],
    public telephonePere?: string,
    public telephoneMere?: string,
    public telephonePortable?: string,
    public mailAffectationSoutien?: boolean,
    public mailChangementAvis?: boolean,
    public mailChangementDossier?: boolean,
    public mailMessage?: boolean,
    public email?: string
  ) {}
}

export const ADHERENT_DATE_FIELDS_DEFINITION = new DateFieldsDefinition( ['dateNaissance']);
