import { TypeDossierEnum } from '../constant/enum';
import { Structure } from './structure.model';
import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';

export interface IDdcProjet {
  id?: number;
  libelle?: string;
  typeProjet?: TypeDossierEnum;
  structure?: Structure;
  dateHeureCreation?: Moment;
}

export class DdcProjet implements IDdcProjet{
  constructor(
    public id?: number,
    public libelle?: string,
    public typeProjet?: TypeDossierEnum,
    public structure?: Structure,
    public dateHeureCreation?: Moment,
  ) { }
}

export const DDC_PROJET_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateHeureCreation']);

