import {Component, Input} from '@angular/core';
import { IHdChangesObj } from './camp-info-complementaire-historique-hdtype-obj.component';

@Component({
  selector: 'ddc-camp-info-complementaire-historique-hdtype-list',
  templateUrl: './camp-info-complementaire-historique-hdtype-list.component.html',
  styleUrls: ['../../../camp/camp-info-complementaire/camp-info-complementaire-historique/camp-info-complementaire-historique.component.scss']
})
export class CampInfoComplementaireHistoriqueHdtypeListComponent {

  @Input() set item(item: IHdTypeList) {
    this._item = item;
  }
  _item: IHdTypeList;
}

export interface IhdTypeListItem extends IHdChangesObj {
  hdActionType?: string;
  itemLabel?: string;
  newVal?: any;
  changes?: IHdChangesList;
}

export interface IHdTypeList {
  labelKey?: string;
  hdType?: string;
  changes?: IhdTypeListItem[];
}

export interface IHdChangesList extends IHdChangesObj {
  newVal?: any;
}
