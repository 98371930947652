import { ICampAdherentStaff } from './camp-adherent-staff.model';

export interface ICampGrilleActiviteCampAdherentStaff {
  id?: number;
  campAdherentStaff?: ICampAdherentStaff;
}

export class CampGrilleActiviteCampAdherentStaff {
  constructor(
    public id?: number,
    public campAdherentStaff?: ICampAdherentStaff,
  ) {
  }
}
