<div class="container-fluid footer-bottom-page">
  <div class="row">
    <div class="col-12">
      <mat-card class="ddc-card-with-title">
        <mat-card-title>
          <span ddcLabel labelKey="appli.accueil.homePage.carte.mesProjets"></span>
        </mat-card-title>
        <mat-card-content>
          <div ddcLabel labelKey="appli.accueil.homePage.mesProjets.description" class="ddc-appli-description"></div>
          <div class="ddc-short-table-div-container">
            <ng-container *ngIf="ddcProjets?.length > 0 ;else noResultsBlock">
              <table mat-table [dataSource]="ddcProjets">
                <ng-container matColumnDef="icon">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let ddcProjet">
                    <i class="material-icons">
                      {{ddcProjet.typeProjet == TypeDossierEnum.CAMP ? 'landscape' : 'today'}}
                    </i>
                  </td>
                </ng-container>

                <ng-container matColumnDef="libelle">
                  <th mat-header-cell *matHeaderCellDef>
                    <span ddcLabel labelKey="appli.accueil.homePage.mesProjets.libelle"></span>
                  </th>
                  <td mat-cell *matCellDef="let ddcProjet">
                    {{ddcProjet.libelle}}
                  </td>
                </ng-container>


                <ng-container matColumnDef="structure">
                  <th mat-header-cell *matHeaderCellDef>
                    <span ddcLabel labelKey="appli.accueil.homePage.mesProjets.structure"></span>
                  </th>
                  <td mat-cell *matCellDef="let ddcProjet">
                    {{ddcProjet.structure ? ddcProjet.structure.code + ' - ' + ddcProjet.structure.libelle : 'N/A'}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="mesDdcProjetsDisplayedColumns; sticky: true"></tr>
                <tr ddcMouseEventManager [typeDdc]="row.typeProjet == TypeDossierEnum.PROJET_ANNEE ? TypeDossierEnum.PROJET_ANNEE : TypeDossierEnum.CAMP" [idDdc]="row.id" mat-row *matRowDef="let row; columns: mesDdcProjetsDisplayedColumns;"
                    [routerLink]="row.typeProjet == TypeDossierEnum.PROJET_ANNEE ? '/projet-annee/'+row.id : '/camp/'+row.id">
                </tr>
              </table>
            </ng-container>
            <ng-template #noResultsBlock>
              <div class="ddc-warning-message">
                <span class="material-icons">info</span>
                <span>Aucun résultat trouvé</span>
              </div>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-6">
      <mat-card class="ddc-card-with-title">
        <mat-card-title>
          <span ddcLabel labelKey="appli.accueil.homePage.carte.mesTaches"></span>
        </mat-card-title>
        <mat-card-content>
          <div ddcLabel labelKey="appli.accueil.homePage.mesTaches.description" class="ddc-appli-description"></div>
          <ddc-mes-actions></ddc-mes-actions>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-6 col-lg-6">
      <mat-card class="ddc-card-with-title">
        <mat-card-title>
          <span ddcLabel labelKey="appli.accueil.homePage.carte.notifications"></span>
        </mat-card-title>
        <mat-card-content>
          <div ddcLabel labelKey="appli.accueil.homePage.notifications.description" class="ddc-appli-description"></div>
          <ddc-mes-notifications></ddc-mes-notifications>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
