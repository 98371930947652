import { Injectable } from '@angular/core';
import { SnackBarAlertComponent, DDCSnackBarOptions } from '../snackbar/snackbar-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private static readonly DEFAULT_SNACK_BAR_DURATION = 5;
  PANEL_CLASS_SUFFIX = '-snackbar';
  SNACK_BAR_TYPE: Array<string> = ['success', 'danger', 'warning', 'info'];

  constructor(
    private snackbar: MatSnackBar
  ) {
  }

  close() {
    this.snackbar.dismiss();
  }

  show(text: string, type: string, title: string, options?: DDCSnackBarOptions) {
    const optionalData = {title: options && options.title ? options.title : undefined}
    const givenData = {title: title, text: text};
    const data = _.merge({}, givenData, optionalData);
    let duration;
    duration = options && options.durationInSeconds ? options.durationInSeconds * 1000 : SnackBarService.DEFAULT_SNACK_BAR_DURATION * 1000;
    this.snackbar.openFromComponent(SnackBarAlertComponent, {
      duration: duration,
      data: data,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: this.SNACK_BAR_TYPE.includes(type) ? type + this.PANEL_CLASS_SUFFIX : '',
    });
  }

  showSuccess(text: string, options?: DDCSnackBarOptions) {
    this.show(text, 'success', 'Succès', options);
  }

  showError(text: string, options?: DDCSnackBarOptions) {
    this.show(text, 'danger', 'Erreur', options);
  }

  showWarning(text: string, options?: DDCSnackBarOptions) {
    this.show(text, 'warning', 'Warning', options);
  }

  showInfo(text: string, options?: DDCSnackBarOptions) {
    this.show(text, 'info', 'Info', options);
  }

}
