import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdministrationRoutingModule } from './administration.routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AdministrationPaysComponent } from './administration-pays/administration-pays.component';
import {AdministrationEmailComponent} from "./administration-email/administration-email.component";
import {MatDatepickerModule} from "@angular/material/datepicker";

@NgModule({

  declarations: [
    AdministrationPaysComponent,
    AdministrationEmailComponent
  ],
  imports: [
    AdministrationRoutingModule,
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatMomentDateModule,
    SharedModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    FormsModule,
    MatDatepickerModule
  ]
})
export class AdministrationModule {
}
