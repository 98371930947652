import moment from 'moment';
import {Moment} from 'moment';
import {startCase as _startCase, toLower as _toLower} from 'lodash';
import {ICamp} from '../model/camp.model';

export class DateFieldsDefinition {
  constructor(
    public dateFields?: string[],
    public subDateFieldDefinitions?: { [subFieldName: string]: SubDateFieldDefinition },
  ) {
  }
}

export class SubDateFieldDefinition {
  constructor(
    public dateFieldsDefinition: DateFieldsDefinition,
    public isArray?: boolean
  ) {
  }
}

export function convertDateFromServer<T extends object>(object: T, dateFieldDefinition: DateFieldsDefinition): T {
  if (object) {
    if (dateFieldDefinition.dateFields) {
      dateFieldDefinition.dateFields.forEach(dateField => {
        // On ne met à jour ce champs que s'il existe, sinon on le laisse "undefined"
        if (object[dateField] !== undefined) {
          object[dateField] = object[dateField] != null ? moment.utc(object[dateField]).local() : null;
        }
      });
    }
    if (dateFieldDefinition.subDateFieldDefinitions) {
      for (const [subFieldName, subDateFieldDefinition] of Object.entries(dateFieldDefinition.subDateFieldDefinitions)) {
        if (subDateFieldDefinition.isArray) {
          if (object[subFieldName]) {
            object[subFieldName].forEach(subFieldValueElement => convertDateFromServer(subFieldValueElement, subDateFieldDefinition.dateFieldsDefinition));
          }
        } else {
          convertDateFromServer(object[subFieldName], subDateFieldDefinition.dateFieldsDefinition);
        }
      }
    }
  }
  return object;
}

export function convertDateFromClient<T extends object>(object: T, dateFieldDefinition: DateFieldsDefinition): T {
  if (object) {
    const objectDateFields = {};
    if (dateFieldDefinition.dateFields) {
      dateFieldDefinition.dateFields.forEach(dateField => {
        const dateFieldValue: Moment = object[dateField];
        objectDateFields[dateField] = dateFieldValue != null && dateFieldValue.isValid() ? dateFieldValue.toISOString() : null;
      });
    }

    if (dateFieldDefinition.subDateFieldDefinitions) {
      for (const [subFieldName, subDateFieldDefinition] of Object.entries(dateFieldDefinition.subDateFieldDefinitions)) {
        if (subDateFieldDefinition.isArray) {
          if (object[subFieldName]) {
            object[subFieldName].forEach(subFieldValueElement => convertDateFromClient(subFieldValueElement, subDateFieldDefinition.dateFieldsDefinition));
          }
        } else {
          convertDateFromClient(object[subFieldName], subDateFieldDefinition.dateFieldsDefinition);
        }
      }
    }
    return Object.assign({}, object, objectDateFields);
  }
  return object;
}

export function convertToHumanDate(date: Moment, withTime?: boolean, withDayName?: boolean): string {
  const dateFormat = !!withTime ? 'DD/MM/YYYY à H:mm' : 'DD/MM/YYYY';
  let dayName = '';
  const humanDate = date && moment(date) ? moment(date).tz('Europe/Paris').format(dateFormat) : '';
  if (!!withDayName) {
    dayName = date && moment(date) ? _startCase(_toLower(moment(date).format('dddd'))) : '';
    return dayName + ' ' + humanDate;
  }
  return humanDate;
}

export function getAgeAdherentAuDebutCamp(camp: ICamp, dateDeNaissance: Moment): string {
  const premierJourDuCamp: Moment = camp && camp.dateDebut;
  if (premierJourDuCamp && dateDeNaissance) {
    const age = premierJourDuCamp.diff(dateDeNaissance, 'years');
    return age + ' ans';
  } else {
    return '';
  }
}
