import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Subject } from 'rxjs'
import { distinctUntilChanged, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectService {

  private readonly loginEndpoint = environment.apiServerUrl + 'login'
  private readonly logoutEndpoint = environment.apiServerUrl + 'logout'
  private readonly subject = new Subject<string>()

  constructor() { 
    this.subject
      .pipe(
        tap((url) => console.debug(`request url redirect : ${url}`)),
        distinctUntilChanged()
      )
      .subscribe(url => window.location.href = url)
  }

  triggerLogin(): void {
    this.triggerRedirect(this.loginEndpoint)
  }

  triggerLogout(): void {
    this.triggerRedirect(this.logoutEndpoint)
  }

  triggerRedirect(url: string): void {
    this.subject.next(url)
  }
}
