import { Component, Input, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import {CampConfigurationService} from '../service/camp-configuration.service';
import {EventManager} from '../util/event-manager';
import { AuthenticationService } from '../service/authentication.service';
import { DdcUser } from '../model/ddc-user.model';
import { environment } from '../../../environments/environment';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: '[ddcLabel]',
  templateUrl: './dynamic-label.component.html',
  styleUrls: ['./dynamic-label.component.scss']
})
export class DynamicLabelComponent implements OnInit {
  @Input() public labelKey: string;

  label = '';
  currentUser: DdcUser;
  displayLabelToolTip: boolean;

  constructor(
    protected campConfigurationService: CampConfigurationService,
    protected authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) {
    if(authenticationService.currentUserValue) {
      this.currentUser = authenticationService.currentUserValue;
    }
    this.displayLabelToolTip = environment ? environment.displayLabelToolTip : false;
  }

  ngOnInit(): void {
    EventManager.campLabelsUpdated.subscribe(campLabels => {
      this.label = this.sanitizer.sanitize(SecurityContext.HTML,
        campLabels[this.labelKey]
          ? campLabels[this.labelKey]
          : campLabels['bdd.' + this.labelKey] ? campLabels['bdd.' + this.labelKey] : '?' + this.labelKey + '?');
    });
    const campLabels = this.campConfigurationService.getCampLabels();
    this.label = this.sanitizer.sanitize(SecurityContext.HTML,
      campLabels[this.labelKey]
        ? campLabels[this.labelKey]
        : campLabels['bdd.' + this.labelKey] ? campLabels['bdd.' + this.labelKey] : '?' + this.labelKey + '?');
  }

}
