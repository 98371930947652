import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { convertDateFromServer } from '../util/moment.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IProjetAnneeHistoModuleDerniereModificationContainer,
  IProjetAnneeHistoriqueModification,
  PROJET_ANNEE_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION
} from '../model/projet-annee-historique-modification.model';

@Injectable({
  providedIn: 'root'
})
export class ProjetAnneeHistoriqueModificationService {

  constructor(protected http: HttpClient) {
  }

  public resourceUrl = environment.apiServerUrl + 'projet-annee-historique-modifications';

  public static mapProjetAnneeHistoModuleDerniereModificationHeader<T>(httpResponseObservable: Observable<HttpResponse<T>>): Observable<IProjetAnneeHistoModuleDerniereModificationContainer<T>> {
    return httpResponseObservable.pipe(
      map(ProjetAnneeHistoriqueModificationService.httpResponseToProjetAnneeHistoModuleDerniereModificationContainer)
    );
  }

  public static httpResponseToProjetAnneeHistoModuleDerniereModificationContainer<T>(response: HttpResponse<T>) {
    return {
      data: response.body,
      projetAnneeHistoModuleDerniereModification: ProjetAnneeHistoriqueModificationService.convertDateFromServer(
        JSON.parse(
          response.body['_ddcProjetAnneeHistoModuleDerniereModification'] ? response.body['_ddcProjetAnneeHistoModuleDerniereModification'] : 'null'
        )
      ),
      serverValue: response.body
    };
  }

  static convertDateFromServer(projetAnneeHistoriqueModification: IProjetAnneeHistoriqueModification): IProjetAnneeHistoriqueModification {
    if (projetAnneeHistoriqueModification) {
      convertDateFromServer(projetAnneeHistoriqueModification, PROJET_ANNEE_HISTORIQUE_MODIFICATION_DATE_FIELDS_DEFINITION)
    }
    return projetAnneeHistoriqueModification;
  }
}
