<ng-container *ngIf="_isDisplayed">
  <div class="dropdown">
    <a class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
       aria-expanded="false">
      <span class="title" ddcLabel labelKey="{{this._titleLabelKey}}"></span>
    </a>
    <ng-container *ngIf="_subTabs && _subTabs.length > 0">
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
        <li disabled="true" *ngFor="let subTab of _subTabs">
          <a *ngIf="subTab.displayed" [class.disabled]="subTab.disabled" class="dropdown-item" [routerLink]="[subTab.routerLink]">
            <span class="dropdown-menu-text" ddcLabel labelKey="{{subTab.labelKey}}"></span>
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
</ng-container>
