import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector : 'ddc-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss']
})
export class SnackBarAlertComponent {

  constructor(
    private snackbar : MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ){}

  close(){
    this.snackbar.dismiss();
  }

}

export class DDCSnackBarOptions <T = any>{
  public title?: string;
  public durationInSeconds?: number;
}
