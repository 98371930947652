import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccueilRoutingModule } from './accueil-routing.module';
import { AccueilPageComponent } from './accueil.page.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../shared/shared.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MesActionsComponent } from './accueil-components/mes-actions/mes-actions.component';
import { MesNotificationsComponent } from './accueil-components/mes-notifications/mes-notifications.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({

  declarations: [
    AccueilPageComponent,
    MesActionsComponent,
    MesNotificationsComponent
  ],
    imports: [
        CommonModule,
        AccueilRoutingModule,
        MatGridListModule,
        MatCardModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatMomentDateModule,
        SharedModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatTooltipModule,
    ]
})
export class AccueilModule {
}
