import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { convertToHumanDate } from '../util/moment.utils';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GRILLE_ACTIVITES_CRENEAUX, MENUS_REPAS, GRILLE_ACTIVITES_CRENEAUX_COMPAGNONS } from '../constant/constant';
import { ICampAdherentStaff } from '../model/camp-adherent-staff.model';
import { CampGrilleActivite, ICampGrilleActivite } from '../model/camp-grille-activite.model';
import { CampConfigurationService } from '../service/camp-configuration.service';
import { ICampAdherentParticipant } from '../model/camp-adherent-participant.model';
import { ITypeCamp } from '../model/type-camp.model';
import { TypeCampGroupEnum } from '../constant/enum';
import {
  CampGrilleActiviteCampAdherentStaff,
  ICampGrilleActiviteCampAdherentStaff
} from '../model/camp-grille-activite-camp-adherent-staff.model';
import {AbstractCampOngletCampFormComponent} from "../../camp/camp-onglets/abstract-camp-onglet-camp-form.component";

@Component({
  selector: 'ddc-edit-modal-dialog',
  templateUrl: './edit-camp-info-dialog.component.html',
  styles: [`mat-card.ddc-card-with-title {
    margin-bottom: 0;
  }`]
})
export class EditCampInfoDialogComponent {

  convertToHumanDate = convertToHumanDate;
  formArray: UntypedFormArray = new UntypedFormArray([]);
  selectedForm = this.fb.array([]);
  selectedFormIndex: number;
  maximumIndexThatCanBeSelected: number;
  dialogTitle: string;
  numberOfSlotsPerDay: number;
  tmpFormArray: UntypedFormArray = this.fb.array([]);
  w: boolean = false;
  focusedTheme: string;

  TypeCampGroupEnum = TypeCampGroupEnum;

  constructor(
    public fb: UntypedFormBuilder,
    protected campConfigurationService: CampConfigurationService,
    public dialogRef: MatDialogRef<EditCampInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditModalDialogData
  ) {
    this.focusedTheme = this.campConfigurationService.getFocusedTheme();
    this.dialogRef.disableClose = this.data.disableClose || false;
    this.data.items.forEach(item => {
      const formGroup = this.fb.group(item);
      this.initcampGrilleActiviteCampAdherentStaffsFormArray(formGroup, item);
      this.formArray.push(formGroup);
    });
    this.numberOfSlotsPerDay = data.numberOfSlotsPerDay;
    this.selectedFormIndex = this.data.selectedFormIndex;
    this.maximumIndexThatCanBeSelected = this.formArray.length - this.numberOfSlotsPerDay;
    this.initselectedForm();
    this.initTmpFormArray();
  }

  initselectedForm() {
    this.selectedForm.clear();
    for (let i: number = 0; i < this.numberOfSlotsPerDay; i++) {
      this.selectedForm.push(this.formArray.at(this.selectedFormIndex + i));
    }
    const dayNumber = this.selectedFormIndex / this.numberOfSlotsPerDay + 1;
    if (this.data.codeModule === 'MENU') {
      this.dialogTitle = 'Modification Menu - Jour ' + dayNumber.toString() + ' le ' + convertToHumanDate(this.selectedForm.at(0).get('dateMenu').value, false, true);
    } else if (this.data.codeModule === 'GRILLE_ACTIVITE') {
      this.dialogTitle = 'Modification Activité - Jour ' + dayNumber.toString() + ' le ' + convertToHumanDate(this.selectedForm.at(0).get('dateActivite').value, false, true);
    }
  }

  initTmpFormArray() {
    const selectedFormValues = this.selectedForm.getRawValue();
    this.tmpFormArray.clear();
    selectedFormValues.forEach((formValue) => {
      this.tmpFormArray.push(this.createTmpFormGroup(formValue));
    });
  }

  initcampGrilleActiviteCampAdherentStaffsFormArray(formGroup: UntypedFormGroup, campGrilleActivite: ICampGrilleActivite) {
    if (campGrilleActivite) {
      const keys = Object.keys(campGrilleActivite);
      if (keys.includes('campGrilleActiviteCampAdherentStaffs')) {
        const campGrilleActiviteCampAdherentStaffs: ICampGrilleActiviteCampAdherentStaff[] = campGrilleActivite.campGrilleActiviteCampAdherentStaffs || [];
        const campGrilleActiviteCampAdherentStaffsFormArray = this.fb.array([]);
        campGrilleActiviteCampAdherentStaffsFormArray.clear();
        campGrilleActiviteCampAdherentStaffs.forEach((campGrilleActiviteCampAdherentStaff: ICampGrilleActiviteCampAdherentStaff) => {
          const item = new CampGrilleActiviteCampAdherentStaff();
          const tmpFormGroup = this.fb.group(item);
          tmpFormGroup.patchValue(campGrilleActiviteCampAdherentStaff);
          campGrilleActiviteCampAdherentStaffsFormArray.push(tmpFormGroup);
        });
        formGroup.setControl('campGrilleActiviteCampAdherentStaffs', campGrilleActiviteCampAdherentStaffsFormArray);
      }
    }
  }

  confirmDataEntry() {
    const tmpFormArrayValues = this.tmpFormArray.getRawValue();
    this.selectedForm.reset();
    tmpFormArrayValues.forEach((formValue, i: number) => {
      this.selectedForm.at(i).patchValue(formValue);
      this.initcampGrilleActiviteCampAdherentStaffsFormArray(this.selectedForm.at(i) as UntypedFormGroup, formValue);
    });
    this.tmpFormArray.clear();
  }

  createTmpFormGroup(formValue: ICampGrilleActivite): UntypedFormGroup {
    const formGroup = this.fb.group(formValue);
    this.initcampGrilleActiviteCampAdherentStaffsFormArray(formGroup, formValue);
    return formGroup;
  }

  cancelModifications(): void {
    this.initTmpFormArray();
  }

  onYesClick() {
    this.confirmDataEntry();
    this.dialogRef.close({ items: this.formArray.getRawValue() });
  }

  changeDay(direction: string) {
    switch (direction) {
      case 'BACKWARD':
        if (this.selectedFormIndex > 0) {
          this.confirmDataEntry();
          this.selectedFormIndex = this.selectedFormIndex - this.numberOfSlotsPerDay;
          this.initselectedForm();
          this.initTmpFormArray();
        }
        break;
      case 'FORWARD':
        if (this.selectedFormIndex < this.maximumIndexThatCanBeSelected) {
          this.confirmDataEntry();
          this.selectedFormIndex = this.selectedFormIndex + this.numberOfSlotsPerDay;
          this.initselectedForm();
          this.initTmpFormArray();
        }
        break;
    }
  }

  getCreneauText(value: number) {
    let creneau;

    if(this.data.typeCamp.codeGroupeCamp != TypeCampGroupEnum.COMPAGNON) {
      creneau = GRILLE_ACTIVITES_CRENEAUX.find(creneau => creneau.value === value);
      return creneau.text
    } else {
      creneau = GRILLE_ACTIVITES_CRENEAUX_COMPAGNONS.find(creneau => creneau.value === value);
      return creneau.labelKey
    }
    return 'Non fourni';
  }

  getRepasText(value: number) {
    const repas = MENUS_REPAS.find(repas => repas.value === value);
    return repas ? repas.text : 'Non fourni';
  }

  public compareGrilleActiviteValues(param1: ICampGrilleActivite, param2: ICampGrilleActivite): boolean {
    return param1 && param2 ? param1.id === param2.id : false;
  }

  public compareCampAdherentStaffs(param1: ICampAdherentStaff, param2: ICampAdherentStaff): boolean {
    return param1 && param2 ? param1.id === param2.id : false;
  }

  public setcampGrilleActiviteCampAdherentStaffs(form: AbstractControl, event: any) {
    const formGroup = form as UntypedFormGroup;
    const campGrilleActivite: ICampGrilleActivite = formGroup.getRawValue();
    const campAdherentStaffs = event.value;
    campGrilleActivite.campGrilleActiviteCampAdherentStaffs = [];
    if (campAdherentStaffs && campAdherentStaffs.length > 0) {
      campAdherentStaffs.forEach((campAdherentStaff: ICampAdherentStaff) => {
        const campGrilleActiviteCampAdherentStaff = new CampGrilleActiviteCampAdherentStaff();
        campGrilleActiviteCampAdherentStaff.id = 0;
        campGrilleActiviteCampAdherentStaff.campAdherentStaff = campAdherentStaff;
        campGrilleActivite.campGrilleActiviteCampAdherentStaffs.push(campGrilleActiviteCampAdherentStaff);
      });
    }
    this.initcampGrilleActiviteCampAdherentStaffsFormArray(formGroup, campGrilleActivite);
  }

  public getcampGrilleActiviteCampAdherentStaffs(form: AbstractControl) {
    const formGroup = form as UntypedFormGroup;
    const campGrilleActivite: ICampGrilleActivite = formGroup.getRawValue();
    const campAdherentStaffs = campGrilleActivite.campGrilleActiviteCampAdherentStaffs.map((campGrilleActiviteCampAdherentStaff: ICampGrilleActiviteCampAdherentStaff) => campGrilleActiviteCampAdherentStaff.campAdherentStaff);
    return campAdherentStaffs;
  }

}

export class EditModalDialogData<T = any> {
  public items?: object[];
  public selectedFormIndex?: number;
  public numberOfSlotsPerDay?: number;
  public closeBtnText?: string;
  public ignoreModificationsBtnText?: string;
  public codeModule?: string;
  public disableClose?: boolean;
  public campAdherentStaffs?: ICampAdherentStaff[];
  public campAdherentParticipants?: ICampAdherentParticipant[];
  public typeCamp?: ITypeCamp;
}
