import { TamRefService } from '../service/tam-ref.service';
import { filterOkMapBody } from './http.utils';
import { ITamRefCommune } from '../model/tam-ref-commune.model';
import { FormGroup, Validators } from '@angular/forms';
import { ITamRefPays } from '../model/tam-ref-pays.models';

export function getCommuneByCodePostal(tamRefService: TamRefService, listeCommune: object, codePostal: string){
  if(codePostal && codePostal.length == 4 || codePostal.length == 5 && !listeCommune[codePostal]) {
    // Launch the service to get ville by code postal
    tamRefService.getCommunesByCodePostal(codePostal)
      .pipe(filterOkMapBody)
      .subscribe((res: ITamRefCommune[]) => {
        if(res && res.length > 0) {
          listeCommune[codePostal] = res;
        }
      });
  }
}

export function getListCommune(listeCommune: object, codePostal: string): ITamRefCommune[] {
  if(codePostal && listeCommune[codePostal]) {
    return listeCommune[codePostal];
  }
  return [];
}


export function setTamRefCommuneOptions(tamRefService: TamRefService, lieuForm: FormGroup, listeCommune: object, codePostal: string) {
  getCommuneByCodePostal(tamRefService, listeCommune, codePostal);
  lieuForm.get('tamRefCommune').setValue(null);
}

export function toggleItinerant(lieuForm: FormGroup, event) {
  if(event && event.checked === true) {
    lieuForm.get('itinerant').setValue(true);
  }else {
    lieuForm.get('itinerant').setValue(false);
  }
}

export function toggleEnFrance(lieuForm: FormGroup, event: any) {
  if(event && event.checked === true) {
    lieuForm.get('enFrance').setValue(true);
    updateCommunePaysControls(lieuForm, true);
  }else {
    lieuForm.get('enFrance').setValue(false);
    updateCommunePaysControls(lieuForm, false);
  }
  lieuForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
}

export function updateCommunePaysControls(lieuForm: FormGroup, enFrance: boolean) {
  if(enFrance === true) {
    lieuForm.controls.tamRefCommune.setValidators(Validators.required);
    lieuForm.controls.tamRefCommune.updateValueAndValidity({onlySelf: false, emitEvent: true});
    lieuForm.controls.tamRefPays.clearValidators();
    lieuForm.controls.tamRefPays.updateValueAndValidity({onlySelf: false, emitEvent: true});
    lieuForm.controls.codePostal.clearValidators();
    lieuForm.controls.codePostal.updateValueAndValidity({onlySelf: false, emitEvent: true});
  } else {
    lieuForm.controls.tamRefCommune.clearValidators();
    lieuForm.controls.tamRefCommune.updateValueAndValidity({onlySelf: false, emitEvent: true});
    lieuForm.controls.tamRefPays.setValidators(Validators.required);
    lieuForm.controls.tamRefPays.updateValueAndValidity({onlySelf: false, emitEvent: true});
    lieuForm.controls.codePostal.setValidators(Validators.required);
    lieuForm.controls.codePostal.updateValueAndValidity({onlySelf: false, emitEvent: true});

  }
}

export function compareCommunesValues(param1: ITamRefCommune, param2: ITamRefCommune) : boolean {
  return param1 && param2 ? param1.id === param2.id : false;
}

export function comparePaysValues(param1: ITamRefPays, param2: ITamRefPays) : boolean {
  return param1 && param2 ? param1.id === param2.id : false;
}
