import { Moment } from 'moment';
import { DateFieldsDefinition } from '../util/moment.utils';
import { ITamRefCommune } from './tam-ref-commune.model';
import { ITamRefPays } from './tam-ref-pays.models';

export interface ICampLieu {
  id?: number;
  dateDebut?: Moment;
  dateFin?: Moment;
  libelle?: string;
  adresseLigne1?: string;
  adresseLigne2?: string;
  enFrance?: boolean;
  codePostal?: string;
  ville?: string;
  pays?: string;
  tamRefCommune?: ITamRefCommune;
  tamRefPays?: ITamRefPays;
  geoJson?: IGeoLocation;
  telephone?: string;
  feuxAutorise?: boolean;
  sanitaireEnDur?: boolean;
  commentaire?: string;
  nbreKm?: number;
  moyenTransport?: string;
}

export class CampLieu implements ICampLieu {
  constructor(
  public id?: number,
  public dateDebut?: Moment,
  public dateFin?: Moment,
  public libelle?: string,
  public adresseLigne1?: string,
  public adresseLigne2?: string,
  public enFrance?: boolean,
  public codePostal?: string,
  public ville?: string,
  public pays?: string,
  public tamRefCommune?: ITamRefCommune,
  public tamRefPays?: ITamRefPays,
  public geoJson?: IGeoLocation,
  public telephone?: string,
  public feuxAutorise?: boolean,
  public sanitaireEnDur?: boolean,
  public commentaire?: string,
  public nbreKm?: number,
  public moyenTransport?: string,
  ){}
}

export interface IGeoLocation {
  longitude?: string;
  latitude?: string;
}

export const CAMP_LIEU_DATE_FIELDS_DEFINITION = new DateFieldsDefinition(['dateDebut','dateFin']);
