import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorComponent } from './error/control-error.component';
import { DynamicLabelComponent } from './label/dynamic-label.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DdcModulesComponent } from '../camp/camp-modules/ddc-modules.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { StructureSelectorComponent } from './structure-selector/structure-selector.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StaffSelectorComponent } from './staff-selector/staff-selector-component';
import { ModuleMarinEncadrantNautiqueSelectorComponent } from './module-marin-staff-selector/module-marin-encadrant-nautique-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CampInfoComplementaireHistoriqueHdtypeListComponent } from './camp/camp-info-complementaire-historique-hdtypes/camp-info-complementaire-historique-hdtype-list.component';
import { CampInfoComplementaireHistoriqueHdtypeObjComponent } from './camp/camp-info-complementaire-historique-hdtypes/camp-info-complementaire-historique-hdtype-obj.component';
import { CampInfoComplementaireHistoriqueHdtypeScalarComponent } from './camp/camp-info-complementaire-historique-hdtypes/camp-info-complementaire-historique-hdtype-scalar.component';
import { DonneesDeclarationTamComponent } from './donnees-declaration-tam/donnees-declaration-tam.component';
import { PieceJointeComponent } from './piece-jointe/piece-jointe.component';
import { MatIconModule } from '@angular/material/icon';
import { CampAvisComponent } from './camp/camp-avis/camp-avis.component';
import {MouseEventManagerDirective} from "./mouse-event-manager/mouse-event-manager.directive";
import { TooltipIconComponent } from './tooltip-icon/tooltip-icon.component';
import { DdcProjectsInformationCardComponent } from './ddc-projects-information-card/ddc-projects-information-card.component';
import { CampsInformationTableComponent } from './camp/camps-information-table/camps-information-table.component';
import { CampDetailsCardComponent } from './camp/camp-details-card/camp-details-card.component';
import { ProjetsAnneeInformationTableComponent } from './projet-annee/projets-annee-information-table/projets-annee-information-table.component';
import { MatSortModule } from "@angular/material/sort";
import { PaginationComponent } from './pagination/pagination.component';
import { GestionNotificationEmailComponent } from './gestion-notification-email/gestion-notification-email.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ProjetAnneeAvisComponent } from './projet-annee/projet-annee-avis/projet-annee-avis.component';
import {MatMenuModule} from "@angular/material/menu";
import { MaterialFileInputModule } from 'ngx-material-file-input';

@NgModule({
  declarations: [
    ControlErrorComponent,
    DynamicLabelComponent,
    DdcModulesComponent,
    StructureSelectorComponent,
    StaffSelectorComponent,
    ModuleMarinEncadrantNautiqueSelectorComponent,
    CampInfoComplementaireHistoriqueHdtypeListComponent,
    CampInfoComplementaireHistoriqueHdtypeObjComponent,
    CampInfoComplementaireHistoriqueHdtypeScalarComponent,
    DonneesDeclarationTamComponent,
    PieceJointeComponent,
    CampAvisComponent,
    MouseEventManagerDirective,
    TooltipIconComponent,
    DdcProjectsInformationCardComponent,
    CampsInformationTableComponent,
    CampDetailsCardComponent,
    ProjetsAnneeInformationTableComponent,
    PaginationComponent,
    GestionNotificationEmailComponent,
    ProjetAnneeAvisComponent
  ],
    imports: [
        CommonModule,
        MatTooltipModule,
        MatCardModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatButtonModule,
        MatDatepickerModule,
        MatIconModule,
      MaterialFileInputModule,
      MatSortModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatMenuModule
    ],
    exports: [
        ControlErrorComponent,
        DynamicLabelComponent,
        DdcModulesComponent,
        StructureSelectorComponent,
        StaffSelectorComponent,
        CampInfoComplementaireHistoriqueHdtypeListComponent,
        CampInfoComplementaireHistoriqueHdtypeObjComponent,
        CampInfoComplementaireHistoriqueHdtypeScalarComponent,
        DonneesDeclarationTamComponent,
        PieceJointeComponent,
        ModuleMarinEncadrantNautiqueSelectorComponent,
        CampAvisComponent,
        MouseEventManagerDirective,
        TooltipIconComponent,
        DdcProjectsInformationCardComponent,
        CampsInformationTableComponent,
        CampDetailsCardComponent,
        ProjetsAnneeInformationTableComponent,
        PaginationComponent,
        ProjetAnneeAvisComponent
    ],

})
export class SharedModule {
}
