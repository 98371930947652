import { Component, Input } from '@angular/core';


@Component({
  selector: 'ddc-menu-tab',
  templateUrl: 'menu-tab.component.html',
  styleUrls: ['menu-tab.component.scss']
})

export class MenuTabComponent {

  @Input() set titleLabelKey(titleLabelKey: string) {
    this._titleLabelKey = titleLabelKey;
  }

  @Input() set isDisplayed(isDisplayed: boolean) {
    this._isDisplayed = isDisplayed;
  }

  @Input() set subTabs(subTabs: ISubTabInterface[]) {
    this._subTabs = subTabs;
  }

  _titleLabelKey: string;
  _subTabs: ISubTabInterface[];
  _isDisplayed: boolean;

}

export interface IMenuTabInterface {
  titleLabelKey: string;
  subTabs: ISubTabInterface[];
  isDisplayed?: boolean;
}

export interface ISubTabInterface {
  labelKey: string;
  routerLink: string;
  displayed: boolean;
  disabled?: boolean;
}
