import { Component } from '@angular/core';
import { LoadingService } from '../service/loading.service';

@Component({
  selector: 'ddc-loading',
  template: `<mat-progress-spinner *ngIf="loadingService.isLoadingAsObservable() | async" mode="indeterminate" class="loading-spinner"></mat-progress-spinner>`
})
export class LoadingComponent {
  constructor(public loadingService: LoadingService) {}
}
