
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import moment from 'moment';

// sentry
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// pour pouvoir utiliser moment.tz merci à https://github.com/moment/moment-timezone/issues/385#issuecomment-392323276
import 'moment-timezone'; 

if (environment.production) {
  enableProdMode();
}

// issu de la doc fournie par le client : https://scouts-et-guides-de-france.sentry.io/projects/monprojet-uat-javascript-angular/
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    moment.locale('fr');
    moment.tz.setDefault('UTC'); // Définition de la timezone par défaut à "UTC" pour être sûr de traiter toutes les dates comme UTC
  })
  .catch(err => console.error(err));
  
Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.authKeycloakRedirectUrl],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
  