import { Injectable } from '@angular/core';
import { ICampHistoModuleDerniereModificationContainer } from '../model/camp-historique-modification.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampHistoriqueModificationService } from './camp-historique-modification.service';
import * as _ from 'lodash';
import { IProjetAnneeHistoModuleDerniereModificationContainer } from '../model/projet-annee-historique-modification.model';
import { ProjetAnneeHistoriqueModificationService } from './projet-annee-historique-modification.service';
import { IDdcUser } from '../model/ddc-user.model';
import {
  DDC_CAMP_SEARCH_CRITERIAS,
  DDC_NUMBER_ITEM_PAGINATION,
  DDC_PROJET_ANNEE_SEARCH_CRITERIAS
} from "../constant/constant";

export interface ILocalCacheHandler<T> {
  convertFromCache: (localCacheValue: T) => T;
  canClearCache: (
    localCacheKey: string,
    localStorageValue: ICampHistoModuleDerniereModificationContainer<T>,
    serverValue: ICampHistoModuleDerniereModificationContainer<T>
  ) => Observable<boolean>
}

export interface IProjetAnneeLocalCacheHandler<T> {
  convertFromCache: (localCacheValue: T) => T;
  canClearCache: (
    localCacheKey: string,
    localStorageValue: IProjetAnneeHistoModuleDerniereModificationContainer<T>,
    serverValue: IProjetAnneeHistoModuleDerniereModificationContainer<T>
  ) => Observable<boolean>
}

@Injectable({ providedIn: 'root' })
export class LocalCacheService {
  public static readonly DDC_LOCAL_STORAGE_PREFIX = 'DDC-';

  /**
   *
   * @param localCacheKey
   * @param campHistoModuleDerniereModificationContainerServerObservable observable de réponse serveur : les dates doivent avoir été correctement converties au préalable
   * @param localCacheHandler
   * @param campHistoModuleDerniereModificationContainerViewToSaveToCacheObservable
   */
  public static attachCampHistoModuleDerniereModificationContainerToLocalCache<T>(
    localCacheKey: string,
    campHistoModuleDerniereModificationContainerServerObservable: Observable<ICampHistoModuleDerniereModificationContainer<T>>,
    localCacheHandler: ILocalCacheHandler<T>,
    campHistoModuleDerniereModificationContainerViewToSaveToCacheObservable: Observable<ICampHistoModuleDerniereModificationContainer<T>>,
  ): Observable<ICampHistoModuleDerniereModificationContainer<T>> {

    // On écoute les valeurs provenant de la vue dont la vue souhaite sauvegarder en cache les valeurs
    campHistoModuleDerniereModificationContainerViewToSaveToCacheObservable.subscribe(viewValue => {
      this.setCampHistoModuleDerniereModificationContainerViewValueToLocalCacheForKey(localCacheKey, viewValue, localCacheHandler);
    });

    return campHistoModuleDerniereModificationContainerServerObservable.pipe(
      map(serverValue => {
        const localStorageValue: ICampHistoModuleDerniereModificationContainer<T> = JSON.parse(localStorage.getItem(this.getLocalStorageKey(localCacheKey)) || 'null');
        if (localStorageValue) {
          // On a un enregistrement dans le cache local, nous allons comparer les versions
          localStorageValue.campHistoModuleDerniereModification = CampHistoriqueModificationService.convertDateFromServer(localStorageValue.campHistoModuleDerniereModification); // On convertit les dates en objets Moment
          localStorageValue.data = localCacheHandler.convertFromCache(localStorageValue.data);
          if ((localStorageValue.campHistoModuleDerniereModification == null && serverValue.campHistoModuleDerniereModification != null) // local storage a un histo null, mais pas le remote
            || (localStorageValue.campHistoModuleDerniereModification != null && serverValue.campHistoModuleDerniereModification != null
              && localStorageValue.campHistoModuleDerniereModification.dateHeureModification.isBefore(serverValue.campHistoModuleDerniereModification.dateHeureModification)
            )
          ) {
            // La version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur
            // FIXME la version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur
            console.error({text: `FIXME : La version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur`, localCacheKey, serverValue, localStorageValue});
            localCacheHandler.canClearCache(localCacheKey, localStorageValue, serverValue).subscribe(answer => {
              if (answer) {
                this.clearLocalCacheValueForKey(localCacheKey);
              }
            });
            return serverValue;
          } else {
            // La version du local storage est toujours d'actualité, on la charge
            console.log({text: 'Version du local storage chargée', localCacheKey, serverValue, localStorageValue});
            let s = _.merge({},serverValue); // This is done to move serverValue into another variable. This prevents the modification of the variable "serverValue"
            return _.merge(s, localStorageValue);
          }
        } else {
          return serverValue;
        }
      })
    );
  }

  /**
   *
   * @param localCacheKey
   * @param projetAnneeHistoModuleDerniereModificationContainerServerObservable observable de réponse serveur : les dates doivent avoir été correctement converties au préalable
   * @param localCacheHandler
   * @param projetAnneeHistoModuleDerniereModificationContainerViewToSaveToCacheObservable
   */
  public static attachProjetAnneeHistoModuleDerniereModificationContainerToLocalCache<T>(
    localCacheKey: string,
    projetAnneeHistoModuleDerniereModificationContainerServerObservable: Observable<IProjetAnneeHistoModuleDerniereModificationContainer<T>>,
    localCacheHandler: IProjetAnneeLocalCacheHandler<T>,
    projetAnneeHistoModuleDerniereModificationContainerViewToSaveToCacheObservable: Observable<IProjetAnneeHistoModuleDerniereModificationContainer<T>>,
  ): Observable<IProjetAnneeHistoModuleDerniereModificationContainer<T>> {

    // On écoute les valeurs provenant de la vue dont la vue souhaite sauvegarder en cache les valeurs
    projetAnneeHistoModuleDerniereModificationContainerViewToSaveToCacheObservable.subscribe(viewValue => {
      this.setProjetAnneeHistoModuleDerniereModificationContainerViewValueToLocalCacheForKey(localCacheKey, viewValue, localCacheHandler);
    });

    return projetAnneeHistoModuleDerniereModificationContainerServerObservable.pipe(
      map(serverValue => {
        const localStorageValue: IProjetAnneeHistoModuleDerniereModificationContainer<T> = JSON.parse(localStorage.getItem(this.getLocalStorageKey(localCacheKey)) || 'null');
        if (localStorageValue) {
          // On a un enregistrement dans le cache local, nous allons comparer les versions
          localStorageValue.projetAnneeHistoModuleDerniereModification = ProjetAnneeHistoriqueModificationService.convertDateFromServer(localStorageValue.projetAnneeHistoModuleDerniereModification); // On convertit les dates en objets Moment
          localStorageValue.data = localCacheHandler.convertFromCache(localStorageValue.data);
          if ((localStorageValue.projetAnneeHistoModuleDerniereModification == null && serverValue.projetAnneeHistoModuleDerniereModification != null) // local storage a un histo null, mais pas le remote
            || (localStorageValue.projetAnneeHistoModuleDerniereModification != null && serverValue.projetAnneeHistoModuleDerniereModification != null
              && localStorageValue.projetAnneeHistoModuleDerniereModification.dateHeureModification.isBefore(serverValue.projetAnneeHistoModuleDerniereModification.dateHeureModification)
            )
          ) {
            // La version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur
            // FIXME la version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur
            console.error({text: `FIXME : La version du local storage est antérieur à celle du serveur, on doit donc la supprimer et avertir l'utilisateur`, localCacheKey, serverValue, localStorageValue});
            localCacheHandler.canClearCache(localCacheKey, localStorageValue, serverValue).subscribe(answer => {
              if (answer) {
                this.clearLocalCacheValueForKey(localCacheKey);
              }
            });
            return serverValue;
          } else {
            // La version du local storage est toujours d'actualité, on la charge
            console.log({text: 'Version du local storage chargée', localCacheKey, serverValue, localStorageValue});
            let s = _.merge({},serverValue); // This is done to move serverValue into another variable. This prevents the modification of the variable "serverValue"
            return _.merge(s,localStorageValue);
          }
        } else {
          return serverValue;
        }
      })
    );
  }

  public static clearLocalCacheValueForKey(localCacheKey: string) {
    localStorage.removeItem(this.getLocalStorageKey(localCacheKey));
  }

  public static clearLocalCacheValueForOnglet(idCamp: number, codeModule: string, campModuleId?: number) {
    LocalCacheService.clearLocalCacheValueForKey(LocalCacheService.getCampLocalCacheKeyForOnglet(idCamp, codeModule, campModuleId));
  }

  private static getLocalStorageKey(localCacheKey: string) {
    return LocalCacheService.DDC_LOCAL_STORAGE_PREFIX + localCacheKey;
  }

  private static setCampHistoModuleDerniereModificationContainerViewValueToLocalCacheForKey<T>(localCacheKey: string, viewValue: ICampHistoModuleDerniereModificationContainer<T>, localCacheHandler: ILocalCacheHandler<T>) {
    localStorage.setItem(LocalCacheService.getLocalStorageKey(localCacheKey), JSON.stringify(viewValue));
  }

  private static setProjetAnneeHistoModuleDerniereModificationContainerViewValueToLocalCacheForKey<T>(localCacheKey: string, viewValue: IProjetAnneeHistoModuleDerniereModificationContainer<T>, localCacheHandler: ILocalCacheHandler<T>) {
    localStorage.setItem(LocalCacheService.getLocalStorageKey(localCacheKey), JSON.stringify(viewValue));
  }

  public static setCampLocalCacheValueForOnglet(localStorageValue: string, idCamp: number, codeModule: string, campModuleId?: number) {
    let localStorageKey = LocalCacheService.getCampLocalStorageKeyForOnglet(idCamp, codeModule, campModuleId);
    return localStorage.setItem(localStorageKey, localStorageValue);
  }

  public static setProjetAnneeLocalCacheValueForOnglet(localStorageValue: string, idProjetAnnee: number, codeModule: string) {
    let localStorageKey = LocalCacheService.getProjetAnneeLocalStorageKeyForOnglet(idProjetAnnee, codeModule);
    return localStorage.setItem(localStorageKey, localStorageValue);
  }

  /**
   * Renvoie la valeur de local storage (donc une string ou null) pour l'onglet de camp défini par les paramètres de cette fonction
   * @param idCamp
   * @param codeModule
   * @param campModuleId
   */
  public static getCampLocalStorageValueForOnglet(idCamp: number, codeModule: string, campModuleId?: number): string | null {
    let localStorageKey = LocalCacheService.getCampLocalStorageKeyForOnglet(idCamp, codeModule, campModuleId);
    return localStorage.getItem(localStorageKey);
  }

  /**
   * Renvoie la valeur de local storage (donc une string ou null) pour l'onglet de camp défini par les paramètres de cette fonction
   * @param idCamp
   * @param codeModule
   * @param campModuleId
   */
  public static getProjetAnneeLocalStorageValueForOnglet(idProjetAnnee: number, codeModule: string, projetAnneeModuleId?: number): string | null {
    let localStorageKey = LocalCacheService.getProjetAnneeLocalStorageKeyForOnglet(idProjetAnnee, codeModule, projetAnneeModuleId);
    return localStorage.getItem(localStorageKey);
  }


  /**
   * Renvoie la clé de local cache (concept fonctionnel, à différencier de local storage qui est ce qui est stocké concrètement) pour l'onglet de camp défini par les paramètres de cette fonction
   * @param idCamp
   * @param codeModule
   * @param campModuleId
   */
  public static getCampLocalCacheKeyForOnglet(idCamp: number, codeModule: string, campModuleId?: number) {
    if (campModuleId) {
      return `CampOnglet-${idCamp}-Id-${campModuleId}`;
    } else {
      return `CampOnglet-${idCamp}-Code-${codeModule}`;
    }
    return '';
  }

  /**
   * Renvoie la clé de local cache (concept fonctionnel, à différencier de local storage qui est ce qui est stocké concrètement) pour l'onglet de camp défini par les paramètres de cette fonction
   * @param idProjetAnnee
   * @param codeModule
   */
  public static getProjetAnneeLocalCacheKeyForOnglet(idProjetAnnee: number, codeModule: string, projetAnneeModuleId?: number) {
    if(projetAnneeModuleId) {
      return `ProjetAnneeOnglet-${idProjetAnnee}-Id-${projetAnneeModuleId}`;
    }else {
      return `ProjetAnneeOnglet-${idProjetAnnee}-Code-${codeModule}`;
    }
  }

  private static getCampLocalStorageKeyForOnglet(idCamp: number, codeModule: string, campModuleId?: number) {
    return LocalCacheService.getLocalStorageKey(LocalCacheService.getCampLocalCacheKeyForOnglet(idCamp, codeModule, campModuleId));
  }

  private static getProjetAnneeLocalStorageKeyForOnglet(idProjetAnnee: number, codeModule: string, projetAnneeModuleId?: number) {
    return LocalCacheService.getLocalStorageKey(LocalCacheService.getProjetAnneeLocalCacheKeyForOnglet(idProjetAnnee, codeModule, projetAnneeModuleId));
  }

  public static setLastVisitedUrl(url: string, currentDdcUser: IDdcUser) {
    if(currentDdcUser && currentDdcUser.numero) {
      localStorage.setItem('ddc-last-visited-url-'+currentDdcUser.numero, url);
    }
  }

  public static getLastVisitedUrl(currentDdcUser: IDdcUser): string {
    return currentDdcUser && currentDdcUser.numero ? localStorage.getItem('ddc-last-visited-url-'+currentDdcUser.numero) : '';
  }

  public static removeLastVisitedUrl(currentDdcUser: IDdcUser): void {
    if(currentDdcUser && currentDdcUser.numero) {
      localStorage.removeItem('ddc-last-visited-url-'+currentDdcUser.numero);
    }
  }

  public static getCampSearchCriterias() {
    return localStorage.getItem(DDC_CAMP_SEARCH_CRITERIAS);
  }

  public static setCampSearchCriterias(campSearchCriterias: string) {
    localStorage.setItem(DDC_CAMP_SEARCH_CRITERIAS, campSearchCriterias);
  }

  public static removeCampSearchCriterias(): void {
    localStorage.removeItem(DDC_CAMP_SEARCH_CRITERIAS);
  }

  public static setProjetAnneeSearchCriterias(campSearchCriterias: string) {
    localStorage.setItem(DDC_PROJET_ANNEE_SEARCH_CRITERIAS, campSearchCriterias);
  }

  public static getProjetAnneeSearchCriterias() {
    return localStorage.getItem(DDC_PROJET_ANNEE_SEARCH_CRITERIAS);
  }

  public static removeProjetAnneeSearchCriterias(): void {
    localStorage.removeItem(DDC_PROJET_ANNEE_SEARCH_CRITERIAS);
  }

}
